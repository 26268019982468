.commercial-heat-pump-external-elements {
    display: block;
    margin-top: 20px;

    .header {
        .title {
            font-size: 22px !important;
            padding-bottom: 0 !important;
        }
    }

    .form-box {
        border: solid 1px #E4E4E4;
        border-radius: 10px;
        padding: 8px 18px 18px 18px;
        margin: 15px 0 0 0;
    }

    .input-row {
        display: flex;
        width: 100%;
        margin: 15px 0 0 0;

        .input {
            display: block;

            .text-input {
                width: 100%;
                padding: 8px 10px;
                border-radius: 5px;
                border: solid 1px #E4E4E4;
                background: #F8F8F8;
                color: #7A7A7A;
                font-size: 18px;
                height: 42px;

                &::placeholder {
                    color: #d0d0d0;
                }

                &:focus {
                    outline: none;
                }
            }

            .input-label {
                display: inline-flex;
                align-items: center;
                position: relative;
                margin: 0 0 5px 0;
                font-size: 18px;
            }

            &.input-badge {
                .input-label-container {
                    width: auto;
                    display: flex;
                    align-items: center;
                }

                .text-input {
                    border-radius: 5px 0 0 5px;
                }

                .badge {
                    display: flex;
                    align-items: center;
                    background: #F8F8F8;
                    color: #7A7A7A;
                    border-radius: 0 5px 5px 0;
                    font-size: 18px;
                    padding: 8px 10px;
                    border: solid 1px #E4E4E4;
                    border-left: 0;
                }
            }

            &.error {
                .text-input, .badge {
                    background: rgba(38,230,109,0.075) !important;
                    color: #26E66D !important;
                    border-color: #26E66D !important;
                }
            }

            &:last-of-type {
                margin-right: 0;
            }
        }

        &.two-inputs {
            .input {
                width: 50%;
                margin-right: 10px;
            }
        }

        &.three-inputs {
            .input {
                width: 33%;
                margin-right: 10px;
            }
        }
    }

    .actions {
        margin: 18px 0 0 0;

        .submit {
            display: inline-flex;
            background: #26E66D;
            color: white;
            border-radius: 5px;
            border: none;
            font-size: 18px;
            cursor: pointer;
            padding: 10px 26px;
            transition: all 250ms;
            margin-left: auto;
        }
    }
}

.external-elements-table-whitespace-holder {
    display: block;
    width: 100%;
    text-align: center;
    padding: 30px 0;
}

#externalElementsTable {

    tbody {
        tr td:last-child {
            padding: 0;
            height: 60px;
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            vertical-align: middle;
        }
    }

    .deleteExternalElementRow {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #F5F5F5;
        color: #7A7A7A;
        border: none;
        border-radius: 1000px;
        height: 38px;
        width: 38px;
        margin-right: 8px;
        transition: all 250ms;
        cursor: pointer;

        svg {
            height: 16px;
        }

        &:hover {
            background: #e8e8e8;
        }
    }
}