* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Roboto', sans-serif;
}

body {
	background: #F8F8F8;

	&.no-scroll {
		overflow: hidden;
	}
}

.container {
	position: relative;
	width: calc(100% - 40px);
	max-width: 1100px;
	margin: auto;


	@include medium-screens-and-up {
		width: 70%;
	}

	@include extra-large-screens-and-up {
		&.wide {
			width: calc(100% - 80px);
		}
	}
}

.page-error {
	.error-title {
		text-align: center;
		margin: 40px 0 10px 0;
	}

	.error-subtitle {
		text-align: center;
	}
}

.loader {
	z-index: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	position: absolute;
	transform: translateX(-50%);
	top: 65px;
	left: 50%;
	width: 100%;
	height: calc(100% - 65px);
	background: #F8F8F8;
}

.loader h1 {
	font-size: 28px;
	font-weight: 500;
}

.loader svg {
	-webkit-animation:spin 3s linear infinite;
	-moz-animation:spin 3s linear infinite;
	animation:spin 3s linear infinite;
}

.loader.hidden {
	display: none;
}



.icon-header {
	margin: 30px 0 35px 0;
	display: flex;
	align-items: center;
}

.icon-header img {
	height: 85px;
}

.icon-header .titles {
	margin-left: 10px;
}

.icon-header .titles h1 {
	margin: 0;
	font-size: 38px;
}

.icon-header .titles h2 {
	font-weight: 400;
}

.back-link {
	display: inline-flex;
	align-items: center;
	text-decoration: none;
	font-size: 18px;
	font-weight: 500;
	color: rgba(0, 0, 0, 0.75);
	margin: 25px 0 0 0;
	transition: all 250ms;
	border: none;
	background: none;
	cursor: pointer;
}

.back-link:hover {
	color: rgba(0, 0, 0, 0.5);
}

.back-link:focus {
	outline: none;
}

.back-link svg {
	margin-right: 5px;
}

.module-selection-header {
	display: block;
	margin: 0 0 42px 0;
	padding: 0 12px;
	background: #F8F8F8;
	text-align: center;
	white-space: nowrap;

	.primary {
		color: #26E66D;
		display: block;
		font-size: 22px;
		font-weight: 500;
		margin: 0;
		white-space: break-spaces;

		@include medium-screens-and-up {
			font-size: 28px;
		}
	}

	.secondary {
		display: block;
		font-weight: 400;
		font-size: 18px;
		margin-top: 6px;
		white-space: break-spaces;
	}
}

.module-selection-section {
	position: relative;
	display: grid;
	grid-template-columns: 1fr;
	grid-row-gap: 20px;
	border: solid 2px transparent;
	padding: 30px 15px 15px 15px;
	margin-top: 55px;
	border-radius: 20px;

	.module-selection-section-header {
		display: flex;
		align-items: center;
		gap: 10px;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translate(-50%, -50%);
		background: #f8f8f8;
		padding: 0 10px;

		.icon {
			display: flex;
			align-items: center;

			svg {
				width: 26px;
				height: 26px;

				@include medium-screens-and-up {
					width: 32px;
					height: 32px;
				}
			}

			&.smaller-icon {
				svg {
					width: 28px;
					height: 28px;
				}
			}
		}

		.text {
			.title {
				display: block;
				text-align: center;
				font-size: 18px;
				font-weight: 500;
				white-space: nowrap;

				@include medium-screens-and-up {
					text-align: left;
					font-size: 22px;
				}
			}

			.description {
				display: none;
				align-items: center;
				color: rgba(0, 0, 0, 0.5);
				margin-top: 2px;

				.fake-tooltip {
					align-items: center;
					background: rgba(0, 0, 0, .06);
					border-radius: 28px;
					color: rgba(0, 0, 0, .65);
					display: flex;
					font-weight: 500;
					justify-content: center;
					margin: 0 6px;
					height: 24px;
					width: 24px;
					transform: scale(0.9);
				}

				@include medium-screens-and-up {
					display: flex;
				}
			}
		}

		@include medium-screens-and-up {
			left: 25px;
			transform: translateY(-50%);
		}
	}

	&.green-border {
		border-color: #26E66D;

		.title {
			color: #26E66D;
		}

		.icon {
			color: #26E66D;
		}
	}

	&.red-border {
		border-color: #e62643;

		.title {
			color: #e62643;
		}

		.icon {
			color: #e62643;
		}
	}

	&.blue-border {
		border-color: #2696e6;

		.title {
			color: #2696e6;
		}

		.icon {
			color: #2696e6;
		}
	}

	@include medium-screens-and-up {
		padding: 45px 20px 20px 20px;
	}
}

.overview-page .items-divider {
	display: flex;
	text-align: center;
	justify-items: center;
	align-items: center;
	margin: 0 0 46px 0;

	.middle {
		display: flex;
		flex-direction: column;
		align-items: center;
		white-space: nowrap;
		font-size: 18px;
		font-weight: 400;
		padding: 8px 2px;
		background: #fff;
		border-radius: 1000px;
		box-shadow: 0 3px 6px rgba(0,0,0,.05);

		.text {
			display: flex;
			align-items: center;
		}

		.feather {
			margin: 0 12px;
			animation: scroll 2s infinite;

			@keyframes scroll {
				0% {
					transform: translateY(-10px);
					opacity: 0;
				}
				25% {
					opacity: 0.2;
				}
				50% {
					opacity: 0.2;
				}
				99% {
					transform: translateY(10px);
					opacity: 0;
				}
				100% {
					transform: translateY(-10px);
					opacity: 0;
				}
			}
		}
	}

	.line {
		background: #e8e8e8;
		height: 2px;
		width: 100%;
	}
}

.items {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap: 15px;
	row-gap: 15px;
	width: 100%;

	.item {
		position: relative;
		display: flex;
		flex-flow: column;
		background: white;
		text-align: center;
		padding: 10px;
		border-radius: 20px;
		border: solid 2px white;
		box-shadow: 0 3px 8px rgba(0, 0, 0, .06);
		transition: all 250ms;
		cursor: pointer;
		text-decoration: none;
		color: black;

		.image {
			padding: 8px;
			width: 70%;
			margin: auto;

			img {
				width: 100%;
				display: block;
			}

			@include medium-screens-and-up {
				padding: 0;
			}
		}

		.name {
			display: flex;
			flex-flow: column;
			justify-content: center;
			align-items: center;
			flex: 1 1 auto;
			margin-top: 10px;

			h2 {
				flex: 0 1 auto;
				font-size: 18px;
				font-weight: 400;
			}

			h3 {
				font-size: 14px;
				font-weight: 400;
				margin-top: 2px;
			}

			&.smaller {
				h2 {
					font-size: 18px;
				}
			}

			@include medium-screens-and-up {
				margin-top: 20px;

				h2 {
					font-size: 20px;
				}
			}
		}

		.item-tooltip {
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 28px;
			position: absolute;
			bottom: 10px;
			right: 10px;
			font-weight: 500;
			cursor: pointer;
			width: 28px;
			height: 28px;
			background: rgba(0, 0, 0, 0.06);
			color: rgba(0, 0, 0, 0.65);
		}

		&.active {
			border-color: #26E66D;
			box-shadow: 0 3px 8px rgba(38, 230, 109, 0.50);
		}

		&:hover {
			transform: scale(1.1);
		}

		@include medium-screens-and-up {
			padding: 20px 20px;
		}
	}

	&.disabled {
		.item {
			opacity: 0.6;
			cursor: not-allowed !important;

			&:hover {
				transform: scale(1) !important;
			}
		}
	}

	@include small-screens-and-up {
		grid-template-columns: repeat(3, 1fr);
		column-gap: 30px;
		row-gap: 25px;
	}

	@include large-screens-and-up {
		grid-template-columns: repeat(4, 1fr);
	}

	@include extra-large-screens-and-up {
		grid-template-columns: repeat(5, 1fr);
	}
}

.input-tooltip {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 28px;
	font-weight: 500;
	cursor: pointer;
	width: 22px;
	height: 22px;
	font-size: 14px;
	background: rgba(0, 0, 0, 0.06);
	color: rgba(0, 0, 0, 0.65);
	border: solid 1px rgba(0, 0, 0, 0.075);
	margin-left: 6px;
}

.actions {
	display: flex;
	justify-content: space-between;
	margin: 45px 0;
}

.modal .actions {
	margin: 0;
}

.actions.next-only {
	justify-content: flex-end;
}

.actions button {
	display: flex;
	align-items: center;
	border-radius: 10px;
	border: none;
	font-size: 20px;
	cursor: pointer;
	transition: background-color 250ms;
}

.actions button:focus {
	outline: none;
}

.actions .next {
	display: flex;
	background: #26E66D;
	color: white;
	padding: 14px 12px 14px 28px;
}

.actions .next.disabled {
	display: none;
	background: rgba(38, 230, 108, 0.5);
	cursor: not-allowed !important;
}

.actions .not-edited-help {
	line-height: 52px;
	margin-right: 14px;
	font-size: 18px;
	color: rgba(0, 0, 0, 0.75);
}

.actions .next.no-icon, .actions .previous.no-icon {
	padding: 14px 28px;
}

.actions .next:hover {
	background: rgba(38, 230, 108, 0.75);
}

.actions .next svg {
	margin-left: 6px;
}

.actions .previous {
	background: rgb(235, 235, 235);
	padding: 14px 28px 14px 12px;
	white-space: nowrap;
	align-self: center;
}

.actions .previous:hover {
	opacity: 0.6;
}

.actions .previous svg {
	margin-right: 6px;
}

.survey-login-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;

	.survey-login-title {
		display: block;
		text-align: center;
		padding: 64px 0 0 0;
		font-size: 24px;
		font-weight: 500;
		margin: 0;
	}

	.survey-login-subtitle {
		display: block;
		text-align: center;
		padding: 16px 0 48px 0;
		font-size: 18px;
		font-weight: 400;
		margin: 0;
	}
}



.panel-header {
	font-size: 26px;
	font-weight: 500;
	margin-bottom: 18px;
}

.panel-header.overview {
	font-size: 32px;
}

.panel {
	background: white;
	border-radius: 20px;
	border: 1px solid white;
	padding: 20px 25px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, .05);

}

.panel .title-row {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.panel .title {
	font-size: 28px;
	font-weight: 500;
	margin: 0;
}

.panel .subtitle {
	margin: 3px 0 0 0;
	font-size: 18px;
	font-weight: 400;
	color: rgba(0, 0, 0, 0.5);
}

.panel .action {
	padding: 12px 30px;
	color: white;
	background: #26E66D;
	font-size: 18px;
	font-weight: 500;
	border: none;
	border-radius: 10px;
	cursor: pointer;
	transition: all 250ms;
}

.panel .action:hover {
	background: rgba(38, 230, 108, 0.75);
}

.panel .action:focus {
	outline: none;
}

.panel table {
	margin: 24px 0 0 0;
	border-collapse: collapse;
	min-width: 100%;
}

.panel table thead {
	background: #F5F5F5;
}

.panel table thead th {
	padding: 14px 0;
	font-size: 16px;
	color: rgba(0, 0, 0, .5);
	text-transform: uppercase;
	text-align: left;
}

.panel table thead th:first-child {
	border-radius: 10px 0 0 10px;
	padding: 0 0 0 20px;
}

.panel table thead th:last-child {
	border-radius: 0 10px 10px 0;
}

.panel table tbody tr {
	border-bottom: solid 1px #F5F5F5;
}

.panel table tbody tr:last-child {
	border-bottom: 0;
}

.panel table tbody td {
	font-size: 18px;
	padding: 20px 0;
}

.panel table tbody td:first-child {
	padding: 0 0 0 20px;
}

.panel table tbody .row-actions {
	padding: 0;
	height: 60px;
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	vertical-align: middle;
}

.panel table tbody .row-actions button {
	display: flex;
	align-items: center;
	justify-content: center;
	background: #F5F5F5;
	color: #7A7A7A;
	border: none;
	border-radius: 1000px;
	height: 38px;
	width: 38px;
	margin-right: 8px;
	transition: all 250ms;
	cursor: pointer;
}

.panel table tbody .row-actions button:focus {
	outline: none;
}

.panel table tbody .row-actions button:hover {
	background: rgb(232, 232, 232);
}

.panel table tbody .row-actions button:first-child {
	margin-right: 20px;
}

.panel table tbody .row-actions button svg {
	height: 16px;
}

.modal .overlay {
	visibility: hidden;
	opacity: 0;
	z-index: 3001;
	position: fixed;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.5);
	width: 100%;
	min-height: 100vh;
	height: 100%;
	transition: opacity 750ms;
	overflow-y: auto;
}

.modal.open .overlay, .pdf-modal.open .overlay {
	visibility: visible;
	opacity: 1;
}

.pdf-modal {
	visibility: hidden;

	.body {
		z-index: 1013;
		width: 675px;
		min-height: 75vh;
		max-width: calc(100% - 12px);
		max-height: calc(80vh - 24px);
		background: white;
		position: fixed;
		transform: translate(-50%, -50%);
		left: 50%;
		top: 50%;
		border-radius: 10px;
		transition: opacity 500ms;
		padding: 15px;

		.dismiss {
			position: absolute;
			right: 0;
			top: -45px;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			border: none;
			border-radius: 10px;
			color: black;
			background: white;
			height: 35px;
			width: 35px;
			cursor: pointer;
			transition: background-color 250ms;

			&:focus {
				outline: none;
			}

			&:hover {
				background: #F5F5F5;
			}
		}

		.embed-container {
			position: relative;
			padding-bottom: calc(75vh - 30px); /* set the aspect ratio here as (height / width) * 100% */
			height: 0;
			overflow: hidden;
			max-width: 100%;

			iframe {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
	}


	.overlay {
		visibility: hidden;
		opacity: 0;
		z-index: 1012;
		position: fixed;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.5);
		width: 100vw;
		min-height: 100vh;
		height: 100%;
		transition: opacity 750ms;
		overflow-y: auto;
	}

	&.open {
		visibility: visible;
		opacity: 1;
	}
}

.modal {
	.body {
		visibility: hidden;
		opacity: 0;
		z-index: 3002;
		width: 675px;
		max-width: calc(100% - 12px);
		max-height: calc(80vh - 24px);
		background: white;
		position: fixed;
		transform: translateX(-50%);
		left: 50%;
		top: 12px;
		border-radius: 10px;
		transition: opacity 500ms;
	}

	&.supplier-permission-modal, &.multi-supplier-permission-modal {
		.body {
			width: 475px;
			transform: translate(-50%, -50%);
			top: 50%;

			.modal-header {
				height: 52px;
				text-align: center;

				.header-title {
					font-size: 22px;
					line-height: 1.5;
				}
			}

			.modal-content {
				.notice {
					font-size: 14px;
					color: rgba(0, 0, 0, 0.5);

					.moduleName {
						font-weight: 500;
					}
				}

				.supplier-additional-services-form {
					margin: 0 0 15px 0;


					.checkbox .checkmark {
						box-shadow: 0 0 4px rgb(0 0 0 / 5%);
					}

					.fields-group {
						border-bottom: 1px solid rgb(0 0 0 / 5%);
						margin-bottom: 20px;
						padding-bottom: 5px;

						&:last-of-type {
							border-bottom: 0;
							padding-bottom: 0;
						}
					}

					.field {
						display: flex;
						flex-direction: column;
						position: relative;
						margin-bottom: 15px;

						&.text-field {
							.input {
								padding: 10px 12px;
								border-radius: 5px;
								background: white;
								font-size: 18px;
								border: solid 1px #E4E4E4;
								color: #7A7A7A;
								box-shadow: 0 0 4px rgba(0, 0, 0, 0.05);
								height: auto;

								&:focus {
									outline: none;
								}

								&::placeholder {
									font-size: 16px;
									opacity: 0.5;
								}
							}

							.input[type=date]::-webkit-calendar-picker-indicator {
								background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23E4E4E4" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
								cursor: pointer;
							}

							.input[type=number] {
								-moz-appearance: textfield;

								&::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
									-webkit-appearance: none;
									margin: 0;
								}
							}

							.input-label {
								font-size: 14px;
								position: absolute;
								top: 0;
								left: 12px;
								opacity: 0;
							}

							.input:not(:placeholder-shown) {
								padding-top: 28px;
							}

							.input:not(:placeholder-shown) + .input-label {
								top: 8px;
								opacity: 0.5;
							}
						}

						&.dropdown-field {
							.dropdown {
								display: block;
								position: relative;

								.button {
									position: relative;
									display: flex;
									align-items: center;
									width: 100%;
									padding: 28px 12px 10px 12px;
									border-radius: 5px;
									background: white;
									font-size: 16px;
									border: solid 1px #E4E4E4;
									color: #7A7A7A;
									box-shadow: 0 0 4px rgba(0, 0, 0, 0.05);
									cursor: pointer;

									.selected {
										font-size: 16px;
									}

									.icon {
										position: absolute;
										right: 10px;
										margin-left: auto;
										width: 24px;
										height: 24px;
										opacity: 0.5;
									}
								}

								.dropdown-content {
									z-index: 1010;
									text-align: left;
									position: absolute;
									top: 70px;
									display: none;
									padding: 10px 12px;
									border-radius: 5px;
									background: white;
									font-size: 18px;
									border: solid 1px #E4E4E4;
									color: #7A7A7A;
									box-shadow: 0 0 4px rgba(0, 0, 0, 0.05);

									ul {
										max-height: 250px;
										overflow-x: auto;
										overflow-y: auto;
										list-style: none;

										li {
											padding: 6px 8px;
											margin: 2px 0;
											cursor: pointer;
											border-radius: 4px;
											white-space: nowrap;
											overflow-x: auto;
											font-size: 16px;

											&:hover {
												background: rgba(0, 0, 0, 0.035);
											}
										}

										.no-data {
											padding: 8px 8px;

											&.hidden {
												display: none;
											}
										}

										.simplebar-wrapper {
											display: block;
											overflow: auto;
											overflow-y: hidden;
										}

										.simplebar-content {
											width: fit-content;
											max-width: 300px;
										}

									}

									.searchbar {
										margin-bottom: 10px;

										input {
											display: block;
											width: 100%;
											padding: 8px 10px;
											border-radius: 5px;
											border: solid 1px #E4E4E4;

											&::placeholder {
												opacity: 0.75;
											}

											&:focus {
												outline: none;
											}
										}
									}

									&.open {
										display: block;
									}
								}
							}

							.input-label {
								font-size: 14px;
								position: absolute;
								top: 0;
								left: 12px;
								opacity: 0;
							}

							&:not(.dropdown-placeholder) {
								.input-label {
									font-size: 14px;
									left: 12px;
									top: 8px;
									opacity: 0.5;
								}
							}

							&.dropdown-placeholder .dropdown{
								.button {
									padding-top: 10px;

									.selected {
										opacity: 0.5;
									}
								}

								.dropdown-content {
									top: 52px;
								}
							}
						}

						&.upload-field {
							padding: 10px 12px;
							border-radius: 5px;
							background: white;
							font-size: 18px;
							border: solid 1px #E4E4E4;
							color: #7A7A7A;
							box-shadow: 0 0 4px rgba(0, 0, 0, 0.05);
							height: auto;
							cursor: pointer;

							.input-file-header {
								display: block;
								font-size: 14px;
								cursor: pointer;
							}

							.input-file-name {
								display: block;
								padding: 6px 0;
								font-size: 14px;
								opacity: 0.75;
								cursor: pointer;
							}

							.input-file-validation {
								display: block;
								font-size: 12px;
								opacity: 0.5;
								cursor: pointer;
							}

							&.with-icon .input-label {
								display: flex;
								align-items: center;

								.icon {
									margin-right: 15px;
									margin-left: 5px;
									height: 32px;
									width: 32px;
									color: rgb(219 219 219);
								}
							}
						}

						.checkbox {
							display: flex;
							align-items: center;
							width: 100%;
							color: #7A7A7A;
							height: 22px;
							position: relative;
							padding-left: 32px;
							cursor: pointer;
							font-size: 18px;
							-webkit-user-select: none;
							-moz-user-select: none;
							-ms-user-select: none;
							user-select: none;

							input[type=checkbox] {
								position: absolute;
								opacity: 0;
								cursor: pointer;
								height: 0;
								width: 0;
							}

							.checkmark {
								position: absolute;
								top: 0;
								left: 0;
								height: 22px;
								width: 22px;
								border: solid 1px #E4E4E4;
								box-shadow: 0 0 4px rgb(0 0 0 / 5%);
							}


							input[type=checkbox]:checked ~ .checkmark {
								background-color: #26E66D;
								border-color: #26E66D;
							}

							.checkmark:after {
								content: "";
								position: absolute;
								display: none;
							}

							input[type=checkbox]:checked ~ .checkmark:after {
								display: block;
							}

							.checkmark:after {
								left: 6px;
								top: 2px;
								width: 5px;
								height: 10px;
								border: solid white;
								border-width: 0 3px 3px 0;
								-webkit-transform: rotate(45deg);
								-ms-transform: rotate(45deg);
								transform: rotate(45deg);
							}


						}

						&.has-error {
							margin-bottom: 10px;
						}
					}

					.field-error-message {
						display: flex;
						align-items: center;
						font-size: 14px;
						color: red;
						margin-bottom: 15px;

						.icon {
							margin-right: 5px;
							width: 20px;
							height: 20px;
						}
					}
				}

				.supplier-list {
					margin-bottom: 15px;
					list-style: none;

					li {
						display: flex;
						align-items: center;
						margin: 4px 0;

						.checkboxText {
							cursor: pointer;
							font-size: 16px;
							font-weight: 500;
							padding-left: 10px;
						}

					}
				}

				.includeInstallField {
					display: flex;
					align-items: center;
					margin-bottom: 18px;

					.checkboxText {
						margin-left: 16px;
						cursor: pointer;
					}
				}

				.data-list-label, .supplier-list-label {
					display: block;
					font-size: 14px;
					margin: 16px 0 4px 0;

					&.data-list-label {
						color: rgba(0, 0, 0, 0.5);
					}
				}

				.supplier-list-label {
					font-size: 16px;
					margin: 0 0 10px 0;
				}

				.data-list {
					font-size: 14px;
					font-weight: 500;
					margin: 0 0 15px 20px;
					color: rgba(0, 0, 0, 0.5);
				}

				.success-alert{
					display: none;
				}
			}

			.modal-footer {
				height: 66px;

				.actions {
					button {
						padding: 10px 24px;
						font-size: 18px;
						border-radius: 8px;
					}
				}
			}
		}

		&.success {
			.modal-header, .modal-footer {
				display: none;
			}

			.modal-content {
				padding: 18px;

				.details {
					display: none;
				}

				.success-alert {
					display: flex;
					align-items: center;
					justify-content: center;

					.info {
						display: flex;
						flex-direction: column;

						.title {
							display: flex;
							align-items: center;
							font-size: 18px;
							font-weight: 500;
							color: #26E66D;
							margin-bottom: 10px;

							svg {
								display: block;
								height: 30px;
								width: 30px;
								margin-right: 10px;
								color: #26E66D;
							}
						}

						.content {
							font-size: 16px;
						}

						.dismiss-button {
							display: block;
							margin-top: 10px;
							border-radius: 4px;
							padding: 8px 0;
							background: #ebebeb;
							border: none;
							cursor: pointer;
							transition: all 250ms;

							&:hover, &:focus {
								opacity: 0.6;
							}
						}
					}


				}
			}
		}
	}

	&.contact-modal {

		.modal-content {
			.input-row {
				.input {
					width: 100%;
					display: flex;
					flex-direction: column;

					[type=text], [type=email], textarea {
						padding: 10px 12px;
						border-radius: 10px;
						border: solid 1px #E4E4E4;
						background: #F8F8F8;
						color: #7A7A7A;
						font-size: 18px;
						resize: vertical;

						&:focus {
							outline: none;
						}
					}
				}

				&.two-inputs {
					display: flex;
					justify-content: space-between;

					.input {
						width: calc(50% - 5px);
					}
				}
			}
		}

		.success-alert {
			display: none;
			align-items: center;
			padding: 15px 15px;
			color: #26E66D;
			background: rgb(38 230 109 / 8%);
			border: solid 1px #26E66D;
			margin-top: 15px;
			border-radius: 10px;

			.feather {
				margin-right: 10px;
			}
		}

		&.success {
			.success-alert {
				display: flex;
			}
		}

	}
}

.modal .body .modal-header {
	z-index: 1004;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background: white;
	height: 70px;
	padding: 10px 16px;
	border-bottom: solid 1px rgba(0, 0, 0, 0.1);
	border-radius: 10px 10px 0 0;
}

.modal .body .modal-header .header-title {
	line-height: 49px;
	margin-bottom: 0;
}

.modal .body .modal-content {
	max-height: calc(80vh - 24px);
	padding: 70px 18px 70px 18px;
	overflow-y: auto;
}

.modal .body .modal-content table {
	margin: 12px 0;
	white-space: nowrap;
	max-width: 100%;
}

.modal .body .modal-content table#boilerImportList tbody td:nth-of-type(-n+2) {
	width: 1px;
	white-space: nowrap;
}

.modal .body .modal-content .timeseries-table {
	display: inline-flex;
	overflow: auto;
	width: 100%;
}

.modal .body .modal-content .timeseries-table table {
	display: block;
	width: auto;
	white-space: nowrap;
}

.modal .body .modal-content .timeseries-table table tbody tr th {
	padding-top: 1px;
	padding-bottom: 1px;
}

.modal .body .modal-content table tbody td {
	padding: 20px 10px;
}

.modal .body .modal-footer {
	z-index: 1004;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	background: white;
	height: 70px;
	padding: 12px;
	border-top: solid 1px rgba(0, 0, 0, 0.1);
	border-radius: 0 0 10px 10px;
}

.modal.open .body {
	visibility: visible;
	opacity: 1;
}

.modal .body .header-title {
	font-size: 26px;
	font-weight: 500;
	margin: 0 0 25px 0;
}

.modal .body .actions button {
	padding: 12px 36px;
	font-size: 20px;
}

.modal .body .actions .cancel {
	background: rgb(235, 235, 235);
}

.modal .body .actions .submit {
	background: #26E66D;
	color: white;

	&:enabled:hover {
		background: rgba(38, 230, 108, 0.75);
	}

	&:disabled {
		opacity: 0.5;
		cursor: default;
	}
}


.modal .body .actions .cancel:hover {
	opacity: 0.6;
}


.modal .body .input.error {
	.input-badge {
		border-radius: 10px;
		box-shadow: 0 0 0 4px rgba(204,0,0,.15);

		.badge {
			background: rgba(204,0,0,.075)!important;
			border-color: #c00!important;
			color: #c00!important;
		}
	}

	input:not(.searchbar-input) {
		background: rgba(204,0,0,.075)!important;
		border-color: #c00!important;
		color: #c00!important;
	}
}

.modal .body .input-block label, .modal .body .input-row label {
	display: inline-block;
	position: relative;
	margin: 0 0 10px 0;
	font-size: 18px;
}

.modal .body input[type=text], .modal .body input[type=number] {
	width: 100%;
	height: 42px;
	padding: 10px 12px;
	border-radius: 10px;
	border: solid 1px #E4E4E4;
	background: #F8F8F8;
	color: #7A7A7A;
	font-size: 18px;
	-moz-appearance: textfield;
}

.modal .body input[type=text]:focus, .modal .body input[type=number]:focus {
	outline: none;
}

.modal .body .input-block, .modal .body .input-row {
	display: block;
	margin: 20px 0;
}

.modal .body .input-inline {
	display: flex;
	justify-content: space-between;
}

.modal .body .input-inline.three-inputs {
	width: 100%;
}

.modal .body .input-inline .inline {
	width: calc(50% - 10px);
}

.modal .body .title-label {
	display: block;
	font-weight: 500;
	font-size: 20px !important;
	margin-top: 8px;
}
.modal .body .section-title {
	display: block;
	font-weight: 500;
	font-size: 22px !important;
	margin-bottom: 0;
}

.modal .body .figure-block {
	display: block;
	margin: 12px 0;
}

.modal .body .figure-block .figure {
	display: block;
	margin: 8px 0;
	font-size: 18px;
}

.duplicate-lighting-modal {
	z-index: 1015;
	position: relative;
}

.input-badge {
	width: auto;
	display: flex;
	align-items: center;

}

.input-badge input {
	border-radius: 0 10px 10px 0;
}

.input-badge .badge {
	background: #F8F8F8;
	color: #7A7A7A;
	border-radius: 10px 0 0 10px;
	font-size: 18px;
	height: 42px;
	padding: 10px 12px;
	border: solid 1px #E4E4E4;
	border-right: 0;
}

.input-badge.right-side .badge {
	border-radius: 0 10px 10px 0;
	border: solid 1px #E4E4E4;
	border-left: 0;
}

.install-rate .input-badge input {
	border-radius: 0 10px 10px 0 !important;
}

.input-badge.right-side input {
	border-radius: 10px 0 0 10px !important;
}

.modal .body input::-webkit-outer-spin-button,
.modal .body input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.modal .body input[type=number] {
	-moz-appearance:textfield; /* Firefox */
}

.modal.credit-purchase-modal {
	.body {
		.modal-header {
			display: flex;
			align-items: center;

			.price {
				display: flex;
				align-items: center;
				font-size: 18px;
				margin-left: 6px;

				.amount {
					display: block;
					align-self: center;
					background: #26E66D;
					padding: 6px 8px;
					border-radius: 4px;
					color: white;
					margin-left: 4px;
				}
			}
		}

		.modal-content {
			.error-block {
				padding: 20px;
				margin: 12px 0;
				background: rgba(255,0,0,0.25);
				border-radius: 10px;

				&.hide {
					display: none;
				}
			}

			.purchase-item-info {
				margin-top: 18px;

				.product-description {
					font-size: 18px;
					line-height: 1.25;
				}

			}

			.purchase-meta-info {
				margin: 18px 0;

				.purchase-more-credits {
					display: block;
					padding: 18px;
					border: solid 1px #26E66D;
					border-radius: 10px;
					margin-bottom: 18px;

					.purchase-credits-title {
						display: block;
						font-size: 20px;
						font-weight: 500;
					}

					.purchase-credits-subtitle {
						display: block;
						margin-top: 4px;
						font-size: 16px;
						color: rgba(0, 0, 0, 0.75);
					}

					.purchase-credits-button {
						display: inline-flex;
						text-decoration: none;
						color: white;
						background: #26E66D;
						font-size: 16px;
						padding: 10px 14px;
						margin: 12px 0 0 0;
						border-radius: 6px;
						cursor: pointer;
						transition: background-color 250ms;

						&:hover {
							background: rgba(38, 230, 108, 0.75);
						}
					}
				}

				.text {
					display: block;
					color: rgba(0, 0, 0, 0.75);
					margin-top: 2px;

					&.hide {
						display: none;
					}
				}
			}
		}
	}
}

.embed-form .header-title {
	font-size: 26px;
	font-weight: 500;
	margin: 0 0 25px 0;
}

.embed-form .input-block, .embed-form .input-row {
	display: block;
	margin: 20px 0;
}

.embed-form .input-block label, .embed-form.input-row label {
	display: inline-block;
	position: relative;
	margin: 0 0 10px 0;
	font-size: 18px;
}

.tooltip {
	position: absolute;
	right: -32px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background: #EEEEEE;
	color: #7A7A7A;
	font-size: 15px;
	height: 22px;
	width: 22px;
	border-radius: 24px;
	margin-left: 8px;
	cursor: pointer;
}


.tooltip.tooltip-overview {
	position: relative;
	right: 0;
	display: inline-flex;
	width: 22px !important;
	margin-left: 0px;
	bottom: 3px;
}

.tooltip:hover ~ .tooltip-content {
	visibility: visible;
	opacity: 1;
}


.tooltip-content {
	visibility: hidden;
	opacity: 0;
	position: absolute;
	transform: translateX(100%);
	right: -38px;
	top: 0;
	min-width: 200px;
	border: solid 1px #E4E4E4;
	background: #F8F8F8;
	color: #7A7A7A;
	border-radius: 5px;
	padding: 8px;
	font-size: 16px;
	transition: all 250ms;
}

.embed-form input::-webkit-outer-spin-button, .embed-form input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.embed-form input[type=month] {
	display: block;
	border: solid 1px #E4E4E4;
	background: #F8F8F8;
	color: #7A7A7A;
	width: 100%;
	padding: 8px 12px;
	border-radius: 10px;
	cursor: pointer;
}

.embed-form input[type=month]:focus {
	outline: none;
}

.embed-form input[type=text], .embed-form input[type=number] {
	width: 100%;
	height: 42px;
	padding: 10px 12px;
	border-radius: 10px;
	border: solid 1px #E4E4E4;
	background: #F8F8F8;
	color: #7A7A7A;
	font-size: 18px;
	-moz-appearance: textfield;
}

.embed-form input[type=text]::placeholder, .embed-form input[type=number]::placeholder {
	color: #d0d0d0;
}

.embed-form input[type=text]:focus, .embed-form input[type=number]:focus {
	outline: none;
}

.embed-form .input-row {
	display: flex;
	justify-content: space-between;
}

.embed-form .input-row .input.error {

	.dropdown .button {
		color: red;
		border-color: red;
	}

	input[type=number], input[type=text] {
		color: red;
		border-color: red;
	}
}

.embed-form .input-row .input.error-disabled {

	.dropdown .button {
		background: #eeeeee;
		pointer-events: none;

		.selected {
			color: #d0d0d0;
		}
	}

	input[type=number], input[type=text] {
		background: #eeeeee;
		pointer-events: none;
	}
}


.embed-form .input-row .input.error .dropdown-content {
}

.embed-form .input-row .input.error {
	background: white;
}

.embed-form .input-row .input.error .inputSuggestionField {
}

.embed-form .input-row.two-inputs .input {
	width: calc(50% - 10px);
}

.embed-form .input-row.two-one-inputs .group {
	display: flex;
	justify-content: space-between;
	width: calc(50% - 10px);
}

.embed-form .input-row.two-one-inputs .group .input {
	width: calc(50% - 10px);
}

.embed-form .input-row.two-one-inputs .input {
	width: calc(50% - 10px);
}

.embed-form .input-row.four-inputs .input {
	width: calc(25% - 10px);
}

.embed-form .input-row.three-inputs {
	display: flex;
	justify-content: space-between;
}

.embed-form .input-row.three-inputs .input {
	width: calc(33% - 10px);

	&.center-checkbox-vertical {
		display: flex;
		align-items: center;

		.checkbox + label {
			margin-bottom: 0;
			bottom: 0;
		}
	}
}

.embed-form .input.disabled input, .embed-form .input.disabled .dropdown .button  {
	background: #eeeeee;
	color: #d0d0d0;
	cursor: default;

	.selected {
		color: #d0d0d0;
	}
}

.embed-form .input.next input, .embed-form .input.next .dropdown .button  {
	color: #26E66D;
	border-color: #26E66D;

	.selected {
		color: #26E66D;
	}
}

.embed-form .header {
	text-align: left;
	margin: 0 0 16px 0;

	&.edit-lighting-specific {
		display: block;
		.inner-content {
			display: flex;
			align-items: center;
			margin-bottom: 8px;

			.title {
				margin-bottom: 0;
			}

			.icon {
				margin-right: 8px;
			}
		}
	}
}

.embed-form .header.border-bottom {
	padding-bottom: 16px;
	border-bottom: solid 1px #E4E4E4;
}

.embed-form .header.with-step-count {
	display: flex;
	align-items: center;
}

.embed-form .header .step-count {
	font-size: 26px;
	font-weight: 600;
	margin-right: 12px;
}

.embed-form .header .title {
	font-size: 22px;
	margin: 0 0 4px 0;
}

.embed-form .header .subtitle {
	font-size: 16px;
}

.embed-form .dropdown {
	position: relative;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.embed-form .dropdown .button {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px 12px;
	border-radius: 10px;
	border: solid 1px #E4E4E4;
	background: #F8F8F8;
	color: #7A7A7A;
	font-size: 18px;
	cursor: pointer;
}

.embed-form .dropdown .button span {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.embed-form .dropdown .dropdown-content {
	z-index: 1012;
	min-width: 100%;
	display: none;
	position: absolute;
	top: 56px;
	border-radius: 10px;
	border: solid 1px #E4E4E4;
	background: #F8F8F8;
	color: #7A7A7A;
}

.embed-form .dropdown .dropdown-content ul {
	min-width: 100%;
	max-height: 250px;
	overflow-x: auto;
	overflow-y: auto;
	list-style: none;
	padding: 8px;
}

.embed-form .dropdown-content ul.scrollbar-less {
	max-height: 1000px;
}

.embed-form .dropdown .dropdown-content.open {
	display: block;
}

.embed-form .dropdown .dropdown-content ul li {
	display: block;
	font-size: 18px;
	padding: 10px 10px;
	cursor: pointer;
	white-space: nowrap;
}

.embed-form .dropdown .dropdown-content ul li:hover {
	background: #E4E4E4;
	border-radius: 5px;
}

.simplebar-scrollbar::before {
	background-color: #7A7A7A;
}

.embed-form .usage-table.error table {
	background: rgba(38, 230, 109, 0.075);
	border: solid 1px #26E66D;
	position: relative;
}

.embed-form .usage-table table {
	width: 100%;
	border-collapse: collapse;
}

.embed-form .usage-table table thead th {
	padding: 14px 0;
	font-size: 16px;
	font-weight: 700;
	color: rgba(0, 0, 0, .5);
	text-transform: uppercase;
	text-align: center;
}

.embed-form .usage-table table thead th:first-of-type {
	border-radius: 10px 0 0 10px;
	padding: 0 0 0 20px;
}

.embed-form .usage-table table thead th:last-of-type {
	border-radius: 0 10px 10px 0;
}

.embed-form .usage-table table tbody tr:first-of-type {
	border-bottom: solid 1px rgba(228, 228, 228, 0.5);
}

.embed-form .usage-table table tbody td {
	text-align: center;
	height: 60px;
}

.embed-form .usage-table table tbody td:first-of-type {
	font-size: 18px;
	padding: 0 12px;
}

.embed-form .usage-table table tbody td input {
	display: inline-block;
	width: 35px;
	height: 35px;
	font-size: 16px;
	text-align: center;
	padding: 10px 0;
}

.embed-form .usage-table table tbody td {
	padding: 10px 0;
}

.embed-form .actions {
	margin: 28px 0 0 0;
}

.embed-form .actions button {
	padding: 12px 36px;
	font-size: 20px;
}

.embed-form .actions .cancel, .embed-form .actions .previous-step {
	background: rgb(235, 235, 235);
}

.embed-form .actions .cancel.hidden, .embed-form .actions .previous-step.hidden {
	display: none;
}

.embed-form .actions .cancel:hover, .embed-form .actions .previous-step:hover {
	opacity: 0.6;
}

.embed-form .actions .submit {
	background: #26E66D;
	color: white;
}

.embed-form .actions .submit.error {
	z-index: 1002;
}

.embed-form .actions .submit.hidden {
	display: none;
}

.embed-form .actions .submit:hover {
	background: rgba(38, 230, 108, 0.75);
}

.embed-form .input label {
	display: inline-flex;
	align-items: center;
	position: relative;
	margin: 0 0 10px 0;
	font-size: 18px;
}

.embed-form .inner-embed-form-page,.embed-form .subpage {
	display: none;
}

.embed-form .inner-embed-form-page.active, .embed-form .subpage.active {
	display: block;
}

.embed-form .subpage .subpage-title {
	font-weight: 400;
	font-size: 20px;
	margin-top: 18px;
}

.embed-form .input .checkbox {
	margin-bottom: 0;
}
.embed-form .input .checkbox ~ label {
	bottom: 4px;
	cursor: pointer;
}

.embed-form .input .checkbox {
	margin-right: 4px;
}

.embed-form .input input#emergencyQuantity {
	display: none;
}

.embed-form .input.emergencyQuantityInput label.has-tooltip {
	margin-bottom: 6px;
}

.embed-form .boiler-replacement-methods {
	margin-top: 6px;

	.input-tooltip {
		display: inline-flex;
	}

	.option {
		display: block;
		font-size: 20px;
		margin: 10px 0;

		&:first-of-type {
			margin-top: 12px;
		}

		input[type="radio"] {
			position: absolute;
			left: -9999px;

			&:disabled + label:before {
				background: #EEEEEE;
			}

			&:not(:checked) + label:after {
				opacity: 0;
				-webkit-transform: scale(0);
				transform: scale(0);
			}

			&:checked + label:after {
				opacity: 1;
				-webkit-transform: scale(1);
				transform: scale(1);
			}
		}

		label {
			position: relative;
			padding-left: 32px;
			cursor: pointer;
			line-height: 22px;
			display: inline-block;
			color: #000000;

			&:before {
				content: '';
				position: absolute;
				left: 0;
				top: 2px;
				width: 18px;
				height: 18px;
				border: 1px solid #ddd;
				border-radius: 100%;
				background: #fff;
			}

			&:after {
				content: '';
				width: 12px;
				height: 12px;
				background: #26E66D;
				position: absolute;
				top: 6px;
				left: 4px;
				border-radius: 100%;
				-webkit-transition: all 0.2s ease;
				transition: all 0.2s ease;
			}
		}

		.input-tooltip {
			position: relative;
			bottom: 2px;
		}
	}

	button {
		display: inline-block;
		text-align: left;
		font-size: 18px;
		padding: 8px 12px;
		margin-top: 10px;
		border-radius: 4px;
		border: solid 2px transparent;
		background: rgba(0, 0, 0, 0.035);
		cursor: pointer;
		transition: all 250ms ease;

		&:hover {
			border-color: #26E66D;
		}

		&:focus {
			outline: none;
		}
	}
}

.embed-form .boiler-input-methods {
	display: flex;
	flex-direction: column;
	align-items: center;

	.method-button {
		display: flex;
		align-items: center;
		align-self: center;
		border-radius: 10px;
		transition: all 250ms;
		cursor: pointer;
		border: none;
		padding: 14px 18px 14px 12px;
		font-size: 18px;

		.icon {
			height: 20px;
			width: 20px;
			margin-right: 6px;
		}

		&.primary {
			background-color: #26E66D;
			color: white;

			&:hover {
				background-color: rgba(38,230,108,0.75);
			}
		}

		&.secondary {
			&:hover {
				opacity: 0.6;
			}
		}
	}

	.divider-text {
		display: block;
		font-size: 18px;
		margin: 12px 0;
	}
}

.embed-form .sensors-detectors {
	margin-bottom: 10px;
}

.manualDegreeDays {
	display: inline-block;
	text-align: left;
	font-size: 18px;
	padding: 8px 12px;
	margin-top: 10px;
	border-radius: 4px;
	border: solid 2px transparent;
	background: rgb(235, 235, 235);
	cursor: pointer;
	transition: all 250ms ease;
}

.manualDegreeDays:hover {
	border-color: #26E66D;
}

.manualDegreeDays:focus {
	outline: none;
}

//Details input page
.details-page {
	//Panel
	.panel {
		padding: 25px;
		margin-bottom: 48px;

		//Input block header
		.block-header {
			font-size: 22px;
			font-weight: 500;
			margin: 32px 0 16px 0;

			&.has-tooltip {
				display: flex;
			}

			&:first-of-type {
				margin-top: 0;
			}
		}

		//Input block
		.block {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			margin-top: 24px;

			//Input container
			.input {
				//Input label
				label {
					width: 100%;
					display: block;
					font-size: 18px;

					&.has-tooltip {
						display: flex;
					}
				}

				//Input element
				input {
					width: 100%;
					display: block;
					background: #F8F8F8;
					border-radius: 10px;
					font-size: 18px;
					height: 42px;
					padding: 10px 12px;
					border: solid 1px #E4E4E4;
					color: #7A7A7A;
					margin-top: 10px;
					-moz-appearance: textfield;

					&:disabled {
						background: #efefef;
					}

					&:focus {
						outline: none;
					}

					//Hide arrows from number inputs
					&::-webkit-outer-spin-button,
					&::-webkit-inner-spin-button {
						-webkit-appearance: none;
						margin: 0;
					}
				}

				//Dropdown
				.wtp-dropdown {
					margin-top: 10px;
				}

				//Input element with badge
				.input-badge {
					width: auto;
					display: flex;
					align-items: center;

					input {
						border-radius: 10px 0 0 10px;
					}

					.badge {
						background: #F8F8F8;
						color: #7A7A7A;
						border-radius: 0 10px 10px 0;
						font-size: 18px;
						height: 42px;
						padding: 10px 12px;
						border: solid 1px #E4E4E4;
						border-left: 0;
						margin-top: 10px;
					}
				}
			}

			//Two inputs in a block
			&.two-inputs {
				display: flex;
				gap: 20px;

				.input {
					width: 100%;

					@include medium-screens-and-up {
						width: 50%;
					}
				}
			}

			//A button then two inputs in a block
			&.two-inputs-with-button {
				display: flex;
				gap: 20px;
				justify-content: start;

				.input {
					width: 100%;

					@include medium-screens-and-up {
						width: 50%;
					}
				}
			}

			//Three inputs in a block
			&.three-inputs {
				display: flex;
				gap: 20px;

				.input {
					width: 100%;

					@include medium-screens-and-up {
						width: 33%;
					}
				}
			}

			//Heating consumption calculator
			#heatingConsumptionCalculator {
				display: flex;
				width: 100%;
				align-items: center;
				align-self: center;
				justify-content: center;
				padding: 12px 16px;
				background: #26e66d;
				font-weight: 500;
				color: white;
				border: none;
				border-radius: 10px;
				cursor: pointer;
				transition: background-color .25s;
				white-space: nowrap;

				svg {
					height: 26px;
					width: 26px;
					color: white;
					margin-right: 5px;
				}

				&:hover {
					background: rgba(38, 230, 108, .75);
				}

				&:focus {
					outline: none;
				}

				@include medium-screens-and-up {
					width: auto;
				}
			}

			&.hidden {
				display: none;
			}

			&:first-of-type {
				margin-top: 0;
			}

			@include medium-screens-and-up {
				flex-direction: row;
			}
		}

		//Small input block
		.block-small {
			display: flex;
			gap: 20px;
			align-items: center;
			margin-top: 12px;

			label {
				font-size: 18px;
			}

			.checkbox {
				margin-left: 0;
				margin-right: 2px;
			}
		}

		//Note above input
		.input-note {
			display: block;
			margin: 0 0 16px 0;
		}

		//Row of blocks
		.block-row {
			display: flex;
			flex-direction: column;
			width: 100%;
			margin-top: 20px;
			gap: 20px;

			//Column with block inside
			.row-column {
				width: 100%;

				.block .input {
					width: 100%;
				}

				@include medium-screens-and-up {
					width: 50%;
				}
			}

			@include medium-screens-and-up {
				flex-direction: row;
			}
		}

		&:last-of-type {
			margin-bottom: 0;
		}
	}
}


.page-content {
	.page-loader,
	.page-loader:after {
		border-radius: 50%;
		width: 10em;
		height: 10em;
	}
	.page-loader {
		margin: 50px auto;
		font-size: 10px;
		position: relative;
		text-indent: -9999em;
		border-top: 1.1em solid rgba(0, 0, 0, 0.05);
		border-right: 1.1em solid rgba(0, 0, 0, 0.05);
		border-bottom: 1.1em solid rgba(0, 0, 0, 0.05);
		border-left: 1.1em solid #26E66D;
		-webkit-transform: translateZ(0);
		-ms-transform: translateZ(0);
		transform: translateZ(0);
		-webkit-animation: load8 1.1s infinite linear;
		animation: load8 1.1s infinite linear;
	}
	.page-loader-title {
		text-align: center;
		font-weight: 500;
		font-size: 28px;
	}
	@-webkit-keyframes load8 {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	@keyframes load8 {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}

	.average-site-voltage-field {
		margin: 20px 0 0 0;
		display: flex;
		align-items: center;

		.icon {
			color: #26E66D;
		}

		.input-label {
			display: inline-flex;
			align-items: center;
			white-space: nowrap;
			margin: 0 10px 0 10px;

			.input-tooltip {
				background: none;
			}
		}

		.input {
			display: inline-block;
			width: 55px;
			text-align: center;
			padding: 8px 0;
			border-radius: 10px;
			border: solid 1px #E4E4E4;
			//background: #F8F8F8;
			color: #7A7A7A;
			-moz-appearance:textfield;

			&::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}

			&:focus {
				outline-color: rgb(38 230 109 / 50%);
			}
		}

		.save-input-button {
			border: none;
			background: #26E66D;
			color: white;
			padding: 9px 16px;
			border-radius: 10px;
			margin-left: 5px;
			cursor: pointer;
			transition: background-color 250ms;

			&.disabled {
				display: none;
			}

			&:not(:disabled):hover {
				background-color: rgba(38, 230, 108, 0.75);
			}

			&:focus {
				outline-color: rgb(38 230 109 / 50%);
			}

			&:disabled, &[disabled] {
				opacity: 0.25;
				cursor: default;
			}
		}
	}
}


.loading-page-title {
	font-weight: 500;
	font-size: 28px;
}

#noTechModuleSelector {
	display: flex;
	text-align: center;
	align-items: center;
	border-radius: 10px;
	border: none;
	font-size: 20px;
	margin: 0 auto 18.760px auto;
	cursor: pointer;
	transition: all 250ms;
	background: #26E66D;
	color: white;
	padding: 12px 24px;
	white-space: nowrap;

	&:hover {
		background: rgba(38,230,108,0.75);
	}
}

.improvements {
	margin: 60px 0 60px 0;

	&.column-layout {
		display: grid;
		grid-template-columns: 1fr  1fr;
		grid-column-gap: 20px;
		grid-row-gap: 15px;

		.column {
			display: flex;
			flex-direction: column;
			gap: 20px;

			.improvement {
				width: 100% !important;

				@media only screen and (max-width: 600px){
					margin-top: 0 !important;
				}
			}

			@media only screen and (max-width: 600px){
				gap: 15px;
			}
		}

		@media only screen and (max-width: 600px){
			grid-template-columns: 1fr;
		}
	}
}

.improvements .row {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	margin-top: 20px;
}

.improvements .improvement {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: calc(50% - 10px);
	background: white;
	border-radius: 20px;
	padding: 20px 30px 20px 30px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, .05);

	.divider-line {
		display: block;
		background: #F5F5F5;
		width: 100%;
		height: 2px;
		margin: 10px 0;
	}

	.medium-weight {
		font-weight: 500;
	}

	.footer {
		width: 100%;
	}

	.improvement-title {
		font-size: 18px;
		font-weight: 400;
		margin-bottom: 6px;
	}
}

.improvements .group {
	width: calc(50% - 10px);

	.improvement {
		width: 100%;
		margin-bottom: 20px;
		&:last-of-type {
			margin-bottom: 0;
		}
	}
}

.improvements .improvement.upgrades-block {
	align-items: normal;
	border: solid 1px #26E66D;
	box-shadow: 0 3px 8px rgb(38 230 109 / 50%);

	h2 {
		font-size: 16px;
		font-weight: 400;
		text-transform: uppercase;
		margin: 0 0 4px 0;
		letter-spacing: 1px;
		text-align: left;
	}

	table {
		border-collapse: collapse;
		margin-top: 10px;

		thead {
			background: #F5F5F5;

			td {
				padding: 10px 0;
				font-size: 16px;
				font-weight: 600;
				color: rgba(0,0,0,0.5);
				text-transform: uppercase;

				&:first-of-type {
					padding-left: 14px;
					border-radius: 10px 0 0 10px;
				}

				&:last-of-type {
					border-radius: 0 10px 10px 0;
					min-width: 10px;
				}
			}
		}

		tbody {
			td {
				padding: 10px 0;

				&.supplier-row {
					padding-left: 0 !important;

					.supplier-container {
						display: flex;
						align-items: center;
						padding-left: 0 !important;

						.supplier-logo {
							display: inline-block;
							max-height: 25px !important;
							margin-right: 10px;
						}

						.supplier-name {
							margin-right: 10px;
							white-space: nowrap;
						}

						.filler-line {
							display: block;
							background: #F5F5F5;
							width: 100%;
							height: 2px;
							border-radius: 10px;
						}

						.multi-supplier-contact-button, .multi-supplier-contact-unavailable-button {
							margin-left: 10px;
							display: flex;
							align-items: center;
							background: #26E66D;
							border: none;
							border-radius: 10px;
							color: white;
							padding: 8px 12px;
							cursor: pointer;
							transition: all 250ms;
							text-decoration: none;
							white-space: nowrap;

							svg.feather {
								width: 18px;
								height: 18px;
								margin-right: 6px;
							}

							&:hover {
								background: rgba(38,230,109,0.75);
							}

							&:disabled {
								opacity: 0.5;
								cursor: default;

								&:hover {
									background: #26E66D;
								}
							}

							&.multi-supplier-contact-unavailable-button, &.multi-supplier-contact-unavailable-button:hover {
								background: rgba(0, 0, 0, 0.125);
								color: black;
							}
						}
					}
				}



				&:first-of-type {
					padding-left: 14px;
					border-radius: 10px 0 0 10px;
				}

				&:last-of-type {
					border-radius: 0 10px 10px 0;
				}
			}
		}
	}

	.primary-button {
		font-size: 18px;
		background: #26E66D;
		border: none;
		border-radius: 10px;
		color: white;
		padding: 10px 20px;
		cursor: pointer;
		transition: all 250ms;
		margin-top: 8px;
		text-decoration: none;
		text-align: center;

		&:hover {
			background: rgba(38,230,109,0.75);
		}

		&.hide {
			display: none;
		}
	}

	.supplier-permission-granted, .additional-supplier-permission-granted {
		display: none;
		border-radius: 10px;
		padding: 15px;
		background: rgb(38 230 109 / 10%);
		color: #26E66D;
		margin-top: 10px;

		&.show {
			display: block;
		}
	}

	&.supplier-additional-services {
		padding: 20px 25px;

		h2 {
			text-transform: unset;
			text-align: center;
			font-size: 20px;
			margin-bottom: 8px;
			display: block;

			.highlight {
				font-style: normal;
				font-weight: 600;
				text-transform: uppercase;
				color: #26E66D;
			}
		}

		.supplier-details {
			display: flex;
			align-items: center;
			margin: 20px 0;

			.supplier-logo {
				height: 38px;
				margin-right: 20px;
			}

			.supplier-text {
				font-size: 20px;
				font-style: italic;
			}
		}
	}
}

.improvements .improvement .improvement-row {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 85px;
}

.improvements .stack-improvement {
	width: calc(50% - 10px);

	.improvement {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.purchase-carbon-offsets {
		display: grid;
		grid-template-columns: 36px 1fr;
		grid-template-rows: auto;
		justify-items: center;
		align-items: center;

		background: #26E66D;
		width: 100%;
		border: none;
		padding: 12px 12px;
		margin-top: 15px;
		border-radius: 10px;
		color: white;
		font-weight: 500;
		transition: all 250ms;
		cursor: pointer;

		.text {

		}

		svg {
			width: 18px;
			height: 18px;
			margin-right: 6px;
		}

		&:hover {
			background: rgba(38,230,109,0.75);
		}
	}
}


.improvements .improvement .collapse {
	display: none;
	margin-bottom: 16px;
}

.improvements .improvement .improvement-title {

}

.improvements .improvement .improvement-button {
	display: flex;
	align-items: center;
	padding: 6px 12px;
	min-height: 36px;
	font-size: 16px;
	font-weight: 500;
	width: 100%;
	border: none;
	background: #F8F8F8;
	color: rgba(0, 0, 0, 0.8);
	border-radius: 100px;
	cursor: pointer;
	transition: all 250ms;

	span {
		width: 100%;
	}

	.closed-text {
		display: none;
	}

	&:hover {
		background: #f3f3f3;
	}

	&:focus {
		outline: none;
	}

	&.icon-with-text {
		text-align: center;

		span {
			display: flex;
			align-items: center;
			justify-content: center;

			.icon {
				margin-right: 6px;
			}
		}
	}
}

.improvements .improvement .half {
	display: flex;
	align-items: center;

	.simple-edit-button {
		display: flex;
		align-items: center;
		background: #26e66d;
		border: none;
		border-radius: 10px;
		color: #fff;
		cursor: pointer;
		padding: 8px 16px;
		text-decoration: none;
		transition: background-color .25s;

		svg {
			height: 24px;
			margin-right: 6px;
		}

		&:hover {
			background-color: rgba(38,230,109,0.75);
		}

		&:focus {
			outline: 4px solid rgb(38 230 109 / 25%);
		}
	}
}

.improvements .improvement img {
	display: block;
	height: 65px
}

.improvements .improvement .info {
	margin-left: 30px;
}

.improvements .improvement .info h1 {
	margin: 0;
	font-size: 28px;
	font-weight: 500;
}

.improvements .improvement .info h2 {
	font-size: 16px;
	font-weight: 400;
	text-transform: uppercase;
	margin: 0 0 4px 0;
	letter-spacing: 1px;
}

.improvements .improvement .info-second {
	justify-self: flex-end;
	align-items: center;
	text-align: center;
	border-left: solid 1px rgba(0, 0, 0, .1);
	padding-left: 32px;
}

.improvements .improvement .info-second h1 {
	font-size: 28px;
	font-weight: 500;
	margin: 0;
	color: #26E66D;
}

.improvements .improvement .info-second h2 {
	font-size: 14px;
	font-weight: 400;
	text-transform: uppercase;
}

.improvements .improvement .info-second h3 {
	font-size: 14px;
	font-weight: 500;
	margin-top: 4px;
	text-transform: uppercase;
	color: #26E66D;
}

.improvements .improvement .savingsBreakdown {
	display: flex;
}

.improvements .improvement .savingsBreakdown div {
	width: 50%;
}

.improvements .improvement .savingsBreakdown.column div {
	width: 100%;
}

.improvements .improvement .savingsBreakdown strong, .improvements .improvement .costBreakdown strong, .improvements .improvement .savingsBreakdown span, .improvements .improvement .costBreakdown span {
	display: block;
	font-size: 18px;
	margin: 6px 0;
}

.improvements .improvement .costBreakdown div {
	width: 100%;
}

.improvements .improvement .costBreakdown .rates-action.two-rates-button {
	display: flex;
	gap: 10px;
}

.improvements .improvement .figure-checkbox {
	display: flex;
	margin: 16px 0 8px 0;

	.checkbox {
		.checkmark {
			margin: 0;
		}
	}

	.label {
		font-size: 18px;
		line-height: 1;
		margin-left: 8px;
		cursor: pointer;
	}
}

.upgrade-buttons {
	display: flex;
}

.purchase-upgrade {
	display: flex;
	align-items: center;
	background: #26E66D;
	border: none;
	border-radius: 10px;
	color: white;
	padding: 8px 12px;
	cursor: pointer;
	transition: all 250ms;
	margin-right: 10px;
	text-decoration: none;

	svg.feather {
		height: 24px;
		margin-right: 6px;
	}

	&:focus {
		outline: none;
	}

	&:hover {
		background: rgba(38, 230, 109, 0.75);
	}

	&:last-of-type {
		margin-right: 0;
	}
}


.upgrades-table {
	margin-top: 0 !important;
}

.upgrades-table thead tr th {
	text-align: center;
}

.upgrades-table thead tr th:first-of-type, .upgrades-table tbody tr td:first-of-type  {
	padding-left: 10px;
	padding-right: 15px;
}

.upgrades-table thead tr th:nth-child(2) {
	text-align: left;
}

.upgrades-table thead tr th:last-of-type {
	min-width: 10px;
}

.upgrades-table tbody tr td {
	text-align: center;
}

.upgrades-table tbody tr td:nth-child(2) {
	text-align: left;
}

.upgrades-table tbody tr td:last-of-type {
	width: 1px;
	white-space: nowrap;
}

.upgrade-part h1 {
	font-size: 20px;
	font-weight: 500;
	margin: 0;
}

.upgrade-part h2 {
	font-size: 18px;
	font-weight: 400;
}



.back-to-top {
	position: fixed;
	bottom: 50px;
	right: 45px;
	display: flex;
	align-items: center;
	border: none;
	background: none;
	transition: all 250ms ease;
	cursor: pointer;
	z-index: 1;
}

.back-to-top .icon {
	height: 50px;
	width: 50px;
	position: relative;
	left: 16px;
	border-radius: 75px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #26E66D;
	color: white;
	box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
}

.back-to-top span {
	background: white;
	color: rgba(0, 0, 0, 0.8) ;
	font-size: 20px;
	font-weight: 500;
	padding: 12px 12px 12px 28px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
	border-radius: 0 6px 6px 0;
}

.back-to-top svg {
	height: 40px;
	width: 40px;
}

.back-to-top:hover {
	/*box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);*/
	transform: scale(1.05);
}

.back-to-top:focus {
	outline: none;
}


.error-banner {
	display: block;
	padding: 20px;
	background: rgba(255, 0, 0, 0.25);
	border-radius: 10px;
	margin-bottom: 18px;
}

.error-banner.success {
	background: rgba(38, 230, 109, 0.25);
}

.error-banner span {
	display: block;
	font-weight: 500;
	font-size: 18px;
	margin-bottom: 4px;
}

.error-banner ul {
	margin-left: 20px;
}

.error-banner ul li {
	font-size: 16px;
	margin-bottom: 4px;
}

.error-banner ul li:last-of-type {
	margin-bottom: 0;
}

.error-area.details {
	margin-bottom: 30px;
}

.error-area.details .error-banner ul li {
	font-size: 18px;
}

.error-area.details .error-banner span {
	font-size: 18px;
	margin-bottom: 6px;
}


.module-form {

}

.module-form .form-progress {
	display: table;
	table-layout: fixed;
	width: 100%;
	list-style: none;
	margin: 18px auto 0;
}

.module-form .form-progress .stage-button {
	display: table-cell;
	vertical-align: middle;
	position: relative;
	width: 2%;
	background: rgb(243, 243, 243);
	font-size: 16px;
	padding: 0 15px 0 35px;
	height: 38px;
	cursor: pointer;
	text-align: center;

	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.module-form .form-progress .stage-button:nth-of-type(even) {
	background: rgb(243, 243, 243);
}

.module-form .form-progress .stage-button.disabled, .module-form .form-progress .stage-button.disabled:hover {
	background: #dadada;
	cursor: default;
}

.module-form .form-progress .stage-button.disabled:after, .module-form .form-progress .stage-button.disabled:hover:after{
	border-left-color: #dadada;
}

.module-form .form-progress .stage-button .text {
	font-size: 18px;
	vertical-align: middle;
	height: 25px;
}

.module-form .form-progress .stage-button.disabled.show-na .text {
	font-size: 16px;
}

.module-form .form-progress .stage-button .subtext {
	display: none;
	font-size: 12px;
}

.module-form .form-progress .stage-button.show-na .subtext {
	display: block;
}

.module-form .form-progress .stage-button:first-of-type {
	padding-left: 15px;
	border-radius: 8px 0 0 8px;
}

.module-form .form-progress .stage-button:last-of-type {
	border-radius: 0 8px 8px 0;
}

.module-form .form-progress .stage-button:last-of-type:after {
	display: none;
}


.module-form .form-progress .stage-button:hover {
	background: #eaeaea;
}

.module-form .form-progress .stage-button:hover:after {
	border-left-color: #eaeaea;
}


.module-form .form-progress .stage-button.active {
	background: #26E66D;
	color: white;
	font-weight: 500;
}

.module-form .form-progress .stage-button.active:after {
	border-left-color: #26E66D;
}

.module-form .form-progress .stage-button:after {
	content:'';
	z-index: 999;
	position: absolute;
	top: 0;
	left: 100%;
	width: 0;
	height: 0;
	border-top: solid 19px transparent;
	border-left: solid 14px rgb(243, 243, 243);
	border-right: solid 14px transparent;
	border-bottom: solid 19px transparent;
}


.module-form .form-pages .page {
	display: none;
	flex-direction: column;
	width: 100%;
	padding: 25px 0;
}

.module-form .form-pages .page.show {
	display: flex;
}

.module-form .form-pages .page.page-centered {
	align-items: center;
	justify-content: center;
}




.module-form .form-pages .page .sliderContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 16px;
}


.module-form .form-pages .page .sliderContainer .slider {
	-webkit-appearance: none;
	width: 250px;
	height: 15px;
	border-radius: 100px;
	background: rgb(235, 235, 235);
	outline: none;
	-webkit-transition: .2s;
	transition: opacity .2s;
	margin: 0 12px;
}

.module-form .form-pages .page .sliderContainer .slider::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 25px;
	height: 25px;
	border-radius: 50%;
	background: #26E66D;
	cursor: pointer;
}

.module-form .form-pages .page .sliderContainer .slider::-moz-range-thumb {
	width: 25px;
	height: 25px;
	border-radius: 50%;
	background: #26E66D;
	cursor: pointer;
}

.module-form .form-pages .page .sliderContainer .extremity {
	font-size: 20px;
}


.module-form .form-pages .page .sliderValue {
	font-size: 24px;
	font-weight: 500;
	margin-top: 18px;
}

.module-form .form-pages .page .sliderValue.smaller {
	font-size: 22px;
}

.module-form .form-pages .page #roofSlopeImage {
	height: 300px;
	width: 500px;
	background: url(../img/roof-slope.png);
	background-position-y: 9500px;
}

.module-form .form-pages .page #roofShadingImage {
	height: 200px;
	width: 300px;
	background: url(../img/roof-shading.png);
	background-position-y: 800px;
}

.module-form .form-pages .page .title {
	display: flex;
	align-items: center;
	padding: 0 0 8px 0;
	font-size: 26px;
}

.module-form .form-pages .page .description {
	max-width: 700px;
	text-align: center;
	font-size: 18px;
	margin-bottom: 25px;
	display: flex;
	flex-direction: column;

	.link, .excel-download {
		display: inline-flex;
		text-decoration: none;
		font-size: 14px;
		color: black;
		transition: opacity 250ms;
		border-radius: 5px;
		background: #ededed;


		&:hover {
			opacity: 0.75;
		}

		.prefix-icon {
			padding: 5px 10px;
			display: flex;
			align-items: center;
			border-radius: 5px 0 0 5px;
			margin-right: 5px;
			color: white;

			&.excel-green {
				background: #1D6F42;
			}

			&.calculator-grey {
				background: #4d4d4d;
			}

			&.sun-yellow {
				background: #deda1d;
			}
		}

		.text {
			padding: 10px 0 10px 5px;
			display: flex;
			align-items: center;
		}

		.suffix-icon {
			padding: 10px;
			display: flex;
			align-items: center;
			margin-left: auto;
			color: rgba(0, 0, 0, 0.5);;

			svg {
				height: 16px;
				width: 16px;
			}
		}

		svg {
			width: 18px;
			height: 18px;
		}
	}

}

.module-form .form-pages .page .input-info-title {
	font-size: 24px;
	margin-bottom: 12px;
}

.module-form .form-pages .page .input-info {
	list-style: none;
	width: 90%;
	max-width: 600px;
}

.module-form .form-pages .page .input-info .row {
	display: flex;
	justify-content: space-between;
	font-size: 22px;
	margin: 4px 0;
}

.module-form .form-pages .page .input-info .note {
	line-height: 1.25;
	margin-top: 8px;
}

.module-form .form-pages .page .input-info-title-v2 {
	display: block;
	text-align: center;
	width: 90%;
	max-width: 600px;
	font-size: 22px;
	margin: 14px 0 4px 0;
	padding-bottom: 6px;
	font-weight: 600;

	&.first {
		margin-top: 0;
	}
}

.module-form .form-pages .page .input-info-v2 {
	list-style: none;
	width: 90%;
	max-width: 600px;

	.row {
		display: flex;
		justify-content: space-between;
		font-size: 20px;
		margin: 8px 0;
		padding: 8px 10px;
		background: rgba(0, 0, 0, 0.035);
		border-radius: 6px;

		.key, .value {
			width: 50%;
		}

		.key {
			font-weight: 500;
		}

		.value {
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}
	}

	@media only screen and (max-width: 750px){
		.row {
			display: flex;
			flex-direction: column;

			.key, .value {
				display: block;
				width: 100%;
			}

			.value {
				margin-top: 4px;
			}
		}
	}
}

.module-form .form-pages .page .input-form {
	max-width: 600px;

	@media only screen and (max-width: 600px) {
		max-width: 100%;
	}
}

.module-form .form-pages .page .input-form .input-label {
	font-size: 20px;
	display: block;
	margin: 18px 0 12px 0;
	max-width: 400px;

	&.has-helper {
		margin-bottom: 0;
	}
}

.module-form .form-pages .page .input-form .input-label-help {
	color: rgba(0, 0, 0, 0.75);
	display: block;
	margin: 6px 0 12px 0;
}

.module-form .form-pages .page .icon-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 12px 0 0 0;

	.circle-check-icon {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 46px;
		height: 46px;
		color: white;
		background: #26e66d;
		border-radius: 1000px;

		svg {
			width: 32px;
			height: 32px;
		}
	}

	.title {
		margin: 16px 0 0 0;
	}

	.description {
		margin: 6px 0 0 0;
		color: rgba(0, 0, 0, .5);
	}
}


.module-form .form-pages .page .input-form input {
	width: 400px;
}

.module-form .form-pages .page .input-field.error:not(.input-badge) {
	input {
		background: rgba(204, 0, 0, 0.075) !important;
		color: #cc0000 !important;
		border-color: #cc0000 !important;
		box-shadow: 0 0 0 4px rgb(204 0 0 / 15%);
	}
}

.module-form .form-pages .page .input-field.input-badge.error{
	box-shadow: 0 0 0 4px rgb(204 0 0 / 15%);
	border-radius: 10px;

	input, .badge {
		background: rgba(204, 0, 0, 0.075) !important;
		color: #cc0000 !important;
		border-color: #cc0000 !important;
	}
}

.module-form .form-pages .page .input-form span.error {
	display: block;
	margin-top: 6px;
	color: red;
}


.module-form .form-pages .page .input-field input {
	width: 100%;
	height: 42px;
	padding: 10px 12px;
	border-radius: 10px;
	border: solid 1px #E4E4E4;
	background: #F8F8F8;
	color: #7A7A7A;
	font-size: 18px;
	-moz-appearance: textfield;

	&.next {
		color: #26E66D;
		border-color: #26E66D;
	}

	&.disabled {
		background: #eeeeee;
		color: #d0d0d0;
		cursor: default;
	}
}

.module-form .form-pages .page .input-field input::placeholder {
	color: #d0d0d0;
}

.module-form .form-pages .page .input-field input:focus {
	outline: none;
}

.module-form .form-pages .page .input-field.input-badge input {
	border-radius: 10px 0 0 10px;
}

.module-form .form-pages .page .input-field.input-badge .badge {
	background: #F8F8F8;
	color: #7A7A7A;
	border-radius: 0 10px 10px 0;
	font-size: 18px;
	height: 42px;
	padding: 10px 12px;
	border: solid 1px #E4E4E4;
	border-left: 0;
}

.module-form .form-pages .page .dropdown {
	position: relative;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	width: 400px;

	@media only screen and (max-width: 600px) {
		max-width: 100%;
	}

	&.next .button {
		border-color: #26E66D;
		color: #26E66D;
	}

	&.disabled .button {
		background: #eeeeee;
		color: #d0d0d0;
		cursor: default;
	}
}

.module-form .form-pages .page .dropdown.error .button {
	border-radius: 10px;
	background: rgba(204, 0, 0, 0.075) !important;
	border-color: #cc0000 !important;
	box-shadow: 0 0 0 4px rgb(204 0 0 / 15%);
}

.module-form .form-pages .page .dropdown .button {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px 12px;
	border-radius: 10px;
	border: solid 1px #E4E4E4;
	background: #F8F8F8;
	color: #7A7A7A;
	font-size: 18px;
	cursor: pointer;
}

.module-form .form-pages .page .dropdown .button span {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.module-form .form-pages .page .dropdown .dropdown-content {
	z-index: 1011;
	min-width: 100%;
	display: none;
	position: absolute;
	top: 56px;
	border-radius: 10px;
	border: solid 1px #E4E4E4;
	background: #F8F8F8;
	color: #7A7A7A;
}

.module-form .form-pages .page .dropdown .dropdown-content ul {
	min-width: 100%;
	max-height: 250px;
	overflow-x: auto;
	overflow-y: auto;
	list-style: none;
	padding: 8px;
}

.module-form .form-pages .page .dropdown .dropdown-content ul.scrollbar-less {
	max-height: 1000px;
}

.module-form .form-pages .page .dropdown .dropdown-content.open {
	display: block;
}

.module-form .form-pages .page .dropdown .dropdown-content ul li {
	display: block;
	font-size: 18px;
	padding: 10px 10px;
	cursor: pointer;
	white-space: nowrap;
}

.module-form .form-pages .page .dropdown .dropdown-content ul li:hover {
	background: #E4E4E4;
	border-radius: 5px;
}

.module-form .form-pages .page .slider {
	display: flex;

	span {
		font-size: 18px;
		margin-right: 10px;
		min-width: 45px;
	}

	input[type=range] {
		flex: 1;
		width: 100%;
		-webkit-appearance: none;
		appearance: none;
		height: 20px;
		outline: none;
		-webkit-transition: .2s;
		transition: opacity .2s;
		border-radius: 12px;
		border: solid 1px #E4E4E4;
		background: #F8F8F8;

		&:hover {
			opacity: 1;
		}

		&::-webkit-slider-thumb {
			-webkit-appearance: none;
			appearance: none;
			width: 25px;
			height: 25px;
			border-radius: 50%;
			background: #26E66D;
			cursor: pointer;
		}

		&::-moz-range-thumb {
			width: 25px;
			height: 25px;
			border-radius: 50%;
			background: #26E66D;
			cursor: pointer;
		}

	}
}

.module-form .form-pages .page .section-title {
	display: block;
	padding: 10px 0;
	font-size: 20px;
	font-weight: 500;
	margin-top: 20px;
	text-align: center;
	border-bottom: solid 1px #E4E4E4;
}



.module-form .form-actions {
	display: flex;
	width: 100%;
}

.module-form .form-actions .button {
	display: inline-flex;
	align-items: center;
	border-radius: 10px;
	border: none;
	font-size: 20px;
	cursor: pointer;
	transition: all 250ms;
}

.module-form .form-actions .button:hover {
	opacity: 0.6;
}

.module-form .form-actions .button:focus {
	outline: none;
}

.module-form .form-actions .button.previous {
	background: rgb(235, 235, 235);
	padding: 14px 28px 14px 12px;
	white-space: nowrap;
	align-self: center;
}

.module-form .form-actions .button.previous svg {
	margin-right: 6px;
}

.module-form .form-actions .button.next {
	margin-left: auto;
	display: flex;
	background: #26E66D;
	color: white;
	padding: 14px 12px 14px 28px;
}


.module-form .form-actions .button.next svg {
	margin-left: 6px;
}

















.listNoData {
	text-align: center;
	padding: 40px 0;
}

.listNoData h3 {
	font-size: 24px;
	font-weight: 500;
	margin: 0 0 8px 0;
}

.listNoData h4 {
	font-size: 18px;
	font-weight: 400;
	margin: 0 0 20px 0;

}

.listNoData button {
	padding: 14px 40px !important;
}

.survey-found {
	background: white;
	border-radius: 20px;
	padding: 20px 25px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, .05);
	margin: 0 0 45px 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.survey-found h2 {
	font-weight: 500;
	font-size: 20px;
}

.survey-found .button {
	background: #26E66D;
	font-size: 18px;
	font-weight: 500;
	color: white;
	padding: 12px 24px;
	border-radius: 10px;
	border: none;
	cursor: pointer;
	transition: all 250ms;
}

.survey-found .button:focus {
	outline: none;
}

.survey-found .button:hover {
	background: rgba(38, 230, 108, 0.75);
}

.survey-found .button.goto {
	margin-right: 10px;
}

.survey-found .button.clear {
	background: rgb(235, 235, 235);
	color: rgb(0, 0, 0);
}

.survey-found .button.clear:hover {
	opacity: 0.6;
}



.technology-title {
	display: flex;
	flex-direction: column;

	h1 {
		font-size: 28px;
		font-weight: 500;
	}

}

.editing-survey-banner {
	display: flex;
	align-items: center;
	padding: 10px 15px;
	align-self: start;
	background: white;
	border-radius: 10px;
	box-shadow: 0 3px 6px rgb(0 0 0 / 5%);

	.icon {
		margin-right: 10px;
	}

	.text {
		.highlight {
			font-weight: 500;
		}
	}

	.cancel {
		padding: 8px 12px;
		border-radius: 5px;
		margin-left: 20px;
		cursor: pointer;
		text-decoration: none;
		background: #e0e0e0;
		color: black;
		border: 1px solid #e0e0e0;
		transition: 250ms color,250ms background-color;

		&:hover {
			background-color: red;
			color: white;
		}

		&:focus {
			border-color: red;
		}
	}


	&.details-version, &.survey-selector-version {
		display: inline-flex;
	}

	&.details-version {
		margin-top: 15px;
	}

	&.survey-selector-version {
		margin-bottom: 45px;
	}
}


.breadcrumb-header {
	display: flex;
}

.breadcrumb-header .action-area {
	white-space: nowrap;
	display: flex;
	align-items: center
}

.breadcrumb-header .breadcrumb {
	width: 100%;
}

.breadcrumb-header .next-button {
	display: flex;
	align-items: center;
	background: #26E66D;
	color: white;
	padding: 12px 12px 12px 28px;
	border-radius: 10px;
	border: none;
	font-size: 20px;
	cursor: pointer;
	transition: all 250ms;
}

.breadcrumb-header .next-button svg {
	margin-left: 6px;
}

.breadcrumb-header .next-button:hover {
	background: rgba(38, 230, 108, 0.75)
}

.breadcrumb-header .next-button:focus {
	outline: none;
}

.title-row .action, .lightingNoData .action {
	color: #26E66D;
	padding: 12px 30px;
	border: solid 2px #26E66D;
	background: none;
}

.title-row .action:hover, .lightingNoData .action:hover {
	background: #26E66D;
	color: white;
}

.title-row .actions-list {
	display: inline-flex;
	align-items: center;
}

.title-row .action.importBoilers {
	display: inline-flex;
	align-items: center;
	padding: 12px 15px;
	background: #26E66D;
	color: white;
	margin-right: 8px;
}

.title-row .action.importBoilers.hidden {
	display: none;
}

.title-row .action.importBoilers:hover {
	color: #26E66D;
	background: none;
}

.title-row .action.importBoilers svg {
	height: 20px;
	width: 20px;
	margin-right: 6px;
}

.embed-form {
	display: none;
	position: relative;
	border: solid 1px #E4E4E4;
	border-radius: 10px;
	padding: 18px;
	margin-top: 24px;

	//.create-lighting-specific {
	.edit-lighting-specific {
		display: none;
	}

	&.modal-embed-form {
		padding: 0;
		border: none;
		display: block;

		.input-row {
			display: flex;

			.input label.has-tooltip {
				display: flex;
			}
		}
	}
}


.embed-form.open {
	display: block;
}

.overview-page .upgrades-breakdown {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 25px;
	margin-top: 4px;

	.numbers {
		display: flex;
		margin-left: 8px;
	}

	.number {
		margin-right: 32px;

		h3 {
			font-size: 14px;
			font-weight: 400;
			text-transform: uppercase;
			letter-spacing: 1px;
		}

		h2 {
			font-weight: 500;
		}


		&.left-divider-line {
			border-left: solid 1px #E4E4E4;
			padding-left: 32px;
		}

		&:last-of-type {
			margin-right: 0;
		}

	}
}

.overview-page {
	.upgrades-message {
		display: flex;
		align-items: center;
		padding: 16px 18px;
		border-radius: 10px;
		margin-bottom: 20px;

		.icon {
			display: flex;
			justify-content: center;
			margin-right: 12px;

			svg {
				height: 24px;
				width: 24px;
			}
		}

		p {
			line-height: 1.5;
		}

		&.warning {
			background: #fff3cd;
			border: solid 1px #ffe69c;
			color: #664d03;
		}
	}
}


.rates-action button {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	background: none;
	border-radius: 10px;
	padding: 10px 16px;
	font-weight: 500;
	color: #26E66D;
	border: solid 2px #26E66D;
	cursor: pointer;
	transition: all 250ms;
	margin-top: 12px;
}

.rates-action button:focus {
	outline: none;
}

.rates-action button:hover {
	background: #26E66D;
	color: white;
}

.details-header {
	margin-top: 20px;
	flex-direction: column;
	text-align: center;

	.title {
		display: block;
	}

	.subtitle {
		display: block;
		font-weight: 500;
	}
}


.breadcrumb {
	display: flex;
	margin: auto;
	justify-content: center;
	padding: 20px 0;
	-webkit-user-select: none;  /* Chrome all / Safari all */
	-moz-user-select: none;     /* Firefox all */
	-ms-user-select: none;      /* IE 10+ */
	user-select: none;          /* Likely future */
}

.breadcrumb .line {
	height: 10px;
	width: 100px;
	background: #e0e0e0;
	margin-top: 55px;
}

.breadcrumb .line.active {
	background: #26E66D;
}

.breadcrumb .node {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
}

.breadcrumb .node .text {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	font-weight: 500;
	height: 36px;
	width: 48px;
}

.breadcrumb .node .button {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 48px;
	width: 48px;
	border-radius: 64px;
	background: #e0e0e0;
	text-decoration: none;
	font-size: 20px;
	color: rgba(0, 0, 0, .5);
	z-index: 1;
}

.breadcrumb .node .button[href] {
	transition: box-shadow 250ms;

	&:hover {
		box-shadow: 0 0 0 3px #bababa;
	}

}

.breadcrumb .node.active .button {
	background: #26E66D;
	color: white;
	z-index: 1;
	transition: box-shadow 250ms;

	&:hover {
		box-shadow: 0 0 0 3px #23cc61;
	}
}

.timeseries-table {
	overflow-x: auto;
	padding-bottom: 12px;
}

.timeseries-table table {
	border-collapse: collapse;
	table-layout: fixed;
	width: 100%;
	margin-top: 12px;
	overflow-x: scroll;
	white-space: nowrap;
}

.timeseries-table table thead tr {
	background: #F5F5F5;
}

.timeseries-table table thead tr th {
	text-align: center;
	font-size: 16px;
	color: rgba(0, 0, 0, .5);
	text-transform: uppercase;
	padding: 8px 0;
	width: 100px;
}

.timeseries-table table thead tr th:first-of-type {
	padding-left: 15px;
	padding-right: 10px;
	border-radius: 8px 0 0 8px;
	text-align: left;
	width: 175px;
}

.timeseries-table table thead tr th:last-of-type {
	padding-left: 10px;
	padding-right: 15px;
	border-radius: 0 8px 8px 0;
}

.timeseries-table table tbody tr {
	border-bottom: solid 1px rgba(0, 0, 0, .25);
}

.timeseries-table table tbody tr:last-of-type {
	border-bottom: 0;
}

.timeseries-table table tbody tr th {
	text-align: left;
	width: 175px;
	padding: 4px 0;
	padding-left: 15px;
	color: rgba(0, 0, 0, 0.75);
}

.timeseries-table table tbody tr td {
	font-size: 14px;
	text-align: center;
}

.modal.installRates {

}

.modal.installRates .body {
	max-height: calc(100vh - 50px);
	overflow-y: auto;
}

.input #enterRoomReference {
	zoom: 1.5;
}

.input label[for=enterRoomReference] {
	position: relative;
	bottom: 2px;
	left: 6px;
}

.selector-menu-arrow {
	position: absolute;
	transform: translateY(-100%);
	left: 20px;
	top: 15px;
	width: 250px;
}

#content .moduleResults {
	margin: 16px 0 48px 0;
	position: relative;
}

#content.overview-page .info-footer {
	display: flex;
	justify-content: center;
	margin: 0 0 8px 0;

	.field-info {
		font-size: 14px;
		color: #868686;
		margin: 0 8px 0 0;

		&:last-of-type {
			margin-right: 0;
		}
	}
}

.moduleResults .moduleHeader {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.moduleResults .moduleHeader .icon {
	height: 110px;
	width: 110px;
	border-radius: 125px;
	background: white;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0 3px 6px rgba(0, 0, 0, .05);
}

.moduleResults .moduleHeader .icon img {
	height: 75px;
}

.moduleResults .moduleHeader .title {
	font-size: 30px;
	font-weight: 500;
	margin-top: 16px;
}

.moduleResults .improvements {
	margin: 40px 0 60px 0;
}

.content.header {
	color: black;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0;

	.info-panel {
		background: #fff;
		border-radius: 10px;
		box-shadow: 0 3px 6px rgba(0,0,0,.05);
		display: flex;
		flex-direction: column;
		margin: 30px 0 20px 0;
		padding: 24px;
		width: 100%;

		.details, .emissions {
			width: 100%;

			@include large-screens-and-up {
				width: 50%;
			}
		}

		.emissions {
			display: flex;
			flex-direction: column;
			border: 0;
			border-bottom: solid 1px rgba(0,0,0,0.1);
			padding-bottom: 18px;
			padding-right: 0;
			margin-bottom: 18px;

			.chart-title {
				display: block;
				text-align: center;
				font-size: 20px;
				font-weight: 500;
				margin-bottom: 12px;
			}

			.chart-container {
				flex: 1;
				width: 100%;
				height: 250px;
			}

			.text {
				display: block;
				color: rgba(0, 0, 0, 0.75);
				margin-top: 10px;
				text-align: left;
				font-size: 14px;
				line-height: 1.5;
			}

			.carbon-button {
				display: flex;
				align-items: center;
				border-radius: 10px;
				padding: 8px 10px;
				margin: 12px 0 0 0;
				font-weight: 500;
				cursor: pointer;
				transition: all 250ms;
				text-decoration: none;
				background: #26E66D;
				border: solid 2px #26E66D;
				color: white;

				.icon {
					margin-right: 10px;

					svg {
						width: 20px;
						height: 20px;
					}
				}

				.text {
					display: flex;
					flex-direction: column;
					margin: 0 auto;
					color: white;

					span {
						font-weight: 400;
					}
				}

				&:hover {
					border-color: #26E66D;
					background: white;
					color: #26E66D;

					.text {
						color: #26E66D;
					}
				}

				&:disabled {
					opacity: 0.5;
					cursor: default;

					&:hover {
						background: #26E66D;
						color: white;

						.text {
							color: white;
						}
					}
				}
			}

			@include large-screens-and-up {
				border: 0;
				border-right: solid 1px rgba(0,0,0,0.1);
				padding-right: 18px;
				margin-right: 18px;
				margin-bottom: 0;
			}
		}

		.button-bar {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;

			.row {
				display: flex;
				width: 100%;
				margin-bottom: 8px;

				button {
					display: flex;
					align-items: center;
					border: none;
					background: none;
					border-radius: 10px;
					padding: 8px 10px;
					font-weight: 500;
					cursor: pointer;
					transition: all 250ms;
					margin-bottom: 10px;

					svg {
						margin-right: 8px;
					}

					&.primary {
						background: #26E66D;
						color: white;
						padding: 10px 12px;

						&:hover {
							background: rgba(38, 230, 109, 0.75);
						}
					}

					&.secondary {
						color: #26E66D;
						border: solid 2px #26E66D;

						&:hover {
							border-color: rgba(38, 230, 109, 0.75);
							color: rgba(38, 230, 109, 0.75);
						}
					}

					&:last-of-type {
						margin-right: 0;
					}

					&:focus {
						outline: none;
					}
				}
			}


			.button {
				display: flex;
				align-items: center;
				border-radius: 10px;
				padding: 8px 10px;
				font-weight: 500;
				cursor: pointer;
				transition: all 250ms;
				text-decoration: none;

				margin-bottom: 8px;

				.icon, .icon svg {
					width: 35px;
					height: 35px;
				}

				.icon {
					margin-right: 10px;
				}

				.text {
					display: flex;
					flex-direction: column;
					margin: 0 auto;

					span {
						font-weight: 400;
					}
				}

				&:last-child {
					margin-bottom: 0;
				}
			}

			.report-button {
				white-space: nowrap;
			}

			.carbon-button {
				.icon {
					display: flex;
					align-items: center;

					svg {
						width: 20px;
						height: 20px;
					}
				}

				&:disabled {
					opacity: 0.5;
					cursor: default;
				}
			}

			.report-button {
				background: #26E66D;
				color: white;
				border: solid 2px #26E66D;

				&:hover:not(:disabled) {
					border-color: #26E66D;
					color: #26E66D;
					background: white;
				}

				&:disabled {
					opacity: 0.5;
					cursor: default;
				}

				&.button-pulse {
					box-shadow: rgba(38, 230, 109, 1);
					transform: scale(1);
					animation: pulse 2s infinite;

					@keyframes pulse {
						0% {
							transform: scale(0.95);
							box-shadow: 0 0 0 0 rgba(38, 230, 109, 0.7);
						}

						70% {
							transform: scale(1);
							box-shadow: 0 0 0 10px rgba(38, 230, 109, 0);
						}

						100% {
							transform: scale(0.95);
							box-shadow: 0 0 0 0 rgba(38, 230, 109, 0);
						}
					}
				}
			}

			.assessor-button {
				background: #ebcb00;
				color: white;
				border: solid 2px #ebcb00;

				.icon {
					display: flex;
					align-items: center;

					svg {
						width: 25px;
						height: 25px;
					}
				}

				&:hover {
					border-color: #ebcb00;
					color: #ebcb00;
					background: white;
				}
			}
		}

		.links {
			display: flex;
			justify-content: center;
			margin-top: 24px;

			.button {
				display: flex;
				flex-direction: column;
				align-items: center;
				border: none;
				cursor: pointer;
				background: none;
				margin-right: 22px;

				.icon {
					display: flex;
					align-items: center;
					justify-content: center;
					background: #26E66D;
					color: white;
					padding: 15px;
					margin-bottom: 5px;
					border-radius: 100px;
					transition: background-color 250ms;

					svg {
						width: 24px;
						height: 24px;
					}
				}

				&.share-results .icon {
					background: #f3f3f3;
					color: rgba(0,0,0,.5);
				}

				&:enabled:hover {
					.icon {
						background: rgba(38, 230, 109, 0.75);
					}

					&.share-results .icon {
						background: #f8f8f8;
					}
				}

				&:disabled {
					opacity: 0.5;
					cursor: default;
				}

				&:last-child {
					margin-right: 0;
				}
			}
		}

		@include large-screens-and-up {
			flex-direction: row;
		}
	}
}

.content.overview-page {
	.purchased-alert {

	}
}

.content.header .title {
	display: block;
	margin-top: 96px;
	font-size: 42px;
	font-weight: 600;
	margin-bottom: 12px;
}

.content.header .subtitle {
	display: block;
	font-size: 22px;
	font-weight: 400;
	margin-bottom: 18px;
}




.survey-details {
	background: white;
	border-radius: 10px;
	padding: 24px 24px;
	width: 100%;
	box-shadow: 0 3px 6px rgba(0, 0, 0, .05);
	display: inline-flex;
	flex-direction: column;
	margin: 0 0 50px 0;

	.account-login-section {
		display: block;
		margin-bottom: 12px;

		.text {
			font-size: 18px;
		}

		.login-button {
			display: inline-block;
			background: #26E66D;
			color: white;
			font-size: 18px;
			padding: 10px 16px;
			border-radius: 5px;
			margin-top: 8px;
			text-decoration: none;
			transition: background-color 250ms;

			&:hover {
				background-color: rgba(38,230,108,0.75);
			}
		}
	}

	.address {
		address {
			font-size: 20px;
		}
	}

	.details-title {
		display: block;
		font-size: 20px;
		font-weight: 500;
		margin-bottom: 6px;
	}
}



.results-selector .title-header {
	margin-bottom: 16px;
}

.results-selector .title-header h2 {
	font-size: 28px;
	font-weight: 500;
}

.results-selector .left-button, .results-selector .right-button {
	position: absolute;
	background: white;
	border-radius: 20px;
	padding: 8px 8px;
	height: 150px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, .05);
	border: solid 2px white;
	text-align: center;
	transition: all 250ms;
	cursor: pointer;
}

.results-selector .left-button {
	left: -60px;
}

.results-selector .right-button {
	right: -60px;
}

.results-selector .technologies{
	display: flex;
	max-width: 100%;
	height: 160px;
	align-items: flex-start;
	overflow-x: hidden;
}

.results-selector .simplebar-content {
	display: flex;
}

.results-selector .item {
	display: flex;
	flex-shrink: 0;
	align-items: flex-start;
	width: 150px;
	height: 150px;
	flex-flow: column;
	background: white;
	text-align: center;
	padding: 15px 15px;
	border-radius: 15px;
	border: solid 2px white;
	box-shadow: 0 3px 8px rgba(0, 0, 0, .06);
	cursor: pointer;
	margin-right: 18px;
	transition: all 250ms;
}

.results-selector .item:hover {
	box-shadow: 0 3px 8px rgba(0, 0, 0, .12);

}

.results-selector .item .image {
	width: 60%;
	margin: auto;
}

.results-selector .item .image img {
	width: 100%;
	display: block;
}

.results-selector .item .name {
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	flex: 1 1 auto;
	font-size: 10px;
	font-weight: 400;
	margin: 10px auto 0 auto;
}

.results-selector .item .name h2 {
	font-weight: 500;
}


.modal.importBoilers .boilerInfo {
	cursor: pointer;
}

.modal.importBoilers .boilerInfo:hover {
	background: rgba(0, 0, 0, 0.02);
}

.modal.importBoilers .boilerInfo .primary {
	display: block;
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 4px;
}

.modal.importBoilers .boilerInfo input[type=checkbox] {
	width: 18px;
	height: 18px;
	cursor: pointer;
}

.alert {
	position: relative;
	display: flex;
	align-items: center;
	background: black;
	border: solid 2px black;
	border-radius: 10px;
	padding: 12px 44px 12px 10px;
	margin: 0 18px;
}

.alert.hidden {
	display: none;
}

.alert h2 {
	color: black;
	font-size: 16px;
}

.alert .dismiss {
	position: absolute;
	transform: translateY(-50%);
	top: 50%;
	right: 10px;
	background: none;
	border: none;
	cursor: pointer;
}

.alert .dismiss:focus {
	outline: none;
}

.alert.alert-warning {
	background-color: #ffc68d;
	border-color: orange;
}

.actions .alert.alert-warning {
	z-index: 9999;
}

.alert.alert-warning .dismiss {
	border-color: orange;
}

.module-alert {
	position: absolute;
	left: -10px;
	top: 0;
	transform: translateX(-100%);
	width: 300px;
	display: none;
	align-items: center;
	background: #ffc68d;
	border: solid 2px orange;
	border-radius: 5px;
	padding: 10px;
	margin: 12px 0;
	font-size: 16px;
	line-height: 1.25;
}




@media only screen and (max-width: 1325px) {
	.selector-menu-arrow {
		width: 225px;
	}
}

@media only screen and (max-width: 1150px) {
	.selector-menu-arrow {
		left: 40px;
		top: 20px;
		width: 210px;
	}

	.bot-guide {
		flex-direction: column;
	}

	.bot-guide .text {
		text-align: center;
		margin-right: 0 !important;
		margin-bottom: 12px;
	}

}

.inputSuggestionField {
	position: absolute;
	top: 80px;
	left: 0;
	padding: 10px 12px;
	border-radius: 10px;
	border: solid 1px #E4E4E4;
	background: #F8F8F8;
	color: #7A7A7A;
	list-style: none;
	z-index: 1000;
}

.inputSuggestionField.error {
	background: #ffe5e5;
	color: red;
	line-height: 1.6;
}

.inputSuggestionField li {
	cursor: pointer;
	font-size: 18px;
	padding: 6px 0;
}

.inputSuggestionField li:hover {
	color: #5d5d5d;
}

.inputSuggestionField a {
	font-size: 18px;
}


.checkbox {
	display: block;
	position: relative;
	cursor: pointer;
	font-size: 22px;
	height: 20px;
	width: 20px;
	user-select: none;
}

.checkbox input {
	position: absolute;
	cursor: pointer;
	height: 0;
	width: 0;
}

.checkbox .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	border: solid 1px #E4E4E4;
	border-radius: 4px;
}

.checkbox:hover input ~ .checkmark {
	background-color: #F8F8F8;
}

.checkbox input:checked  ~ .checkmark {
	background-color: #26E66D;
	border-color: #26E66D;
	border-radius: 4px;
}

.checkbox input:disabled  ~ .checkmark {
	background: #E4E4E4;
}

.checkbox .checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

.checkbox input:checked ~ .checkmark:after {
	display: block;
}

.checkbox .checkmark:after {
	left: 6px;
	top: 2px;
	width: 4px;
	height: 8px;
	border: solid white;
	border-width: 0 2.5px 2.5px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

@media only screen and (max-width: 1100px) {

	.navbar #burger-nav {
		display: block;
	}

	.navbar .nav-items {
		display: none;
	}

	.navbar .container {
		justify-content: center;
	}
}

@media only screen and (max-width: 600px) {

	.alert h2 {
		font-size: 14px;
	}

	.breadcrumb-header .action-area .next-button {
		padding: 10px 12px 10px 20px;
		font-size: 18px;
	}

	.header .title, .contact-header h1 {
		font-size: 28px;
		margin-bottom: 3px;
	}
	.header .subtitle, .contact-header h2 {
		font-size: 18px;
	}

	.actions {
		flex-direction: column;
	}

	.actions .not-edited-help {
		line-height: 22px;
		margin: auto;
		margin-bottom: 12px;
	}
	.actions.next-only .next {
		margin-left: auto;
		font-size: 16px;
		padding: 14px 6px 14px 22px;
	}

	.actions#lightModalActions {
		flex-direction: row;
	}

	.actions#lightModalActions button {
		font-size: 18px;
		padding: 10px 24px;
	}

	.usage-table tbody tr td:first-of-type {
		font-size: 16px;
	}

	.about-banner {
		height: 125px;
	}
	.about-banner .title h1 {
		font-size: 28px;
	}
	.about-banner .title h2 {
		font-size: 18px;
	}
	#contact-form .block.double {
		display: block;
	}
	#contact-form .block.double .input {
		display: block;
		width: 100%;
	}
	#contact-form .block.double .input:last-of-type {
		margin-top: 16px;
	}
	#contact-form .block {
		margin-top: 16px;
	}
	#contact-form .block:first-of-type {
		margin-top: 0;
	}
	#lightsList thead tr {
		white-space: nowrap;
	}
	#lightsList thead tr th {
		padding-right: 20px;
	}

	#lightingPanel {
		overflow-x: scroll;
	}

	#lightingPanel .lightingNoData h3 {
		font-size: 20px;
		margin-bottom: 4px;
	}

	#lightingPanel .lightingNoData h4 {
		font-size: 16px;
	}

	#lightingPanel .lightingNoData button.action {
		padding: 8px 16px !important;
	}

	#lightingPanel .title-row .title {
		font-size: 22px;
	}

	#lightingPanel .title-row .subtitle {
		font-size: 14px;
	}

	#lightingPanel .title-row button.action {
		font-size: 16px;
		padding: 8px 16px;
		white-space: nowrap;
	}

	.panel#lightingPanel table#lightsList tbody tr td, table.upgrades-table tbody tr td {
		white-space: nowrap;
		padding-right: 10px;
	}

	.panel.upgrades {
		display: inline-block;
		overflow-x: scroll;
		max-width: 100%;
	}


	.upgrades-table thead tr {
		white-space: nowrap;
	}

	.upgrades-table thead tr th {
		padding-right: 20px;
	}

	.icon-header img {
		height: 65px;
	}

	.icon-header .titles h1 {
		font-size: 32px;
	}

	.icon-header .titles h2 {
		font-size: 24px;
	}

	.modal .body .input-row.two-inputs, .modal .body .input-row.two-one-inputs, .modal .body .input-row.three-inputs {
		display: block;
	}

	.modal .body .input-row.two-inputs .input {
		margin-top: 20px;
		width: 100%;
	}

	.modal .body .input-row.two-one-inputs .input, .modal .body .input-row.two-one-inputs .group {
		margin-top: 20px;
		width: 100%;
	}

	.modal .body .input-row.two-one-inputs .group {
		margin-top: 0;
	}

	.modal .body .input-row.two-one-inputs .group .input {
		margin-top: 0;
	}

	.modal .body .input-row.three-inputs .input {
		margin-top: 20px;
		width: 100%;
	}

	.actions#detailsActions {
		flex-direction: row;
	}

	.actions#detailsActions button {
		font-size: 16px;
		padding: 14px 22px 14px 6px;
	}

	.actions#detailsActions button.next {
		font-size: 16px;
		padding: 14px 6px 14px 22px;
	}

	.improvements {
		margin-bottom: 40px;

		.row {
			flex-direction: column;
			margin-top: 15px;
		}

		.group {
			width: 100%;
		}
	}


	.improvements .improvement {
		width: 100%;
		padding: 10px 15px;
	}

	.improvements .improvement .icon {
		height: 55px;
		margin-left: 10px;
	}

	.improvements .improvement:last-child {
		margin-top: 15px;
	}

	.improvements .improvement .info-second {
		display: none;
	}

	.improvements .improvement .footer .savingsBreakdown {
		flex-direction: column;
	}

	.improvements .improvement .footer .savingsBreakdown div {
		width: 100%;
	}

	.upgrades-breakdown .numbers .number h3 {
		white-space: nowrap;
	}

	.technology-title h1 {
		font-size: 20px;
	}

	.technology-nav .slide-container {
		display: flex;
		align-items: center;
		width: calc(100% - 90px);
		margin-right: 5px;
	}

	.technology-nav .slide-container .overlay {
		left: 36px;
		height: 100px;
		width: calc(100% - 124px);
	}

	.technology-nav .slide-container .nav-items {
		width: calc(100% - 84px);
	}

	.technology-nav .slide-container .nav-items .item {
		height: 100px;
		width: 100px;
		margin-right: 8px;
	}

	.technology-nav .slide-container .nav-items .item img {
		height: 50px;
	}

	.technology-nav .slide-container .nav-items .item h2 {
		font-size: 12px;
		width: 100px;
		position: absolute;
		left: 0;
	}

	.technology-nav .slide-container .slick-arrow {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 84px;
		width: 42px;
		padding: 2px;
		border-radius: 1000px;
		margin-right: 10px;
		margin-bottom: 12px;
	}

	.technology-nav .slide-container .slick-arrow svg {
		width: 18px;
		height: 18px;
	}

	.technology-nav .slide-container .slick-arrow.previous-slide {
		margin-right: 5px;
	}

	.technology-nav .slide-container .slick-arrow.next-slide {
		margin-left: 7px;
	}

	.technology-nav .reset-button {
		width: 40px;
		height: 100px;
		margin-bottom: 12px;
		margin-right: 5px;
	}

	.technology-nav .reset-button button {
		height: 35px;
		width: 35px;
		padding: 4px;
	}

	.technology-nav .menu {
		width: 40px;
		height: 100px;
		margin-bottom: 12px;
	}

	.technology-nav .menu .selector {
		height: 35px;
		width: 35px;
		padding: 4px;
	}

	.technology-nav .menu .menu-content.open {
		width: 295px;
		right: 0;
		top: 115px;
		padding: 0;
		border-radius: 10px;
	}

	.technology-nav .menu .menu-content .menu-item {
		width: 85px;
		height: 85px;
	}

	.technology-nav .menu .menu-content .menu-item img {
		width: 40px;
	}

	.technology-nav .menu .menu-content .menu-item h2 {
		position: relative;
		font-size: 10px;
		height: 24px;
		bottom: 6px;
	}

	.breadcrumb .node {
		width: 36px;
	}

	.breadcrumb .node .text {
		font-size: 14px;
	}

	.breadcrumb .node .button {
		width: 36px;
		height: 36px;
	}

	.breadcrumb .line {
		margin-top: 51px;
		height: 8px;
		width: 50px;
	}

	.panel.page-content .loading-page-title {
		font-size: 22px;
	}

	.content.header {
		margin-bottom: 25px;
	}

	.content.header .container {
		margin: 94px 0;
	}

	.content.header .title {
		font-size: 28px;
	}

	.content.header .subtitle {
		font-size: 20px;
	}

	.results-selector .title-header h2 {
		font-size: 20px;
	}

	.results-selector .item {
		width: 100px;
		height: 100px;
		padding: 4px;
		margin-right: 10px;
	}

	.results-selector .item .name {
		font-size: 8px;
	}

	.results-selector .left-button, .results-selector .right-button {
		height: 100px;
		padding: 4px;
	}

	.results-selector .left-button {
		left: 0;
	}

	.results-selector .right-button {
		right: 0;
	}

	.results-selector .technologies {
		margin: 0 46px;
		height: 125px;
	}

	.moduleResults .moduleHeader .icon {
		width: 85px;
		height: 85px;
	}

	.moduleResults .moduleHeader .icon img {
		width: 55px;
	}

	.moduleResults .moduleHeader .title {
		font-size: 24px;
	}

	.moduleResults .improvements {
		margin-top: 25px;
	}

	.survey-found {
		flex-direction: column;
	}

	.survey-found div {
		display: flex;
		margin-top: 15px;
	}

	.survey-found div button {
		white-space: nowrap;
	}

	h2.panel-header.overview {
		font-size: 28px;
	}

	.header .title {
		font-size: 26px;
	}

	.header .subtitle {
		font-size: 16px;
	}

	.about-block {
		margin-top: 48px;
	}

	.about-block:last-of-type {
		margin-bottom: 64px;
	}

	.about-block h2 {
		font-size: 24px;
	}

	.contact-form {
		margin-bottom: 64px;
	}

	#lightsList thead tr {
		white-space: nowrap;
	}
	#lightsList thead tr th {
		padding-right: 20px;
	}

	#lightingPanel {
		overflow-x: scroll;
	}

	.panel#lightingPanel table#lightsList tbody tr td, table.upgrades-table tbody tr td {
		white-space: nowrap;
		padding-right: 10px;
	}

	.embed-form {
		overflow-x: auto;
	}

	.embed-form .input-row.two-inputs, .embed-form .input-row.three-inputs {
		flex-direction: column;
		margin-top: 0;
		margin-bottom: 0;
	}

	.embed-form .input-row.two-inputs .input, .embed-form .input-row.three-inputs .input {
		width: 100%;
		margin-bottom: 20px;
	}

	.embed-form .usage-table table {
		white-space: nowrap;
	}

	.embed-form .usage-table table thead tr th {
		font-size: 14px;
		padding: 14px;
	}

	.embed-form .usage-table table tbody tr td {
		font-size: 14px;
	}

	.embed-form .actions button {
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 10px 12px;
		font-size: 16px;
	}

	.embed-form .actions button#saveLight {
		margin-top: 5px;
	}

	.embed-form .error-area .error-banner span {
		font-size: 16px;
	}

	.actions.bottom-bar.technology-selection {
		flex-direction: column;
		align-items: center;
		background: red;

	}
}



/*Animations*/
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }