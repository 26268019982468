.wtp-alert {
    display: flex;
    align-items: center;
    border: solid 1px;
    padding: 12px 18px;
    border-radius: 10px;


    .icon {
        margin-right: 8px;

        svg {
            height: 24px;
            width: 24px;
        }
    }

    .content {

    }

    .description {

    }

    &.warning {
        background: #fff2bf;
        border-color: #ffcc00;
        color: #403300;
    }
}