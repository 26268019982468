.account-page-header {
    padding-bottom: 32px;
}

.account-page {
    .account-row {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 18px;
        padding-bottom: 16px;

        .account-name {
            display: block;

            .subtitle {
                display: block;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.5);
                margin-bottom: 4px;
            }

            .title {
                display: block;
                font-size: 20px;
            }
        }

        .logout-button {
            display: flex;
            align-items: center;
            text-decoration: none;
            padding: 8px 12px;
            border-radius: 5px;
            margin-right: 12px;
            font-size: 16px;
            color: red;
            background: rgba(255, 0, 0, 0.05);
            outline-color: rgba(255, 0, 0, 0.5);
            transition: background-color 250ms, color 250ms;

            svg {
                width: 18px;
                height: 18px;
                margin-right: 6px;
            }

            &:hover {
                background: rgba(255, 0, 0, 0.65);
                color: white;
            }
        }
    }

    .nav-tabs-row {
        .nav-tabs {
            position: relative;
            display: flex;
            justify-content: center;
            list-style: none;
            padding: 0;
            margin: 0;
            width: 100%;
            border-radius: 5px;

            .nav-item {
                button {
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    padding: 14px 18px;
                    margin-right: 8px;
                    border-radius: 8px 8px 0 0;
                    font-size: 18px;
                    color: rgba(0, 0, 0, 1);
                    border: none;
                    background: none;
                    cursor: pointer;
                    outline-color: rgba(39, 230, 109, 0.5);
                    transition: background-color 250ms, color 250ms;

                    svg {
                        width: 22px;
                        height: 22px;
                        margin-right: 8px;
                    }

                    &:last-of-type {
                        margin-right: 0;
                    }
                }

                &.active button {
                    color: #26E66D;
                    background: white;
                    box-shadow: 0 3px 6px rgb(0 0 0 / 5%);
                }
            }
            &::after {
                content: "";
                position: absolute;
                bottom: -10px;
                left: 16px;
                background: white;
                width: calc(100% - 32px);
                height: 10px;
            }
        }
    }

    .pages-container {
        .page {
            background: white;
            padding: 38px 48px;
            border-radius: 8px;
            box-shadow: 0 3px 6px rgb(0 0 0 / 5%);
            display: none;

            .content-header {
                display: flex;
                text-align: center;
                justify-items: center;
                align-items: center;
                margin: 0 0 0 0;

                .content-title {
                    display: block;
                    font-size: 20px;
                    font-weight: 500;
                    white-space: nowrap;
                    margin: 0 12px 0 0;
                }

                .line {
                    background: rgba(0, 0, 0, 0.05);
                    height: 1px;
                    width: 100%;
                }
            }

            .content-content {
                display: block;
                margin-bottom: 32px;

                .account-wallet {
                    display: flex;
                    justify-content: space-between;
                    margin: 24px 0 0 0;

                    .credits-column {
                        width: calc(50% - 16px);
                        text-align: center;

                        .amount {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            font-size: 20px;
                            border-radius: 6px 6px 0 0;
                            background: #F7F7F7;
                            padding: 24px 0;

                            .credit-figure {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                margin-left: 2px;
                                font-size: 28px;
                                font-weight: 500;
                                color: black;
                                padding: 1px 5px;
                                border-radius: 4px;
                            }

                            .credit-label {

                            }
                        }

                        .bottom-block {
                            display: block;
                            text-decoration: none;
                            font-size: 18px;
                            color: rgba(0, 0, 0, 0.75);
                            padding: 12px 0;
                            text-align: center;
                            border-radius: 0 0 6px 6px;
                            background: rgba(0, 0, 0, 0.075);

                            &.cta {
                                background: #26E66D;
                                color: white;
                                transition: background-color 250ms, color 250ms;

                                &:hover {
                                    background-color: rgba(39, 230, 109, 0.75);
                                }

                                &:focus {
                                    outline: rgba(39, 230, 109, 0.5) solid 2px;
                                }
                            }
                        }

                        .notice {
                            display: block;
                            color: rgba(0, 0, 0, 0.5);
                            font-size: 14px;
                            margin-top: 6px;
                        }
                    }
                }

                .account-redeem-code {
                    margin-top: 12px;

                    .text {
                        font-size: 18px;
                    }

                    .redeem-code {
                        display: inline-flex;
                        text-decoration: none;
                        color: #26E66D;
                        background: white;
                        font-size: 18px;
                        padding: 12px 16px;
                        margin: 12px 0 0 0;
                        border-radius: 6px;
                        border: solid 1px #26E66D;
                        cursor: pointer;
                        transition: background-color 250ms,color 250ms;

                        &:hover {
                            background: #26E66D;
                            color: white;
                        }

                        &:focus {
                            outline-color: rgba(39, 230, 109, 1);
                        }
                    }
                }

                .password-change-form {
                    .block {
                        display: block;
                        margin: 26px 0 0 0;

                        .input {
                            label {
                                display: block;
                                font-size: 18px;
                                margin: 0 0 8px 0;
                            }

                            input {
                                width: 100%;
                                background: #F8F8F8;
                                border: solid 1px #E4E4E4;
                                padding: 10px 16px;
                                border-radius: 10px;
                                font-size: 18px;
                                color: #7A7A7A;
                                resize: vertical;

                                &:disabled {
                                    background: rgba(0, 0, 0, 0.075);
                                }

                                &:focus {
                                    outline-color: #26E66D;
                                }

                                &::placeholder {
                                    color: #d0d0d0;
                                }
                            }

                            label.error {
                                margin-top: 8px;
                                color: red;
                                font-size: 16px;
                            }
                        }
                    }

                    button[type=submit] {
                        display: inline-flex;
                        text-decoration: none;
                        color: #26E66D;
                        background: white;
                        font-size: 18px;
                        padding: 12px 16px;
                        margin: 20px 0 0 0;
                        border-radius: 6px;
                        border: solid 1px #26E66D;
                        cursor: pointer;
                        transition: background-color 250ms, color 250ms;

                        &:hover {
                            background: #26E66D;
                            color: white;
                        }

                        &:focus {
                            outline-color: rgba(39, 230, 109, 1);
                        }
                    }

                    .error-block {
                        padding: 20px;
                        margin: 16px 0;
                        background: rgba(255,0,0,0.25);
                        border-radius: 10px;

                        .title {
                            display: block;
                            font-weight: 600;
                            margin-bottom: 4px;
                        }

                        &.hide {
                            display: none;
                        }
                    }

                    .success-block {
                        display: flex;
                        align-content: center;
                        padding: 20px;
                        background: rgba(38, 230, 109, 0.1);
                        color: #26E66D;
                        border-radius: 10px;
                        margin: 16px 0;

                        .text {
                            align-self: center;
                        }

                        svg {
                            width: 24px;
                            height: 24px;
                            margin-right: 15px;
                        }

                        &.hide {
                            display: none;
                        }
                    }
                }

                &:last-of-type {
                    margin-bottom: 0;
                }
            }

            .surveys-table, .invoices-table {
                width: 100%;
                border-collapse: collapse;

                thead {
                    tr {
                        th {
                            text-align: left;
                            border-bottom: solid 1px rgba(0, 0, 0, 0.1);
                            font-size: 18px;
                            font-weight: 500;
                            padding-bottom: 12px;
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            font-size: 16px;
                            padding: 18px 0;
                            border-bottom: solid 1px rgba(0, 0, 0, 0.05);

                            .paid-badge {
                                background: #26E66D;
                                color: white;
                                padding: 2px 6px;
                                border-radius: 4px;
                            }
                        }
                        &:last-of-type {
                            td {
                                border-bottom: none;
                            }
                        }
                    }

                    .loading {
                        .spinner {
                            margin: 28px auto 16px auto;
                            font-size: 10px;
                            position: relative;
                            text-indent: -9999em;
                            border-top: 1.1em solid rgba(0,0,0,0.05);
                            border-right: 1.1em solid rgba(0,0,0,0.05);
                            border-bottom: 1.1em solid rgba(0,0,0,0.05);
                            border-left: 1.1em solid #26E66D;
                            transform: translateZ(0);
                            animation: load8 1.1s infinite linear;

                            border-radius: 50%;
                            width: 10em;
                            height: 10em;
                        }

                        &.hide {
                            display: none;
                        }
                    }
                }

                .view-survey {
                    display: inline-block;
                    background: #26E66D;
                    color: white;
                    text-decoration: none;
                    padding: 6px;
                    border-radius: 4px;
                    transition: background-color 250ms,color 250ms;

                    &:hover {
                        background-color: rgba(39, 230, 109, 0.75);
                    }

                    &:focus {
                        outline: rgba(39, 230, 109, 0.5) solid 1px;
                    }
                }

                &.hide {
                    display: none;
                }
            }

            .table-pagination {
                display: block;
                margin-top: 16px;

                .page-links {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .arrow-button, .number-button {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 18px;
                        font-weight: 400;
                        text-decoration: none;
                        margin-right: 6px;
                        width: 35px;
                        height: 35px;
                        background: #F8F8F8;
                        color: black;
                        border-radius: 4px;
                        border: none;
                        cursor: pointer;
                        transition: background-color 250ms;

                        &:hover {
                            background: rgba(0, 0, 0, 0.075);
                        }

                        &:last-of-type {
                            margin-right: 0;
                        }

                        &:disabled {
                            cursor: default;
                            background: #e0e0e0;
                        }

                        &:focus {
                            outline-color: rgba(0, 0, 0, 0.075);
                        }

                        &.current {
                            background: #26E66D;
                            color: white;
                        }

                        &.hide {
                            display: none;
                        }
                    }
                }

                .results-count {
                    display: block;
                    text-align: center;
                    margin: 16px 0 0 0;
                    color: rgba(0, 0, 0, 0.5);
                }

                &.hide {
                    display: none;
                }
            }

            .empty-state {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 28px 0;

                .title {
                    display: block;
                    font-size: 24px;
                    font-weight: 500;
                }

                .subtitle {
                    display: block;
                    font-size: 18px;
                    margin-top: 8px;
                }

                .cta {
                    text-decoration: none;
                    color: white;
                    background: #26E66D;
                    font-size: 18px;
                    padding: 12px 16px;
                    margin-top: 16px;
                    border-radius: 6px;
                    border: none;
                    transition: background-color 250ms,color 250ms;

                    &:hover {
                        background-color: rgba(39, 230, 109, 0.75);
                    }

                    &:focus {
                        outline: rgba(39, 230, 109, 0.5) solid 2px;
                    }
                }

                &.hide {
                    display: none;
                }
            }

            &.open {
                display: block;
            }
        }
    }
}