#content .module-select-header {
    text-align: left;
    margin: 0 0 12px 0;
    padding-top: 32px;

    .title {
        display: block;
        font-weight: 500;
        font-size: 24px;
        margin: 24px 0 6px 0;

    }

    .retrieve-survey {
        display: inline-block;
        font-size: 16px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        margin: 0 0 24px 0;

        a {
            color: rgba(0, 0, 0, 0.85);
            transition: color 250ms;

            &:hover {
                color: black;
            }
        }
    }

    .dual-block-columns {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 20px;
        width: 100%;

        @media only screen and (max-width: 950px){
            grid-template-columns: 1fr;
            grid-row-gap: 20px;
        }
    }

    .block-row, .block-column {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 10px;
        width: 100%;

        @media only screen and (max-width: 600px){
            grid-template-columns: 1fr;
        }

        .block {
            display: flex;
            align-items: center;
            text-align: left;
            padding: 18px 24px;
            margin: 0 0 15px 0;
            border-radius: 10px;
            transition: all 250ms;
            border: solid 2px white;
            background: white;
            box-shadow: 0 3px 8px rgb(0 0 0 / 6%);

            &.primary {
                box-shadow: 0 3px 8px rgba(0, 0, 0, 0.06);
                background: white;
            }

            .icon {
                display: block;
                height: 46px;
                margin-right: 18px;
            }

            .block-title {
                display: block;
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 8px;
                color: black;
                text-decoration: none;
            }

            .block-description {
                font-size: 14px;
                display: block;
            }

            .block-link {
                display: inline-block;
                margin-top: 8px;
                font-size: 16px;
                color: rgba(0, 0, 0, 0.5);
                transition: color 250ms;

                &:hover {
                    color: rgba(0, 0, 0, 1);
                }
            }
        }

        &.block-column {
            display: flex;
            flex-direction: column;
        }
    }

    .services-column {
        .border-outline {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
            grid-row-gap: 20px;
            border: solid 2px #26E66D;
            padding: 15px;
            border-radius: 10px;
        }

        .services-header {
            position: absolute;
            transform: translate(-50%, -50%);
            padding: 0 12px;
            left: 50%;
            display: block;
            background: #f3f3f3;
            text-align: center;
            font-size: 24px;
            font-weight: 500;
            color: #26E66D;
        }

        .service-block {
            display: flex;
            align-items: center;
            text-decoration: none;
            color: black;
            transition: opacity 250ms;

            .icon-container {
                border-radius: 10px;
                transition: all 250ms;
                border: solid 2px white;
                padding: 25px;
                background: white;
                box-shadow: 0 3px 8px rgb(0 0 0 / 6%);
            }

            .icon {
                display: block;
                height: 46px;
            }

            &.icon-left {
                justify-content: left;

                .icon-container {
                    margin-right: 16px;
                }
            }

            &.icon-right {
                justify-content: right;

                .icon-container {
                    margin-left: 16px;
                }
            }

            .content {
                text-align: center;

                .primary {
                    display: block;
                    font-size: 18px;
                    font-weight: 500;
                }

                .secondary {
                    display: block;
                    font-size: 14px;
                    margin-top: 4px;
                }
            }

            &:hover {
                opacity: 0.5;
            }
        }
    }
}

.survey-selector-top-half {
    background: #f3f3f3;
    box-shadow: inset 0px -16px 6px -10px #eeeeee;
    padding-bottom: 8px;
}

//Module Modal
.module-modal-overlay {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.5);
    z-index: 1010;
    visibility: hidden;
    opacity: 0;
    transition: opacity 250ms;

    &.active {
        display: block;
        visibility: visible;
        opacity: 1;
    }
}

.module-modal {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 450px;
    background: white;
    padding: 14px;
    border-radius: 8px;
    z-index: 1011;

    &.open {
        display: block;
    }

    p {
        display: block;
        font-size: 18px;
        line-height: 1.35;
    }

    .module-modal-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 7px;

        //Header title
        span {
            font-size: 20px;
            font-weight: 500;
            text-transform: capitalize;
        }

        //Close button
        .close {
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            border-radius: 8px;
            height: 32px;
            width: 32px;
            cursor: pointer;
            background: #ebebeb;
            transition: all 250ms;

            &:focus {
                outline: none;
            }

            &:hover {
                opacity: 0.6;
            }

            svg {
                height: 24px;
                width: 24px;
            }
        }
    }

    .module-modal-actions {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 14px;

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            border: none;
            border-radius: 8px;
            padding: 10px 12px;
            width: 100%;
            cursor: pointer;
            transition: all 250ms;

            &:focus {
                outline: none;
            }


            //Secondary button style
            &.secondary {
                background: #ebebeb;

                &:hover {
                    opacity: 0.6;
                }
            }

        }
    }
}

