.about-block {
    margin: 48px 0 0 0;

    h2 {
        font-size: 26px;
        font-weight: 500;
        margin: 0 0 16px 0;
    }

    p {
        display: block;
        font-size: 18px;
        line-height: 1.5;
    }

    ul {
        font-size: 18px;
        margin: 8px 0 0 32px;
        line-height: 1.5;
    }

    &:first-of-type {
        margin-top: 0;
    }

    .primary-button {
        display: inline-flex;
        padding: 12px 16px;
        background: #26E66D;
        text-decoration: none;
        color: white;
        font-size: 20px;
        margin: 12px 0;
        border-radius: 10px;
        transition: all 250ms;

        &:hover {
            background: rgba(38,230,109,0.75);
        }
    }

    &.terms-and-conditions-block {
        h2 {
            font-size: 24px;
            margin: 24px 0 16px 0;

            &:first-of-type {
                margin-top: 0;
            }
        }

        p, li {
            font-size: 16px;
            line-height: 1.25;
        }

        ul, ol {
            margin-left: 20px;

            li {
                margin: 6px 0;
            }
        }

        .cookie-table {
            border-collapse: collapse;
            border: solid 1px;
            margin: 10px 0;

            tbody {

                th, td {
                    padding: 10px;

                    &:first-of-type {
                        border-right: solid 1px black;
                    }
                }

                tr {
                    border: solid 1px;
                }

                th {
                    text-align: center;


                }
            }
        }

    }
}
