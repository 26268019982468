//Extra Small below tablets and large smartphones
$screen-xs-max: 576px;

//Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

//Small tablets (portrait view)
$screen-md-min: 768px;

//Tablets and small desktops
$screen-lg-min: 992px;

//Large tablets and desktops
$screen-xl-min: 1200px;

//Small devices and below
@mixin small-screens-and-below {
    @media (max-width: #{$screen-xs-max}) {
        @content;
    }
}

//Small devices
@mixin small-screens-and-up {
    @media (min-width: #{$screen-sm-min}) {
        @content;
    }
}

//Medium devices
@mixin medium-screens-and-up {
    @media (min-width: #{$screen-md-min}) {
        @content;
    }
}

//Large devices
@mixin large-screens-and-up {
    @media (min-width: #{$screen-lg-min}) {
        @content;
    }
}

//Extra large devices
@mixin extra-large-screens-and-up {
    @media (min-width: #{$screen-xl-min}) {
        @content;
    }
}