.report-payment-panel {
	width: 500px;
	background: white;
	box-shadow: 0 3px 6px rgb(0, 0, 0, 0.05);
	padding: 20px;
	margin: 0 auto;
	border-radius: 8px;

	.title {
		display: block;
		font-size: 24px;
		font-weight: 500;
		margin: 0 0 20px 0;
	}

	.report-details {
		text-align: left;
		background: rgba(0, 0, 0, 0.05);
		padding: 15px;
		border-radius: 5px;
		margin: 10px 0 20px 0;

		.left-text {
			display: inline-flex;
			flex-direction: column;

			.name {
				font-size: 18px;
				font-weight: 500;
			}

			.label {
				font-size: 14px;
				margin-bottom: 2px;
			}
		}

		.row {
			display: flex;
			justify-content: space-between;
		}

		.info-line {
			display: block;
			font-size: 12px;
			margin-top: 4px;
			color: rgba(0, 0, 0, 0.5);
		}

		.timestamp {
			display: inline-flex;

			font-size: 14px;
		}


	}

	.payment-line, .payment-total {
		display: flex;
		justify-content: space-between;
	}

	.payment-total {
		font-weight: 500;
		font-size: 18px;
		margin-top: 5px;
	}

	.checkout-button {
		display: block;
		text-decoration: none;
		background: #26E667;
		color: white;
		text-align: center;
		border-radius: 5px;
		border: none;
		font-size: 18px;
		margin: 15px auto 0 auto;
		cursor: pointer;
		transition: all 250ms;
		padding: 12px 12px;

		&:hover {
			background: rgba(38,230,108,0.75);
		}
	}



}
