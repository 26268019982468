.overview-page {
    &.header {
        > .container {
            text-align: center;
        }

        .purchased-alert {
            display: inline-flex;
            align-items: center;
            justify-content: space-between;
            background: white;
            border-radius: 10px;
            padding: 24px;
            margin: 40px 0 0 0;
            box-shadow: 0 3px 6px rgb(0 0 0 / 5%);
            max-width: 700px;

            .alert-content {
                text-align: left;

                .alert-title {
                    font-size: 18px;
                    font-weight: 500;
                    margin: 0 0 4px 0;
                }

                .alert-description {
                    line-height: 1.25;
                }
            }

            .dismiss {
                display: flex;
                align-content: center;
                justify-content: center;
                border: none;
                cursor: pointer;
                padding: 4px;
                background: #F5F5F5;
                margin: 0 0 0 8px;
                border-radius: 4px;
                transition: background-color 250ms;

                svg {
                    color: rgba(0, 0, 0, 0.5);
                    width: 24px;
                    height: 24px;
                }

                &:hover {
                    background-color: rgba(0, 0, 0, 0.075);
                }

                &:focus {
                    outline-color: #26E66D;
                }
            }
        }
    }

    .overview-header-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 400px;
        /*linear-gradient(0deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.7) 100%),*/
        background: linear-gradient(0deg, rgba(248,248,248, 1) 0%, rgba(248,248,248, 0.95) 50px, rgba(248,248,248,0.8) 100%), url("../img/city.jpg");
        background-size: cover;
        background-position: center;
        z-index: -1;
    }

    & ~ .carbon-offset-permission-modal.modal {
        .overlay {
            z-index: 3002;
        }

        .body {
            z-index: 3003;
            top: 50%;
            transform: translate(-50%, -50%);

            .modal-content {
                .details {
                    padding: 10px 0;

                    .divider {
                        width: 100%;
                        height: 1px;
                        margin: 15px 0;
                        background: rgba(0, 0, 0, 0.1);
                    }

                    .module-list-title {
                        font-size: 18px;
                        margin-bottom: 10px;
                        display: block;
                    }

                    .module-list-selection {
                        list-style: none;
                        margin-bottom: 10px;

                        li {
                            display: flex;
                            margin: 5px 0;

                            label {
                                cursor: pointer;
                                font-size: 18px;
                            }

                            .checkbox {
                                margin-right: 10px;
                            }
                        }
                    }

                    .notice {
                        font-size: 14px;
                    }

                    .data-list-label {
                        display: block;
                        font-size: 14px;
                        margin: 16px 0 4px 0;
                    }

                    .data-list {
                        margin-left: 20px;
                        font-size: 14px;
                        font-weight: 500;
                    }
                }
            }
        }

    }
}

