.technology-nav {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 26px 0 14px 0;


    //Movement buttons
    .previous-slide, .next-slide {
        position: relative;
        z-index: 1000;
        background: white;
        border-radius: 20px;
        padding: 8px 8px;
        height: 130px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, .05);
        border: solid 2px white;
        text-align: center;
        transition: all 250ms;
        cursor: pointer;

        &:focus {
            outline: none;
        }

        &:disabled {
            cursor: default;
        }
    }

    .previous-slide {
        margin-right: 8px;

        &:after {
            background: linear-gradient(90deg,#f8f8f8,hsla(0,0%,97%,0) 10px);
            content: "";
            display: block;
            height: 138px;
            left: 50px;
            position: absolute;
            top: 0;
            width: 40px;
            z-index: 100;
        }
    }

    .next-slide {
        margin-left: 8px;

        &:after {
            background: linear-gradient(270deg,#f8f8f8,hsla(0,0%,97%,0) 10px);
            content: "";
            display: block;
            height: 138px;
            right: 50px;
            position: absolute;
            top: 0;
            width: 40px;
            z-index: 100;
        }
    }

    //Technology icon slider
    .slide-container {
        display: flex;
        width: 100%;
        overflow-x: hidden;
        position: relative;
        padding: 0 10px;

        //Re-style Swiper elements
        .swiper-wrapper {
            display: flex;

            .swiper-slide {
                width: auto;
            }
        }

        //Side fading
        .overlay {
            position: absolute;
            z-index: 100;
            top: 0;
            left: 0;
            width: 100%;
            height: 130px;
            background: linear-gradient(90deg, rgba(248, 248, 248, 1) 0px, rgba(248,248,248,0) 10px), linear-gradient(270deg, rgba(248,248,248, 1) 0px, rgba(248,248,248,0) 10px);
            pointer-events: none
        }
    }

    //Technology icon
    .item {
        position: relative;
        background: white;
        border-radius: 20px;
        padding: 8px 8px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, .05);
        border: solid 2px white !important;
        text-align: center;
        height: 130px;
        width: 130px;
        flex: 0 0 130px;
        transition: all 250ms;
        cursor: pointer;
        white-space: normal;
        display: block;

        &:focus {
            outline: none;
        }

        //Active state
        &.active {
            border: solid 2px #26E66D !important;
            box-shadow: 0 3px 8px rgba(38, 230, 109, 0.50) !important;
        }

        //Error state
        &.error {
            border-color: red !important;
            box-shadow: 0 3px 8px rgba(255, 0, 0, 0.5) !important;
            animation-name: techNavItemErrorAnimation;
            animation-duration: 1s;
            animation-iteration-count: infinite;
            animation-timing-function: ease-in-out;
            animation-direction: alternate;

            @keyframes techNavItemErrorAnimation {
                0%   {
                    background-color: rgba(255, 0, 0, 0.05);
                }
                100% {
                    background-color: white;
                }
            }
        }

        //Icon image
        img {
            height: 65px;
            margin: auto;
        }

        //Title
        h2 {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 32px;
            font-weight: 400;
            font-size: 14px;
            margin-top: 6px;
        }

        //Remove technology button
        .removeTechnology {
            position: absolute;
            top: 4px;
            right: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            padding: 2px;
            color: rgb(0, 0, 0, 0.25);
            border: none;
            background: none;
            cursor: pointer;
            border-radius: 100px;
            transition: 250ms color, 250ms background-color;

            &:hover {
                background-color: red;
                color: white;
            }
        }
    }

    //Technology selector menu
    .menu {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 130px;
        width: 75px;
        color: white;
        margin-top: 8px;

        //Button
        .selector {
            height: 50px;
            width: 50px;
            border-radius: 70px;
            background: #26E66D;
            padding: 10px;
            cursor: pointer;
            transition: all 250ms;

            &:hover {
                transform: scale(1.05);
            }
        }

        //Menu content
        .menu-content {
            display: none;
            position: absolute;
            right: 0;
            top: 140px;
            justify-content: center;
            flex-wrap: wrap;
            padding: 6px;
            border-radius: 20px;
            border: solid 1px #E4E4E4;
            background: #F8F8F8;
            box-shadow: 0 3px 6px rgba(0, 0, 0, .05);
            width: 468px;

            //Open state
            &.open {
                z-index: 1015;
                display: flex;
            }

            //Technology item
            .menu-item {
                width: 100px;
                height: 100px;
                text-align: center;
                border: solid 2px #efefef;
                border-radius: 10px;
                padding: 6px;
                margin: 6px;
                cursor: pointer;
                transition: all 250ms;
                user-select: none;

                &:hover {
                    border-color: #d5d5d5;
                }

                //Active state
                &.active {
                    border-color: #26E66D;
                }

                //Icon image
                img {
                    height: 50px;
                }

                //Title
                h2 {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 32px;
                    color: black;
                    font-weight: 400;
                    font-size: 12px;
                }
            }
        }
    }

    //Reset button container
    .reset-button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 130px;
        width: 75px;
        margin-top: 8px;

        //Button
        button {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #e0e0e0;
            border: none;
            height: 50px;
            width: 50px;
            border-radius: 25px;
            cursor: pointer;
            transition: all 250ms;

            &:hover {
                transform: scale(1.05);
            }

            &:focus {
                outline: none;
            }

            svg {
                display: block;
            }
        }
    }
}
