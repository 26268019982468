.error-mask.active {
    &.error-mask-page {
        position: fixed;
        z-index: 1001;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: rgba(0, 0, 0, 0.5);
        pointer-events: none;
    }
}

.actions-bar-container {
    display: block;

    .actions-bottom-bar {
        display: block;
        background: white;
        margin: 24px 0;
        padding: 14px;
        border-radius: 10px;
        box-shadow: 0 3px 8px rgba(0, 0, 0, .06);
        z-index: 1010;
        position: relative;
        justify-content: normal;

        .terms-and-conditions-box {
            display: flex;
            justify-content: center;
            width: 100%;
            text-align: center;
            align-items: flex-end;
            padding: 4px 0 12px 0;
            margin: 0 0 12px 0;
            border-bottom: solid 1px #e8e8e8;

            label {
                user-select: none;
                cursor: pointer;
                font-weight: 500;
            }

            .checkbox {
                margin-right: 10px;

                .checkmark {
                    border-color: #767676;
                }

                input:checked ~ .checkmark {
                    border-color: #26E66D;
                }
            }
        }

        .message-bar {
            display: flex;
            justify-content: center;
            width: 100%;
            text-align: center;
            align-items: flex-end;
            padding: 4px 0 12px 0;
            margin: 0 0 12px 0;
            border-bottom: solid 1px #e8e8e8;

            p {
                color: rgba(0, 0, 0, 1);
            }

            .error-alert {
                display: flex;
                align-items: center;
                color: red;

                svg {
                    display: block;
                    margin-right: 6px;
                }

                p {
                    display: block;
                    font-weight: 500;
                    color: red;
                    line-height: 1;
                }
            }


            &.hide {
                display: none;
            }
        }

        .buttons-row {
            display: flex;

            button {
                display: flex;
                align-items: center;
                border-radius: 10px;
                border: none;
                font-size: 20px;
                cursor: pointer;
                transition: all 250ms;
            }

            .next {
                display: flex;
                background: #26E66D;
                color: white;
                padding: 12px 8px 12px 24px;
                white-space: nowrap;
                align-self: center;
                margin-left: auto;

                &:hover {
                    background: rgba(38, 230, 108, 0.75);
                }

                &:disabled {
                    background: rgba(38, 230, 108, 0.25);
                    cursor: not-allowed;

                    &:hover {
                        background: rgba(38, 230, 108, 0.25);
                    }
                }
            }

            .previous {
                background: rgb(235, 235, 235);
                padding: 14px 28px 14px 12px;
                white-space: nowrap;
                align-self: center;

                svg {
                    margin-right: 6px;
                }

                &:hover {
                    opacity: 0.6;
                }
            }
        }
    }

    &.fixed {
        position: fixed;
        transform: translateX(-50%);
        bottom: 0;
        left: 50%;
        z-index: 1012;

        @media (max-width: 480px) {
            position: relative;
            width: 100%;
        }

        .actions-bottom-bar {
            position: relative;
            margin-left: 12px;
            margin-right: 12px;
            border: solid 1px rgb(228, 228, 228);
            box-shadow: 0 3px 14px rgba(0, 0, 0, .09);

            @media (max-width: 480px) {
                margin-left: 0;
                margin-right: 0;
                border: none;
            }
        }
    }

    @media only screen and (max-width: 600px) {

        .actions-bottom-bar {
            flex-direction: row;

            button {
                font-size: 16px;
            }
        }
    }

    @media only screen and (max-width: 1100px) {
        .actions-bottom-bar .technology-selection .next {
            margin-left: 0;
            margin-top: 8px;
        }

        .actions-bottom-bar .technology-selection .next-button-text {
            font-size: 16px;
            text-align: center;
            width: 100%;
            display: block;
            margin-right: 0;
        }
    }
}

.technology-nav .item.error {
    position: relative !important;
    z-index: 2005 !important;
}

.module-form .form-progress-v2 {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 15px;

    .navigation-button {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        padding: 0 6px;

        &:hover {
            background: #eaeaea;
        }

        &:focus {
            box-shadow: 0 0 0 2px #bababa;
            outline: none;
        }
    }

    .stages {
        display: flex;
        width: calc(100% - 16px);
        margin: 0 8px;
        overflow-x: hidden;

        .stage-button {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;
            background: rgb(243, 243, 243);
            font-size: 16px;
            height: 38px;
            min-width: 25%;
            cursor: pointer;
            text-align: center;
            user-select: none;
            white-space: nowrap;

            .text {
                display: block;
                font-size: 18px;

                @media (max-width: 480px) {
                    font-size: 16px;
                }
            }

            .subtext {
                display: none;
                font-size: 12px;
            }

            &:nth-of-type(even) {
                background: rgb(243, 243, 243);
            }

            &:hover {
                background: #eaeaea;

                &:after {
                    border-left-color: #eaeaea;
                }
            }

            &.active {
                background: #26E66D;
                color: white;
                font-weight: 500;

                &:after {
                    border-left-color: #26E66D;
                }
            }

            &.show-na {
                .subtext {
                    display: block;
                }
            }

            &.disabled, &.disabled:hover {
                background: #dadada;
                cursor: default;

                &.show-na .text {
                    font-size: 16px;
                }

                &:after, &:hover:after {
                    border-left-color: #dadada;
                }
            }

            &:first-of-type {
                padding-left: 25px;
                border-radius: 8px 0 0 8px;
            }

            &:last-of-type {
                border-radius: 0 8px 8px 0;

                &:after {
                    display: none;
                }
            }

            &:after {
                content:'';
                z-index: 999;
                position: absolute;
                top: 0;
                left: 100%;
                width: 0;
                height: 0;
                border-top: solid 19px transparent;
                border-left: solid 14px rgb(243, 243, 243);
                border-right: solid 14px transparent;
                border-bottom: solid 19px transparent;
            }

            @media (max-width: 480px) {
                min-width: 33%;
            }
        }
    }
}

.module-form .form-pages {
    .page {
        .title {
            padding-bottom: 0;
            margin-bottom: 25px;

            &.center {
                text-align: center;
                margin: 0 auto 25px auto;
            }

            &.no-bottom-margin {
                margin-bottom: 0;
            }
        }

        .input-container,  .unit-input-container {
            margin: 0 auto;
            width: 500px;
        }

        .input-container {
            .input-form {
                display: flex;
                flex-direction: column;
                gap: 20px;

                .field {
                    display: flex;
                    flex-direction: column;
                    min-width: 100%;

                    .checkbox {
                        margin-right: 12px;
                    }

                    .input-alert {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        margin: 0 0 10px 0;
                        padding: 15px 15px;
                        border-radius: 10px;
                        line-height: 1.5;

                        .icon {
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                            height: 28px;
                            width: 28px;
                            padding: 4px;
                            border-radius: 1000px;
                        }

                        &.success-alert {
                            background: rgba(38,230,109,.125);
                            color: black;

                            .icon {
                                background: #26E66D;

                                svg {
                                    color: white;
                                }
                            }
                        }
                    }

                    .input-label {
                        display: block;
                        margin: 0;
                        cursor: pointer;
                        font-size: 18px;
                        white-space: normal;
                        width: 100%;
                        max-width: 500px;

                        .input-label-sub-line {
                            display: block;
                            font-size: 16px;
                            color: rgba(0, 0, 0, 0.5);
                            margin-top: 2px;
                            cursor: default;
                        }

                        a {
                            color: #26E66D;
                            text-decoration: none;

                            &:hover {
                                text-decoration: underline;
                            }
                        }

                        &.bold-text {
                            font-weight: 500;
                        }
                    }

                    .input-field {
                        margin-top: 12px;
                        display: block !important;

                        .dropdown {
                            width: 100%;
                        }

                        .radio-input-row {
                            font-size: 18px;
                            position: relative;
                            margin-bottom: 6px;

                            input[type=radio] {
                                display: none;
                                width: auto;
                                height: auto;
                                //accent-color: #26e66d;

                                & + label {
                                    cursor: pointer;
                                    padding: 0 0 0 8px;

                                    &:before {
                                        content: " ";
                                        display: inline-block;
                                        position: relative;
                                        top: 3px;
                                        right: 8px;
                                        margin: 0 5px 0 0;
                                        width: 16px;
                                        height: 16px;
                                        border-radius: 11px;
                                        border: 1px solid #e4e4e4;
                                        background-color: #f8f8f8;
                                    }
                                }

                                &:checked + label {
                                    &:before {
                                        border-color: #26e66d;
                                    }

                                    &:after {
                                        border-radius: 11px;
                                        width: 10px;
                                        height: 10px;
                                        position: absolute;
                                        top: 7px;
                                        left: 4px;
                                        content: " ";
                                        display: block;
                                        background: #26e66d;
                                    }
                                }
                            }

                            &:last-of-type {
                                margin-bottom: 0;
                            }
                        }

                        &.input-badge {
                            display: flex !important;

                            .badge {
                                white-space: nowrap;
                            }
                        }

                        &.error {
                            display: flex;

                            .radio-input-row {
                                label::before {
                                    box-shadow: 0 0 0 4px rgba(204, 0, 0, .15);
                                    background: rgba(204,0,0,.075)!important;
                                    border-color: #c00!important;
                                }
                            }
                        }
                    }

                    .input-subtitle {
                        display: block;
                        font-size: 14px;
                        color: rgba(0, 0, 0, 0.5);
                        margin-top: 6px;

                        &.only-margin-bottom {
                            margin: 0 0 8px 0;
                        }
                    }

                    .slider-input {
                        display: flex;
                        align-items: center;
                        margin-top: 12px;

                        .sliderValue {
                            margin-top: 0;
                            font-size: 18px;
                            font-weight: 400;
                            width: 50px;
                            text-align: right;
                        }

                        .sliderContainer {
                            margin: 0 0;
                            justify-content: normal;
                            width: 100%;

                            input[type=range] {
                                width: inherit;
                            }

                            &.no-extremity-labels {
                                input[type=range] {
                                    margin: 0 0;
                                }
                            }

                            &.inline-value-label {
                                margin-left: 12px;
                            }
                        }

                        .sliderMetaLabel {
                            margin-top: 0;
                            font-size: 18px;
                            font-weight: 400;
                            text-align: right;
                            white-space: nowrap;
                            margin-left: 12px;
                        }
                    }

                    .usage-input-table {
                        margin-top: 12px;
                        padding: 15px 20px;
                        border: solid 1px rgba(0, 0, 0, 0.125);
                        border-radius: 10px;

                        table {
                            margin: 0;

                            tr {
                                th {
                                    padding: 0 0 8px 0;
                                }

                                td {
                                    text-align: center;
                                    padding: 8px 0;

                                    &:first-of-type {
                                        text-align: left;
                                    }
                                }

                                &:last-of-type {
                                    td {
                                        padding-bottom: 0;
                                    }
                                }
                            }
                        }

                        .time-inputs {
                            .day-label {

                            }

                            .input-field {
                                margin: 0;

                                input {
                                    font-size: 16px;
                                    padding: 8px 0;
                                    width: 60px;
                                    height: auto;
                                    text-align: center;
                                }
                            }

                            .hours {
                                font-size: 16px;
                                color: rgba(0, 0, 0, 0.5);
                            }
                        }
                    }

                    .input-top-row {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        gap: 10px;

                        .download-link {
                            //text-decoration: none;
                            //background: transparent;
                            //border: 1px solid #26e66d;
                            //color: #26e66d;
                            //font-size: 14px;
                            //padding: 10px 18px 10px 12px;
                            //border-radius: 10px;
                            display: flex;
                            align-items: center;
                            white-space: nowrap;
                            //transition: opacity .25s;
                            //
                            svg {
                                height: 18px;
                                width: 18px;
                                margin-right: 4px;
                            }
                            //
                            //&:hover {
                            //    opacity: .6;
                            //}
                        }
                    }

                    &.error {
                        .checkbox .checkmark {
                            box-shadow: 0 0 0 4px rgba(204, 0, 0, .15);
                            background: rgba(204,0,0,.075)!important;
                            border-color: #c00!important;
                        }
                    }

                    &.checkbox-field {
                        flex-direction: row;
                        align-items: center;
                    }
                }

                .spreadsheet-input-field {
                    display: none;

                    .filepond--panel-root {
                        background: #f8fefa;
                        border: solid 2px #26e66d;
                    }

                    .filepond--drop-label {
                        color: #26E66D;
                    }

                    .filepond--label-action {
                        text-decoration-color: #26E66D;
                        transition: color 250ms, text-decoration-color 250ms;

                        &:hover {
                            color: rgba(39, 230, 109, 0.5);
                            text-decoration-color: rgba(39, 230, 109, 0.5);
                        }
                    }

                    .filepond--root {
                        margin: 15px 0 5px 0;

                        &.error {
                            .filepond--panel-root {
                                background: #f7dada !important;
                                color: #cc0000 !important;
                                border-color: #cc0000 !important;
                            }

                            .filepond--drop-label {
                                color: #cc0000 !important;
                            }

                            .filepond--label-action {
                                text-decoration-color: #cc0000;
                                transition: color 250ms, text-decoration-color 250ms;

                                &:hover {
                                    color: rgba(204, 0, 0, 0.5);
                                    text-decoration-color: rgba(204, 0, 0, 0.5);
                                }
                            }
                        }
                    }

                    &.show {
                        display: block;
                    }
                }

                .spreadsheet-input-process-values {
                    display: none;

                    .value-box-row {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        margin-top: 12px;
                    }

                    .value-box {
                        border: solid 1px #e8e8e8;
                        padding: 6px 10px;
                        border-radius: 10px;

                        .value-label {
                            display: block;
                            margin-bottom: 6px;
                            color: rgba(0, 0, 0, 0.5);
                        }

                        .box-value {
                            display: block;

                        }
                    }

                    .change-spreadsheet-button {
                        align-self: center;
                        background: #ebebeb;
                        font-size: 14px;
                        padding: 10px 18px 10px 12px;
                        margin-top: 12px;
                        white-space: nowrap;
                        align-items: center;
                        border: none;
                        border-radius: 10px;
                        cursor: pointer;
                        display: flex;
                        transition: opacity .25s;

                        svg {
                            height: 18px;
                            width: 18px;
                            margin-right: 4px;
                        }

                        &:hover {
                            opacity: .6;
                        }
                    }

                    &.show {
                        display: block;
                    }
                }

                .toggled-fields, .dropdown-toggled-fields {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    &.with-borders {
                        padding: 15px 20px;
                        border: solid 1px rgba(0, 0, 0, 0.125);
                        border-radius: 10px;
                    }

                    &.with-top-border {
                        border-top: 1px solid #e8e8e8;
                        padding-top: 20px;
                    }
                }

                .field-note {
                    display: flex;
                    padding: 20px;
                    background: #f3f3f3;
                    border-radius: 10px;
                    line-height: 1.25;

                    &.extra-top-margin {
                        margin-top: 10px;
                    }

                    &.extra-large-top-margin {
                        margin-top: 20px;
                    }
                }
            }
        }

        .unit-input-container {

            .add-unit {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 8px;
                white-space: nowrap;
                line-height: 1.5;
                background: transparent;
                border: 2px solid #26e66d;
                border-radius: 10px;
                color: #26e66d;
                cursor: pointer;
                font-size: 16px;
                font-weight: 500;
                padding: 8px 14px;
                transition: color 250ms, background-color 250ms;

                .icon {
                    width: 20px;
                    height: 20px;
                }

                &:hover {
                    background: #26e66d;
                    color: white;
                }

                &.fill-style {
                    color: white;
                    background: #26e66d;

                    &:hover {
                        background: transparent;
                        color: #26e66d;
                    }
                }
            }

            .unit-input-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 0 0 10px 0;

                .units-title {
                    font-size: 24px;
                    font-weight: 500;
                }
            }

            .unit-input-form {
                display: none;
                flex-direction: column;
                border: 1px solid rgba(0,0,0,.125);
                border-radius: 10px;
                padding: 15px 20px;
                margin-bottom: 20px;

                .input-form-title {
                    font-size: 22px;
                    font-weight: 500;
                    margin: 5px 0 0 0;
                }

                .errors {
                    display: none;
                    background: rgba(255,0,0,.25);
                    border-radius: 10px;
                    margin: 18px 0;
                    padding: 15px 20px;

                    .error-title {
                        display: block;
                        font-size: 16px;
                        font-weight: 500;
                        margin-bottom: 4px;
                    }

                    .errors-list {
                        display: flex;
                        flex-direction: column;
                        gap: 4px;
                        margin-left: 20px;

                        .error-item {
                            font-size: 16px;
                        }
                    }

                    &.show {
                        display: block;
                    }
                }

                .field {
                    .input-label {
                        font-size: 18px;
                        max-width: 100%;

                        .input-tooltip {
                            font-size: 16px;
                            height: 25px;
                            flex-basis: 25px;
                            flex-grow: 0;
                            flex-shrink: 0;
                            align-self: center;
                            margin: 0;
                        }

                        &.has-tooltip {
                            display: flex;
                        }

                        &.no-bottom-margin {
                            margin-bottom: 0;
                        }
                    }

                    .input-field {
                        input {
                            font-size: 18px;
                        }
                    }

                    .input-subtitle {
                        display: block;
                        font-size: 14px;
                        color: rgba(0, 0, 0, 0.5);
                        margin-top: 6px;

                        &.extra-margin-bottom {
                            margin-bottom: 12px;
                        }
                    }

                    .edit-assumptions-button {
                        margin: 18px 0 0 0;
                        display: flex;
                        justify-content: space-between;
                        text-align: center;
                        align-items: center;
                        background: #f8f8f8;
                        border: none;
                        border-radius: 100px;
                        color: rgba(0,0,0,.8);
                        cursor: pointer;
                        font-size: 16px;
                        font-weight: 500;
                        min-height: 36px;
                        padding: 6px 12px;
                        transition: all .25s;
                        width: 100%;
                    }
                }

                .assumption-fields {
                    display: none;

                    &.show {
                        display: block;
                    }
                }

                .action-row {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin: 20px 0 0 0;

                    .action-button {
                        align-items: center;
                        border: none;
                        border-radius: 10px;
                        cursor: pointer;
                        display: flex;
                        font-size: 18px;
                        padding: 12px 24px;
                        transition: background-color 250ms, opacity 250ms;

                        &.cancel-button {
                            background: #ebebeb;

                            &:hover {
                                opacity: .6;
                            }
                        }

                        &.save-button {
                            background: #26e66d;
                            color: #fff;

                            &:hover {
                                background-color: rgba(38,230,108,.75);
                            }
                        }
                    }
                }

                &.open {
                    display: flex;
                }
            }

            .inputted-units {
                display: flex;
                flex-direction: column;
                gap: 10px;

                .unit-card {
                    display: flex;
                    flex-direction: row;
                    border: 1px solid rgba(0,0,0,.125);
                    border-radius: 10px;
                    padding: 15px 20px;

                    .quantity {
                        font-size: 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding-right: 20px;
                    }

                    .details {
                        display: flex;
                        flex-direction: column;
                        gap: 5px;

                        .text {
                            &.larger {
                                font-size: 18px;
                            }

                            &.subtle {
                                color: rgba(0, 0, 0, 0.5);
                            }
                        }

                        .row {
                            display: flex;
                            flex-direction: row;
                            gap: 10px;
                        }
                    }

                    .actions {
                        margin: 0 0 0 auto;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .delete-unit {
                            align-items: center;
                            background: #f5f5f5;
                            border: none;
                            border-radius: 1000px;
                            color: #7a7a7a;
                            cursor: pointer;
                            display: flex;
                            height: 38px;
                            justify-content: center;
                            transition: background-color .25s;
                            width: 38px;

                            svg {
                                height: 16px;
                            }

                            &:hover {
                                background-color: #e8e8e8;
                            }
                        }
                    }
                }

                .empty-state {
                    display: block;
                    text-align: center;
                    border: 1px solid rgba(0,0,0,.125);
                    border-radius: 10px;
                    padding: 25px 20px;

                    .text {
                        font-size: 20px;
                    }

                    .add-unit {
                        flex: 0;
                        margin: 12px auto 0 auto;
                    }

                    &.hide {
                        display: none;
                    }

                    &.error {
                        box-shadow: 0 0 0 4px rgba(204, 0, 0, .15);
                        background: rgba(204,0,0,.075)!important;
                        border-color: #c00!important;
                        color: #c00;
                    }
                }


            }

            &.top-margin {
                margin-top: 30px;
            }
        }
    }
}