.account-login-form-container {
    display: flex;
    justify-content: center;
    width: 100%;
}

#account-login-form, #account-register-form, #account-register-email-code, #account-password-reset-form, #account-password-reset-change-form {
    background: white;
    padding: 32px;
    border-radius: 8px;
    box-shadow: 0 3px 6px rgb(0 0 0 / 5%);
    width: 550px;

    form {
        .error-block {
            padding: 20px;
            margin-bottom: 26px;
            background: rgba(255,0,0,0.25);
            border-radius: 10px;

            .title {
                display: block;
                font-weight: 600;
                margin-bottom: 4px;
            }

            .description {

            }

            &.email-code-error-block {
                margin-bottom: 28px;
            }

            &.hide {
                display: none;
            }
        }

        .success-block {
            display: flex;
            align-content: center;
            padding: 20px;
            background: rgba(38, 230, 109, 0.1);
            color: #26E66D;
            border-radius: 10px;
            margin-bottom: 26px;

            .text {
                align-self: center;
            }

            svg {
                width: 38px;
                height: 38px;
                margin-right: 15px;
            }

            &.hide {
                display: none;
            }
        }

        .info {
            display: block;
            font-size: 18px;
            line-height: 1.25;
            color: rgba(0, 0, 0, 0.5);
            margin-bottom: 24px;
            text-align: center;
        }

        .block {
            display: block;
            margin: 26px 0 0 0;

            .input {
                label {
                    display: block;
                    font-size: 18px;
                    margin: 0 0 8px 0;

                    &.error {
                        margin-top: 8px;
                        color: red;
                        font-size: 16px;

                        &.hide {
                            display: none;
                        }
                    }
                }

                input {
                    width: 100%;
                    background: #F8F8F8;
                    border: solid 1px #E4E4E4;
                    padding: 10px 16px;
                    border-radius: 10px;
                    font-size: 18px;
                    color: #7A7A7A;
                    resize: vertical;

                    &:disabled {
                        background: rgba(0, 0, 0, 0.075);
                    }

                    &:focus {
                        outline-color: #26E66D;
                    }

                    &::placeholder {
                        color: #d0d0d0;
                    }
                }
            }

            .checkbox {
                margin-right: 12px;

                input[type=checkbox]:focus ~ .checkmark {
                    outline: 2px solid rgba(0, 0, 0, 0.05);
                }

                & ~ label {
                    font-size: 18px;
                    cursor: pointer;
                }
            }

            .forget-password a {
                font-size: 18px;
                color: #26E66D;
                transition: color 250ms;

                &:hover {
                    color: rgba(38, 230, 109, 0.75);
                }

                &:focus {
                    outline-color: #26E66D;
                }
            }

            &.remember-me-forget-password-block {
                display: flex;
                justify-content: space-between;

                .remember-me {
                    display: flex;
                    align-content: center;
                }
            }

            &.first-block {
                margin: 0;
            }
        }

        .login-button, .register-button, .confirm-button, .password-reset-button, .password-reset-change-button {
            display: flex;
            width: 100%;
            justify-content: center;
            border: none;
            background: #26E66D;
            color: white;
            padding: 14px 28px;
            margin: 32px 0 0 0;
            border-radius: 10px;
            font-size: 20px;
            cursor: pointer;
            transition: background-color 250ms;

            .default {
                display: flex;
            }

            .loading {
                display: none;
                svg {
                    width: 20px;
                    margin-right: 8px;
                    animation: rotate 2s linear infinite;

                    @keyframes rotate{
                        from {transform: rotate(0deg);} to {transform: rotate(360deg);}
                    }
                }
            }

            &.loading {
                cursor: default;

                .default {
                    display: none;
                }

                .loading {
                    display: flex;
                }

                &:hover {
                    background-color: rgba(38,230,108,1) !important;
                }
            }

            &:hover:enabled {
                background-color: rgba(38,230,108,0.75);
            }

            &:focus {
                outline-color: rgba(0, 0, 0, 0.25);
            }

            &:disabled {
                opacity: 0.5;
                cursor: default;
            }
        }

        .register-account, .login-account, .resend-code-account {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 12px;

            a, button {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                padding: 8px 18px;
                font-size: 18px;
                text-decoration: none;
                color: rgba(0, 0, 0, 0.5);
                background: none;
                border: none;
                transition: background-color 250ms, color 250ms;
                border-radius: 100px;
                cursor: pointer;

                svg {
                    margin-left: 6px;
                }

                &:enabled:hover {
                    background-color: #F8F8F8;
                    color: black;
                }

                &:focus {
                    outline-color: #26E66D;
                }
            }

            a:hover {
                background-color: #F8F8F8;
                color: black;
            }
        }

        .login-account, .resend-code-account {
            a, button {
                svg {
                    margin-left: 0;
                    margin-right: 6px;
                }
            }
        }

        .resend-code-account {
            button {
                cursor: default;

                svg {
                    width: 20px;
                }

                &:enabled {
                    cursor: pointer;
                    border: solid 1px rgba(0, 0, 0, 0.125);
                    color: rgba(0, 0, 0, 0.5);

                    &:hover {
                        background-color: #F8F8F8;
                        color: black;
                    }
                }
            }
        }

        .account-link-button {
            display: flex;
            text-decoration: none;
            width: 100%;
            justify-content: center;
            color: #26E66D;
            border: solid 1px #26E66D;
            padding: 14px 28px;
            margin: 24px 0 0 0;
            border-radius: 10px;
            font-size: 20px;
            cursor: pointer;
            transition: background-color 250ms, color 250ms;

            svg {
                margin-right: 8px;
            }

            &:hover {
                background-color: #26E66D;
                color: white;
            }

            &:focus {
                outline-color: #26E66D;
            }

            &.hide {
                display: none;
            }
        }
    }
}

.purchase-credit-container {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 74px;
}

#purchase-credits {
    background: white;
    padding: 32px;
    border-radius: 8px;
    box-shadow: 0 3px 6px rgb(0 0 0 / 5%);
    width: 550px;

    .error-message {
        display: block;
        padding: 20px;
        background: rgba(255,0,0,0.25);
        border-radius: 10px;
        margin-bottom: 30px;

        &.hide {
            display: none;
        }
    }

    .section {
        margin-bottom: 32px;

        .header {
            display: flex;
            align-items: center;
            margin: 0 0 12px 0;

            .title {
                font-size: 20px;
                margin: 0;
            }

            .subtitle {
                margin-left: 8px;
                color: rgba(0, 0, 0, 0.5);
            }

        }

        .quantity-field {
            display: flex;
            align-items: center;

            .quantity-input {
                text-align: center;
                width: 50px;
                height: 50px;
                background: none;
                border: solid 1px #E4E4E4;
                border-radius: 10px;
                -moz-appearance: textfield;
                font-size: 18px;

                &:focus {
                    outline-color: #26E66D;
                }

                &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
            }

            .increment-buttons {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                height: 50px;
                margin-left: 6px;

                .add, .subtract {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 24px;
                    background: none;
                    border: solid 1px #E4E4E4;
                    border-radius: 5px;
                    cursor: pointer;
                    transition: border-color 250ms;

                    svg {
                        width: 12px;
                        fill: #E4E4E4;
                        transition: fill 250ms;
                    }

                    &:focus {
                        outline-color: #26E66D;
                    }

                    &:hover {
                        svg {
                            fill: rgba(0, 0, 0, 0.25);
                        }
                    }

                    &:active {
                        border-color: #22E262;

                        svg {
                            fill: #22E262;
                        }
                    }
                }
            }

            .label {
                font-size: 20px;
                margin-left: 8px;
            }
        }

        .pricing-tiers {
            display: grid;
            grid-template-columns: auto auto auto;
            grid-column-gap: 6px;
            list-style: none;
            padding: 0;
            margin: 0;

            .tier {
                display: block;
                border-radius: 6px;
                background: white;
                border: solid 1px #E4E4E4;
                padding: 12px;

                .bracket {
                    display: block;
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.75);
                }

                .unit-price {
                    display: block;
                    margin: 4px 0 0 0;
                }

                &.active {
                    border-color: #22E262;
                    background-color: rgba(34, 227, 98, 0.05);
                }
            }
        }

        .total-rows {
            list-style: none;
            padding: 0;
            margin: 0;

            .row, .grand-total-row {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 18px;
                margin: 12px 0;

                .left {

                }

                .right {

                }

                &.grand-total-row {
                    font-size: 20px;
                    font-weight: 500;
                }
            }

            .divider {
                display: block;
                width: 100%;
                height: 1px;
                background: rgba(0, 0, 0, 0.05);
                margin: 12px 0;
            }
        }

        .discount-field {
            display: flex;
            margin-top: 18px;

            .promo-input {
                flex: 1;
                display: block;
                background: #F8F8F8;
                border: solid 1px #E4E4E4;
                padding: 10px 16px;
                border-radius: 10px;
                font-size: 18px;
                color: #7A7A7A;
                resize: vertical;

                &:disabled {
                    background: rgba(0, 0, 0, 0.075);
                }

                &:focus {
                    outline-color: #26E66D;
                }

                &::placeholder {
                    color: #d0d0d0;
                }
            }

            .apply-discount {
                display: block;
                border: none;
                border-radius: 6px;
                font-size: 16px;
                padding: 4px 12px;
                margin-left: 8px;
                color: white;
                background: #22E262;
                cursor: pointer;
                transition: 250ms background-color;

                &:enabled:hover {
                    background: rgba(38,230,108,0.75);
                }

                &:focus {
                    outline-color: rgba(39, 230, 109, 0.25);
                }

                &:disabled {
                    opacity: 0.25;
                    cursor: default;
                }
            }

            .remove-discount {
                display: none;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                padding: 0 8px;
                margin-left: 8px;
                border-radius: 10px;
                background: #F8F8F8;
                border: solid 1px #E4E4E4;

                svg {
                    color: rgba(0, 0, 0, 0.5);
                    transition: 250ms color;
                }

                &:hover svg {
                    color: rgba(0, 0, 0, 1);
                }
            }

            &.discount-applied {
                .apply-discount {
                    display: none;
                }

                .remove-discount {
                    display: flex;
                }
            }
        }

        .discount-message {
            display: block;
            margin: 8px 0 0 0;

            &.error {
                color: red;
            }

            &.success {
                color: #22E262;
            }

            &.hide {
                display: none;
            }
        }

        .payment-methods {
            display: grid;
            grid-template-columns: 1fr 1fr;
            list-style: none;
            padding: 0;
            margin: 18px 0 0 0;

            li {
                display: flex;
                align-items: center;

                .radio-input {
                    cursor: pointer;

                    &:focus {
                        outline-color: #26E66D;
                    }
                }

                .label {
                    display: flex;
                    align-items: center;
                    margin-left: 12px;
                    font-size: 18px;
                    cursor: pointer;

                    svg {
                        margin-right: 8px;
                        width: 28px;
                        height: 28px;
                    }

                    .payment-logo {
                        height: 28px;
                    }
                }
            }
        }
    }

    .checkout-button {
        display: block;
        border: none;
        border-radius: 10px;
        width: 100%;
        font-size: 20px;
        padding: 16px 0;
        color: white;
        background: #22E262;
        cursor: pointer;
        transition: 250ms background-color;

        &:enabled:hover {
            background: rgba(38,230,108,0.75);
        }

        &:focus {
            outline-color: rgba(39, 230, 109, 0.25);
        }

        &:disabled {
            opacity: 0.25;
            cursor: default;
        }
    }
}

#redeem-credits-code {
    background: white;
    padding: 32px;
    border-radius: 8px;
    box-shadow: 0 3px 6px rgb(0 0 0 / 5%);
    width: 550px;

    .error-message {
        display: block;
        padding: 20px;
        background: rgba(255,0,0,0.25);
        border-radius: 10px;
        margin-bottom: 30px;

        &.hide {
            display: none;
        }
    }

    .success-block {
        display: flex;
        align-content: center;
        padding: 20px;
        background: rgba(38, 230, 109, 0.1);
        color: #26E66D;
        border-radius: 10px;
        margin-bottom: 26px;

        .text {
            align-self: center;
        }

        svg {
            width: 38px;
            height: 38px;
            margin-right: 15px;
        }

        &.hide {
            display: none;
        }
    }

    .code-field {
        display: grid;
        width: 100%;
        grid-template-columns: auto 24px auto 24px auto 24px auto;
        align-items: center;
        margin: 0 0 24px 0;

        .code-section {
            input {
                display: block;
                width: 100%;
                background: #F8F8F8;
                border: solid 1px #E4E4E4;
                padding: 8px 8px;
                border-radius: 10px;
                font-size: 26px;
                text-align: center;
                color: #7A7A7A;
                -moz-appearance: textfield;

                &:disabled {
                    background: rgba(0, 0, 0, 0.075);
                }

                &:focus {
                    outline-color: #26E66D;
                }

                &::placeholder {
                    color: #d0d0d0;
                }

                &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
            }
        }

        .divider {
            text-align: center;
            font-size: 24px;
            color: rgba(0, 0, 0, 0.25);
        }

    }

    .redeem-code-button {
        display: block;
        border: none;
        border-radius: 10px;
        width: 100%;
        font-size: 20px;
        padding: 16px 0;
        color: white;
        background: #22E262;
        cursor: pointer;
        transition: 250ms background-color;

        &:enabled:hover {
            background: rgba(38,230,108,0.75);
        }

        &:focus {
            outline-color: rgba(39, 230, 109, 0.25);
        }

        &:disabled {
            opacity: 0.25;
            cursor: default;
        }
    }
}

.account-generic-container {
    display: flex;
    justify-content: center;

    .content-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 600px;
        max-width: 90%;
        text-align: center;
        margin-top: 64px;

        .title {
            font-size: 32px;
            font-weight: 500;
            margin: 14px 0 0 0;
        }

        .subtitle {
            display: block;
            font-size: 20px;
            font-weight: 400;
            margin-top: 14px;

            &.highlight {
                background: rgba(38,230,109,0.1);
                border: solid 1px #26E66D;
                color: #26E66D;
                padding: 6px 12px;
                border-radius: 100px;
            }

        }

        .paragraph {
            display: block;
            font-size: 20px;
            line-height: 1.25;
            margin: 14px 0 0 0;
        }

        .spinner {
            margin: 28px auto 16px auto;
            font-size: 10px;
            position: relative;
            text-indent: -9999em;
            border-top: 1.1em solid rgba(0,0,0,0.05);
            border-right: 1.1em solid rgba(0,0,0,0.05);
            border-bottom: 1.1em solid rgba(0,0,0,0.05);
            border-left: 1.1em solid #26E66D;
            transform: translateZ(0);
            animation: load8 1.1s infinite linear;

            border-radius: 50%;
            width: 10em;
            height: 10em;
        }

        .circle-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #26E66D;
            background: rgba(38,230,109,0.1);
            height: 72px;
            width: 72px;
            border-radius: 1000px;

            svg {
                height: 48px;
                width: 48px;
            }
        }

        .links {
            margin-top: 24px;

            .link-button {
                display: inline-flex;
                align-items: center;
                justify-items: center;
                text-decoration: none;
                padding: 12px 16px;
                margin-right: 8px;
                font-size: 18px;
                background: rgb(235, 235, 235);
                color: black;
                border-radius: 6px;
                border: none;
                transition: background-color 250ms;
                cursor: pointer;

                svg {
                    margin-right: 8px;
                }

                &:hover {
                    background-color: rgb(224, 224, 224);
                }

                &:focus {
                    outline-color: #26E66D;
                }

                &:last-of-type {
                    margin-right: 0;
                }
            }

        }

        .resend-button {
            display: inline-flex;
            padding: 12px 16px;
            font-size: 18px;
            align-self: center;
            background: rgb(235,235,235);
            border-radius: 6px;
            border: none;
            transition: background-color 250ms;
            cursor: pointer;

            &:enabled:hover {
                background-color: rgb(224, 224, 224);
            }

            &:focus {
                outline-color: #26E66D;
            }

            &:disabled {
                opacity: 0.75;
                cursor: default;
            }
        }

        .resend-notice {
            display: block;
            margin-top: 12px;
            font-size: 16px;

            &.error {
                color: red;
            }

            &.success {
                color: #26E66D;
            }
        }
    }
}