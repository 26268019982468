.page-error {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100%;

	.title {
		font-size: 32px;
	}

	.subtitle {
		font-size: 22px;
	}

	.error-icon {
		svg {
			height: 65px;
			width: 65px;
		}
	}

	.error-icon ~ .error-title {
		margin-top: 20px;
	}

	.error-subtitle {
		font-size: 18px;
		color: rgba(0, 0, 0, 0.75);
		line-height: 1.5;
	}

	.error-action-row {
		display: flex;
		gap: 12px;
		margin-top: 8px;

		.error-primary-button, .error-secondary-button {
			display: inline-flex;
			align-items: center;
			text-decoration: none;
			color: white;
			background: #26E66D;
			border: solid 2px #26E66D;
			font-size: 18px;
			padding: 12px 16px;
			margin: 12px 0 0 0;
			border-radius: 6px;
			cursor: pointer;
			transition: background-color 250ms, color 250ms, border-color 250ms;

			&:focus {
				outline: rgba(39,230,109,0.5) solid 1px;
			}

			&:hover {
				background-color: rgba(39,230,109,0.75);
				border-color: rgba(39,230,109,0.75);
			}
		}

		.error-primary-button {
			color: white;
			background: #26E66D;

			&:focus {
				outline: rgba(39,230,109,0.5) solid 1px;
			}

			&:hover {
				background-color: rgba(39,230,109,0.75);
			}
		}

		.error-secondary-button {
			color: #26E66D;
			background: transparent;
			border: solid 2px #26E66D;

			&:focus {
				outline: rgba(39,230,109,0.5) solid 1px;
			}

			&:hover {
				background-color: #26E66D;
				color: white;
			}
		}

		.icon {
			height: 24px;
		}
	}

	&.with-navbar {
		height: calc(100vh - 65px);
	}

}