.generic-page {
    background: white;
    padding: 48px;
    border-radius: 8px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 5%);
}

.generic-header {
    display: block;
    text-align: center;
    padding: 48px 0;

    .generic-title {
        font-size: 38px;
        font-weight: 500;
        margin: 0;
    }

    .generic-subtitle {
        font-size: 24px;
        font-weight: 400;
        margin: 4px 0 0 0;
    }

}

