.retrieve-report-page {
    width: 600px;
    padding: 32px;
}

.retrieve-report-form {
    display: flex;
    flex-direction: column;

    .row {
        display: flex;
    }

    input[type=number] {
        width: 100%;
        padding: 10px 12px;
        margin-right: 10px;
        border-radius: 5px;
        background: white;
        font-size: 20px;
        border: solid 1px #E4E4E4;
        color: #7A7A7A;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.05);

        &:focus, &:hover {
            outline: none;
        }

        &::placeholder {
            font-size: 20px;
        }

        -moz-appearance: textfield;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    button[type=submit] {
        display: block;
        padding: 10px 16px;
        font-size: 20px;
        border-radius: 5px;
        background: #26E66D;
        color: white;
        border: none;
        cursor: pointer;
        transition: all 250ms;

        &:focus, &:hover {
            background: rgba(38,230,108,0.75);
            outline: none;
        }
    }

    .error-text {
        display: block;
        text-align: center;
        font-size: 20px;
        color: #f33a3a;
        margin-top: 16px;
    }
}