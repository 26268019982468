
.input.error {
    .wtp-dropdown {
        .button {
            background: rgba(204,0,0,.075)!important;
            border-color: #c00!important;
            border-radius: 10px;
            box-shadow: 0 0 0 4px rgba(204,0,0,.15);
        }
    }
}


.wtp-dropdown {
    position: relative;
    user-select: none;

    .button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 12px;
        border-radius: 10px;
        border: solid 1px #E4E4E4;
        background: #F8F8F8;
        color: #7A7A7A;
        font-size: 18px;
        cursor: pointer;

        span {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    .dropdown-content {
        z-index: 9999;
        min-width: 100%;
        display: none;
        position: absolute;
        top: 56px;
        border-radius: 10px;
        border: solid 1px #E4E4E4;
        background: #F8F8F8;
        color: #7A7A7A;

        .searchbar {
            padding: 5px;
            background: #E4E4E4;
            border-radius: 10px 10px 0 0;

            input {
                margin-top: 0 !important;
                background: #F8F8F8;

                &::placeholder {
                    color: rgba(122, 122, 122, 0.5);
                }
            }
        }

        .no-data {
            display: block;
            font-size: 18px;
            text-align: center;
            padding: 6px 5px;

            &.hidden {
                display: none;
            }
        }

        ul {
            min-width: 100%;
            max-height: 250px;
            overflow-x: auto;
            overflow-y: auto;
            list-style: none;
            padding: 8px;

            li {
                display: block;
                font-size: 18px;
                padding: 10px 10px;
                cursor: pointer;
                white-space: nowrap;

                &:hover {
                    background: #E4E4E4;
                    border-radius: 5px;
                }
            }

            &.scrollbar-less {
                max-height: 1000px;
            }
        }

        &.open {
            display: block;
        }
    }

    //Relative
    &.relative {
       .dropdown-content {
           position: relative;
           top: 14px;
           margin-bottom: 14px;
       }
    }

    //Disabled
    &.disabled {
        .button {
            background: #eeeeee;
            color: #d0d0d0;
            cursor: default;
        }
    }
}