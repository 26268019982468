.contact-details {
    display: flex;
    align-items: center;
    justify-content: center;

    & > a {
        display: flex;
        align-items: center;
        text-decoration: none;
        font-size: 18px;
        margin-right: 12px;
        color: white;
        border-radius: 100px;
        background: #26E66D;
        padding: 4px 12px;
        border: solid 2px #26E66D;
        transition: background 250ms, color 250ms;

        &:hover {
            background: white;
            color: #26E66D;
        }

        &:last-of-type {
            margin-right: 0;
        }

        svg {
            height: 28px;
            width: 28px;
            margin-right: 6px;
        }
    }
}

.contact-divider {
    display: flex;
    align-items: center;
    width: 100%;
    text-align: center;
    white-space: nowrap;
    font-size: 18px;
    margin-top: 24px;

    &:before, &:after {
        content: "";
        display: inline-block;
        height: 2px;
        width: 100%;
        background: rgba(0, 0, 0, 0.1);
    }

    &:before {
        margin-right: 12px;
    }

    &:after {
        margin-left: 12px;
    }
}

.contact-form {
    width: 100%;
    margin: 32px auto auto auto;
    background: white;
    border-radius: 20px;


    .message-area {
        .success-message, .error-message {
            display: block;
            padding: 20px;
            background: rgba(255, 0, 0, 0.25);
            border-radius: 10px;
            margin-bottom: 24px;

            span {
                display: block;
                font-weight: 500;
                font-size: 18px;
                margin-bottom: 4px;
            }

            ul {
                margin-left: 20px;
                li {
                    font-size: 16px;
                    margin-bottom: 4px;

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }

            &.success-message {
                display: flex;
                align-items: center;
                color: black;
                background: rgba(38, 230, 109, 0.125);

                .icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: rgba(255, 255, 255, 1);
                    background: rgba(38, 230, 109, 1);
                    width: 32px;
                    height: 32px;
                    border-radius: 1000px;
                    margin-right: 15px;

                    svg {
                        width: 24px;
                        height: 24px;
                    }
                }

                span {
                    font-weight: 400;
                    margin: 0;
                }
            }
        }
    }

    input#website {
        display: none;
    }

    .block {
        display: block;
        margin: 26px 0 0 0;

        .input {
            width: 100%;

            label {
                display: block;
                font-size: 18px;
                margin: 0 0 8px 0;
            }

            input, textarea {
                width: 100%;
                background: #F8F8F8;
                border: solid 1px #E4E4E4;
                padding: 10px 16px;
                border-radius: 10px;
                font-size: 18px;
                color: #7A7A7A;
                resize: vertical;

                &:focus {
                    outline: none;
                }
            }

        }

        &.double {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .input {
                width: calc(50% - 10px);
            }
        }

        &.action {
            margin: 20px 0 0 0;
            display: flex;
            justify-content: flex-end;

            button[type=submit] {
                border: none;
                background: #26E66D;
                color: white;
                padding: 14px 28px;
                border-radius: 10px;
                font-size: 20px;
                cursor: pointer;
                transition: all 250ms;

                &:hover, &:focus {
                    outline: none;
                    background: rgba(38, 230, 109, 0.75);
                }
            }
        }

        &.checkbox-block {
            display: flex;
            align-items: center;

            label[for] {
                cursor: pointer;
                margin: 2px 0 0 12px;
                position: relative;
                bottom: 2px;
                font-size: 18px;
            }

            .checkbox {
                transform: scale(1.2);
            }
        }

        &:first-of-type {
            margin-top: 0;
        }
    }
}
