.navbar {
    display: flex;
    z-index: 3000;
    position: relative;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0 3px 8px rgba(0, 0, 0, .06);

    .nav-items {
        list-style: none;
        display: flex;
        margin-left: auto;

        @media only screen and (max-width: 1350px) {
            display: none;
        }

        .link, .dropdown {
            > a, .trigger {
                text-decoration: none;
                font-size: 20px;
                font-weight: 400;
                color: black;
                padding: 0 12px;
                transition: color 250ms;
                cursor: pointer;
                user-select: none;

                &:hover {
                    color: rgba(0, 0, 0, 0.75);
                }
            }
        }

        .dropdown {
            .trigger {
                display: flex;
                .feather {
                    margin-left: 4px;
                }
            }

            .dropdown-content {
                display: none;

                position: absolute;
                top: 85px;
                flex-direction: column;
                background: white;
                box-shadow: 0 3px 8px rgba(0, 0, 0, 0.06);
                border-radius: 10px;
                padding: 10px;
                max-width: 250px;

                .arrow {
                    position: absolute;
                    top: -14px;
                    left: 25px;
                    height: 14px;
                }

                .dropdown-link {
                    display: block;
                    text-decoration: none;
                    font-size: 18px;
                    color: rgba(0, 0, 0, 0.75);
                    padding: 10px 10px;
                    transition: color 250ms;
                    cursor: pointer;
                    border-radius: 10px;

                    &:hover {
                        color: rgba(0, 0, 0, 1);
                        background: rgba(0, 0, 0, 0.04);
                    }
                }

                &.open {
                    display: flex;
                }
            }
        }
    }

    .navbar-container {
        position: relative;
        width: calc(100% - 100px);
        max-width: 1300px;
        margin: auto;
        height: 65px;
        display: flex;
        align-items: center;
    }

    .logo {
        height: 25px;

        img {
            height: 25px;
        }
    }

    .off-canvas {
        display: none;
        z-index: 1001;
        position: fixed;
        top: 0;
        left: 0;
        min-height: 100vh;
        width: 100%;
        padding: 60px 20px 30px 20px;
        background: rgba(255, 255, 255, 0.9);
        backdrop-filter: blur(4px);
        box-shadow: -3px 0px 8px rgba(0, 0, 0, .06);
        user-select: none;

        ul {
            list-style: none;

            li {
                display: block;
                margin: 0 0 10px 0;

                > a {
                    display: flex;
                    padding: 10px 14px;
                    text-decoration: none;
                    font-size: 20px;
                    color: #2b3036;
                    border-radius: 5px;
                    cursor: pointer;

                    .feather {
                        margin-left: auto;
                    }

                    &.active {
                        color: #26E66D;
                        font-weight: 500;
                    }

                    &:hover {
                        //color: #26E66D;
                        //background: rgba(38, 230, 108, 0.1);
                        background: rgb(245 245 245);
                        transition: all 300ms;
                    }
                }

                .dropdown-content {
                    display: none;
                    flex-direction: column;
                    margin-left: 20px;
                    padding: 6px 0;
                    //border-top: solid 1px #e5e5e5;
                    //border-bottom: solid 1px #e5e5e5;

                    .dropdown-link {
                        display: flex;
                        padding: 10px 14px;
                        text-decoration: none;
                        font-size: 18px;
                        color: rgba(0, 0, 0, 1);
                        border-radius: 5px;

                        &:hover {
                            background: rgb(245 245 245);
                            transition: all 300ms;
                        }
                    }
                }

                &.dropdown.open {

                    .dropdown-content{
                        display: flex;
                    }

                    > a {
                        background: rgb(245 245 245);
                        transition: all 300ms;
                    }
                }

                &:last-of-type {
                    margin-bottom: 0;
                }
            }


        }

        .close {
            position: absolute;
            top: 18px;
            right: 20px;
            display: block;
            width: 40px;
            height: 40px;
            background: rgb(245 245 245);
            border: none;
            border-radius: 5px;
            color: rgba(0, 0, 0, 0.90);
            cursor: pointer;

            &:focus, &:active {
                outline: none;
            }
        }
    }

    #burger-nav {
        display: none;
        background: none;
        border: none;
        cursor: pointer;
        position: absolute;
        right: 0;

        @media only screen and (max-width: 1350px) {
            display: block;
        }

        img {
            height: 25px;
            position: absolute;
            transform: translateY(-50%);
            right: 0;
        }

        &:focus {
            outline: none;
        }
    }
}

.navbar-dropdown-overlay {
    display: none;
    opacity: 0;
    z-index: 2000;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(3px);
    transition: opacity 1s;

    &.open {
        display: block;
        opacity: 1;
    }
}

