


.no-data-alert {
    display: none;

    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;

    max-width: 400px;
    background: white;

    padding: 15px;
    border-radius: 10px;
    z-index: 1014;

    &.show {
        display: block;
    }

    .text {
        font-size: 20px;
        margin-bottom: 14px;
        text-align: center;
    }

    button.primary {
        display: block;
        width: 100%;
        background: #26E66D;
        color: white;
        padding: 12px 24px;
        border-radius: 10px;
        font-size: 20px;
        transition: all 250ms;
        border: none;
        cursor: pointer;

        &:hover {
            background: rgba(38,230,108,0.75);
        }
    }
}


.no-data-mask {
    display: none;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.35);
    border-radius: 10px;
    z-index: 1013;

    &.show {
        display: block;
    }
}