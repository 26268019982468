.report-generator-panel {

	width: 900px;
	background: white;
	box-shadow: 0 3px 6px rgb(0, 0, 0, 0.05);
	padding: 20px;
	margin: 0 auto 48px auto;
	border-radius: 8px;

	.title {
		font-size: 24px;
		margin: 8px 0 24px 0;
		text-align: center;
	}

	//Information Rows
	.info-rows {

		.info-row {
			display: flex;
			flex-direction: column;
			width: 100%;
			margin: 12px 0;

			.head {
				display: flex;
				align-items: center;
				width: 100%;
				cursor: pointer;

				.status-icon {
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 1000px;
					padding: 5px;
					margin-right: 15px;

					&.success {
						background: #6bc68c;
						color: white;
					}

					&.fail {
						background: #ef7272;
						color: white;
					}

					&.optional {
						background: rgb(243, 243, 243);
						color: rgba(0, 0, 0, 0.75);
						font-weight: 500;
						font-size: 20px;
						width: 30px;
						height: 30px;
						padding: 0;
					}

					&.hide {
						display: none;
					}

					svg {
						width: 20px;
						height: 20px;
					}
				}

				h3 {
					justify-self: start;
					font-size: 22px;
					font-weight: 500;

					user-select: none;
					-webkit-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
				}

				.body-toggle {
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 6px;
					border-radius: 1000px;
					border: solid 1px transparent;
					cursor: pointer;
					margin-left: auto;
					transition: opacity 250ms;

					svg {
						width: 22px;
						height: 22px;
					}

					&:hover {
						opacity: 0.6;
					}

					&:focus {
						outline: none;
					}
				}

			}

			.body {
				display: none;
				margin: 10px 0 10px 0;

				.row-title {
					display: block;
					font-size: 20px;
					font-weight: 500;
					margin: 8px 0 12px 0;
					text-align: left;
				}

				.field-row {
					display: flex;
					justify-content: space-between;

					.field {
						display: flex;
						flex-direction: column;
						position: relative;
						margin-bottom: 10px;

						.input {
							padding: 10px 12px;
							border-radius: 5px;
							background: white;
							font-size: 18px;
							border: solid 1px #E4E4E4;
							color: #7A7A7A;
							box-shadow: 0 0 4px rgba(0, 0, 0, 0.05);

							&:focus {
								outline: none;
							}

							&::placeholder {
								font-size: 18px;
								opacity: 0.5;
							}
						}

						.input[type=date]::-webkit-calendar-picker-indicator {
							background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23E4E4E4" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
							cursor: pointer;
						}

						.input[type=number] {
							-moz-appearance: textfield;

							&::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
								-webkit-appearance: none;
								margin: 0;
							}
						}

						.input-label {
							font-size: 14px;
							position: absolute;
							top: 0;
							left: 12px;
							opacity: 0;
						}

						.input:not(:placeholder-shown) {
							padding-top: 28px;
						}

						.input:not(:placeholder-shown) + .input-label {
							top: 8px;
							opacity: 0.5;
						}

						.dropdown {
							display: block;
							position: relative;

							.button {
								position: relative;
								display: flex;
								align-items: center;
								width: 100%;
								padding: 28px 12px 10px 12px;
								border-radius: 5px;
								background: white;
								font-size: 18px;
								border: solid 1px #E4E4E4;
								color: #7A7A7A;
								box-shadow: 0 0 4px rgba(0, 0, 0, 0.05);
								cursor: pointer;

								.selected {
									font-size: 18px;
								}

								.icon {
									position: absolute;
									right: 10px;
									margin-left: auto;
									width: 24px;
									height: 24px;
									opacity: 0.5;
								}
							}

							.dropdown-content {
								z-index: 1010;
								text-align: left;
								position: absolute;
								top: 70px;
								display: none;
								padding: 10px 12px;
								border-radius: 5px;
								background: white;
								font-size: 18px;
								border: solid 1px #E4E4E4;
								color: #7A7A7A;
								box-shadow: 0 0 4px rgba(0, 0, 0, 0.05);

								ul {
									max-height: 250px;
									overflow-x: auto;
									overflow-y: auto;
									list-style: none;

									li {
										padding: 8px 8px;
										margin: 2px 0;
										cursor: pointer;
										border-radius: 4px;
										white-space: nowrap;
										overflow-x: auto;

										&:hover {
											background: rgba(0, 0, 0, 0.035);
										}
									}

									.no-data {
										padding: 8px 8px;

										&.hidden {
											display: none;
										}
									}

									.simplebar-wrapper {
										display: block;
										overflow: auto;
										overflow-y: hidden;
									}

									.simplebar-content {
										width: fit-content;
										max-width: 300px;
									}

								}

								.searchbar {
									margin-bottom: 10px;

									input {
										display: block;
										width: 100%;
										padding: 8px 10px;
										border-radius: 5px;
										border: solid 1px #E4E4E4;

										&::placeholder {
											opacity: 0.75;
										}

										&:focus {
											outline: none;
										}
									}
								}

								&.open {
									display: block;
								}
							}
						}

						.checkbox {
							display: flex;
							align-items: center;
							width: 100%;
							color: #7A7A7A;
							height: 22px;
							position: relative;
							padding-left: 32px;
							cursor: pointer;
							font-size: 18px;
							-webkit-user-select: none;
							-moz-user-select: none;
							-ms-user-select: none;
							user-select: none;

							input[type=checkbox] {
								position: absolute;
								opacity: 0;
								cursor: pointer;
								height: 0;
								width: 0;
							}

							.checkmark {
								position: absolute;
								top: 0;
								left: 0;
								height: 22px;
								width: 22px;
								border: solid 1px #E4E4E4;
								box-shadow: 0 0 4px rgb(0 0 0 / 5%);
							}


							input[type=checkbox]:checked ~ .checkmark {
								background-color: #26E66D;
								border-color: #26E66D;
							}

							.checkmark:after {
								content: "";
								position: absolute;
								display: none;
							}

							input[type=checkbox]:checked ~ .checkmark:after {
								display: block;
							}

							.checkmark:after {
								left: 6px;
								top: 2px;
								width: 5px;
								height: 10px;
								border: solid white;
								border-width: 0 3px 3px 0;
								-webkit-transform: rotate(45deg);
								-ms-transform: rotate(45deg);
								transform: rotate(45deg);
							}


						}

						&.error {
							.input {
								box-shadow: 0 0 6px #f33a3a;
								border: solid 1px #e4e4e400;
							}

							.dropdown .button {
								box-shadow: 0 0 6px #f33a3a;
								border: solid 1px #e4e4e400;
							}
						}

						&.warning {
							.input {
								box-shadow: 0 0 6px #e89608;
								border: solid 1px #e4e4e400;
							}

							.dropdown .button {
								box-shadow: 0 0 6px #e89608;
								border: solid 1px #e4e4e400;
							}
						}
					}

					.dropdown-field {
						&:not(.dropdown-placeholder) {
							.input-label {
								font-size: 14px;
								left: 12px;
								top: 8px;
								opacity: 0.5;
							}
						}

						&.dropdown-placeholder .dropdown{
							.button {
								padding-top: 10px;

								.selected {
									opacity: 0.5;
								}
							}

							.dropdown-content {
								top: 52px;
							}
						}
					}

					.input-badge-field {
						position: relative;

						.input-badge {
							position: absolute;
							right: 12px;
							top: 12px;
							color: #7A7A7A;
							background: white;
							-webkit-touch-callout: none;
							-webkit-user-select: none;
							-moz-user-select: none;
							-ms-user-select: none;
							user-select: none;
						}

						.input:not(:placeholder-shown) ~ .input-badge {
							top: 30px;
						}
					}

					&.two-fields {
						.field {
							width: calc(50% - 5px);
						}
					}

					&#manualDegreeDaysFields {
						margin-top: 5px;
					}
				}

			}

			&.open {
				.body {
					display: block;
				}
				.head .body-toggle {
					transform: rotate(180deg);
				}
			}
		}

		.info-title {
			position: relative;
			font-size: 20px;
			font-weight: 500;
			display: flex;
			margin: 16px 0 4px 0;
			background: white;
			white-space: nowrap;

			&::after {
				display: block;
				position: relative;
				top: 12px;
				overflow: hidden;
				width: 100%;
				height: 1px;
				content: '\a0';
				background-color: #E4E4E4;
				z-index: 1000;
				margin-left: 12px;
			}
		}
	}

	.single-input-label {
		display: block;
		text-align: center;
		border-top: solid 1px #E4E4E4;
		padding-top: 25px;
		font-size: 20px;
		font-weight: 500;

		&.not-first {
			border-top: 0;
			padding-top: 10px;
		}
	}

	.single-input-field {
		display: inline-flex;
		margin-top: 12px;
		padding-bottom: 15px;

		.yes-no-button {
			margin-right: 10px;

			label {
				font-size: 18px;
				display: inline-flex;
				position: relative;
				align-items: center;
				padding: 10px 15px;
				border: solid 1px #E4E4E4;
				box-shadow: 0 0 4px rgba(0, 0, 0, 0.05);
				border-radius: 5px;
				cursor: pointer;
			}

			input[type=radio] {
				position: absolute;
				cursor: pointer;
				height: 0;
				width: 0;
				opacity: 0;
			}

			input[type=radio]:checked + label {
				background: #26E66D;
				border-color: #26E66D;
				color: white;
			}

			&:last-of-type {
				margin-right: 0;
			}
		}

		.dropdown {
			display: inline-block;
			position: relative;

			.button {
				position: relative;
				display: flex;
				align-items: center;
				width: 100%;
				padding: 10px 12px 10px 12px;
				border-radius: 5px;
				background: white;
				font-size: 18px;
				border: solid 1px #E4E4E4;
				color: #7A7A7A;
				box-shadow: 0 0 4px rgba(0, 0, 0, 0.05);
				cursor: pointer;

				.selected {
					font-size: 18px;
				}

				.icon {
					position: absolute;
					right: 10px;
					margin-left: auto;
					width: 24px;
					height: 24px;
					opacity: 0.5;
				}
			}

			.dropdown-content {
				z-index: 1010;
				text-align: left;
				position: absolute;
				top: 52px;
				display: none;
				padding: 10px 12px;
				border-radius: 5px;
				background: white;
				font-size: 18px;
				border: solid 1px #E4E4E4;
				color: #7A7A7A;
				box-shadow: 0 0 4px rgba(0, 0, 0, 0.05);

				ul {
					max-height: 250px;
					overflow-x: auto;
					overflow-y: auto;
					list-style: none;

					li {
						padding: 8px 8px;
						margin: 2px 0;
						cursor: pointer;
						border-radius: 4px;
						white-space: nowrap;
						overflow-x: auto;

						&:hover {
							background: rgba(0, 0, 0, 0.035);
						}
					}

					.no-data {
						padding: 8px 8px;

						&.hidden {
							display: none;
						}
					}

					.simplebar-wrapper {
						display: block;
						overflow: auto;
						overflow-y: hidden;
					}

					.simplebar-content {
						width: fit-content;
						max-width: 300px;
					}

				}

				.searchbar {
					margin-bottom: 10px;

					input {
						display: block;
						width: 100%;
						padding: 8px 10px;
						border-radius: 5px;
						border: solid 1px #E4E4E4;

						&::placeholder {
							opacity: 0.75;
						}

						&:focus {
							outline: none;
						}
					}
				}

				&.open {
					display: block;
				}
			}
		}

		.field {
			position: relative;

			.input {
				padding: 10px 12px;
				border-radius: 5px;
				background: white;
				font-size: 18px;
				border: solid 1px #E4E4E4;
				color: #7A7A7A;
				box-shadow: 0 0 4px rgba(0,0,0,0.05);

				&:focus {
					outline: none;
				}

				&::placeholder {
					font-size: 18px;
					opacity: 0.5;
				}
			}

			&.input-badge-field {
				position: relative;

				.input-badge {
					position: absolute;
					right: 12px;
					top: 12px;
					color: #7A7A7A;
					background: white;
					-webkit-touch-callout: none;
					-webkit-user-select: none;
					user-select: none;
				}
			}
		}

		&.block-field {
			display: block;
		}
	}

	.additional-surveys {
		display: none;
		text-align: left;

		&.show {
			display: block;
		}

		.heading {
			display: block;
			font-size: 20px;
			font-weight: 500;
			margin: 12px 0 12px 0;
		}

		.additional-surveys-error {
			display: none;
			margin-top: 10px;
			color: #ef7272;

			&.show {
				display: block;
			}
		}

		.survey-input-rows {

			.survey-row {
				display: flex;
				align-items: center;


				.hash-badge {
					position: relative;
					padding: 10px 0 10px 12px;
					border-radius: 5px 0 0 5px;
					background: white;
					font-size: 18px;
					border: solid 1px #E4E4E4;
					border-right: 0;
					color: #7A7A7A;
					box-shadow: 0 0 4px rgb(0 0 0 / 5%);
				}

				input[type=text] {
					position: relative;
					padding: 10px 12px 10px 8px;
					border-radius: 0 5px 5px 0;
					background: white;
					font-size: 18px;
					border: solid 1px #E4E4E4;
					border-left: 0;
					color: #7A7A7A;
					box-shadow: 2px 0 4px rgb(0 0 0 / 5%);

					&::placeholder {
						font-size: 18px;
						opacity: 0.5;
					}

					&:focus {
						outline: none;
					}
				}




			}
		}

		.survey-list {
			list-style: none;
			border-top: solid 1px #E4E4E4;
			padding-top: 5px;
			margin-top: 15px;

			li {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 10px;

				input {
					position: relative;
					width: 100%;
					padding: 10px 12px;
					border-radius: 5px;
					background: white;
					font-size: 18px;
					border: solid 1px #E4E4E4;
					color: #7A7A7A;
					box-shadow: 2px 0 4px rgb(0 0 0 / 5%);

					&:focus {
						outline: none;
					}
				}

				.delete-survey {
					display: flex;
					align-items: center;
					justify-content: center;
					border: none;
					height: 42px;
					width: 42px;
					border-radius: 5px;
					margin-left: 8px;
					cursor: pointer;
					color: white;
					background: #ef7272;

					.icon {
						width: 20px;
						height: 20px;
					}
				}

			}
		}

		.add-survey {
			display: flex;
			align-items: center;
			border: none;
			padding: 8px 12px 8px 8px;
			margin-left: 10px;
			border-radius: 5px;
			cursor: pointer;
			color: white;
			background: #26E66D;
			height: 42px;

			.icon {
				width: 22px;
				height: 22px;
				margin-right: 5px;
			}
		}
	}

	.manual-data {
		display: none;
		text-align: left;
		width: 100%;

		&.show {
			display: block;
		}

		.heading {
			display: block;
			font-size: 20px;
			font-weight: 500;
			margin: 12px 0 12px 0;
		}

		.manual-data-error {
			display: none;
			margin: 10px 0;
			color: #ef7272;

			&.show {
				display: block;
			}
		}

		.survey-input-rows {
			border: solid 1px #E4E4E4;
			border-radius: 5px;
			padding: 15px;

			.survey-row {
				display: flex;
				flex-direction: column;
				width: 100%;

				input[type=text], input[type=number] {
					position: relative;
					padding: 10px 12px;
					border-radius: 5px;
					background: white;
					font-size: 18px;
					border: solid 1px #E4E4E4;
					color: #7A7A7A;
					box-shadow: 2px 0 4px rgb(0 0 0 / 5%);
					-moz-appearance: textfield;

					&::placeholder {
						font-size: 18px;
						opacity: 0.5;
					}

					&:focus {
						outline: none;
					}

					&::-webkit-outer-spin-button,
					&::-webkit-inner-spin-button {
						-webkit-appearance: none;
						margin: 0;
					}
				}

				.input-row {
					width: 100%;
					margin-bottom: 10px;
					display: flex;

					.input {
						display: flex;
						flex-direction: column;

						label {
							display: block;
							font-size: 18px;
							margin-bottom: 5px;
						}
					}

					&.two-inputs {
						.input {
							width: calc(50% - 5px);
							margin-right: 10px;

							&:last-of-type {
								margin-right: 0;
							}
						}
					}

				}
			}
		}

		.manual-data-list {
			width: 100%;
			font-size: 18px;
			margin-top: 20px;
			border-collapse: collapse;

			thead {
				background: #F5F5F5;

				th {
					padding: 14px 0;
					color: rgba(0, 0, 0, 0.5);
					font-size: 16px;
					text-transform: uppercase;
					text-align: left;

					&:first-child {
						border-radius: 10px 0 0 10px;
						padding: 0 0 0 20px;
					}

					&:last-child {
						border-radius: 0 10px 10px 0;
						min-width: 10px;
					}
				}
			}

			tr {
				border-top: solid 1px #F5F5F5;

				td {
					padding: 10px 0;

					.delete-entry {
						display: flex;
						align-items: center;
						justify-content: center;
						background: #F5F5F5;
						color: #7A7A7A;
						border: none;
						border-radius: 1000px;
						height: 38px;
						width: 38px;
						margin-right: 8px;
						transition: all 250ms;
						cursor: pointer;

						svg {
							height: 16px;
						}

						&:hover {
							opacity: 0.6;
							outline: none;
						}
					}

					&:first-child {
						padding-left: 20px;
					}
				}

				.empty-state {
					width: 100%;
					text-align: center;
					border-bottom: 0;
					padding-top: 20px;
					font-weight: 400;
				}

				&:first-child {
					border-top: 0;
				}
			}

		}

		.survey-list {
			list-style: none;
			padding-top: 5px;
			margin-top: 15px;

			li {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 10px;

				input {
					position: relative;
					width: 100%;
					padding: 10px 12px;
					border-radius: 5px;
					background: white;
					font-size: 18px;
					border: solid 1px #E4E4E4;
					color: #7A7A7A;
					box-shadow: 2px 0 4px rgb(0 0 0 / 5%);

					&:focus {
						outline: none;
					}
				}

				.delete-survey {
					display: flex;
					align-items: center;
					justify-content: center;
					border: none;
					height: 42px;
					width: 42px;
					border-radius: 5px;
					margin-left: 8px;
					cursor: pointer;
					color: white;
					background: #ef7272;

					.icon {
						width: 20px;
						height: 20px;
					}
				}

			}
		}

		.add-entry {
			display: flex;
			align-items: center;
			border: none;
			padding: 8px 12px 8px 8px;
			margin-top: 5px;
			border-radius: 5px;
			cursor: pointer;
			color: white;
			background: #26E66D;
			height: 42px;
			transition: background-color 250ms;

			.icon {
				width: 22px;
				height: 22px;
				margin-right: 5px;
			}

			&:hover {
				background-color: rgba(38,230,108,0.75);
			}
		}
	}


	//Previous and Next Buttons
	.buttons {
		display: flex;
		flex-direction: column-reverse;
		width: 200px;
		margin: 20px auto 0 auto;

		button {
			display: flex;
			align-items: center;
			padding: 12px 24px;
			border-radius: 10px;
			border: none;
			cursor: pointer;
			font-size: 20px;
			transition: all 250ms;
			width: 100%;
			justify-content: center;

			&.hide {
				display: none;
			}

			&.previous {
				margin-top: 10px;
				padding-left: 8px;
				background: #ebebeb;

				&:hover, &:focus {
					opacity: 0.6;
					outline: none;
				}

				svg {
					margin-right: 6px;
				}
			}

			&.next, &.finish {
				margin-left: auto;
				padding-right: 8px;
				background: #26E66D;
				color: white;

				&:hover, &:focus {
					background: rgba(38,230,108,0.75);
					outline: none;
				}

				svg {
					margin-left: 6px;
				}
			}

			&.hidden {
				display: none;
			}

			&.disabled {
				opacity: 0.25;
				pointer-events: none;
			}

			svg {
				height: 24px;
				width: 24px;
			}

		}

	}

	//Page System
	.page, .finish-page {
		display: none;
		text-align: center;

		.title {
			font-size: 28px;
			margin-top: 30px;
			margin-bottom: 10px;
		}

		.subtitle {
			font-size: 18px;
			font-weight: 400;
		}

		&.show {
			display: block;
		}

		.report-loader,
		.report-loader:after {
			border-radius: 50%;
			width: 10em;
			height: 10em;
		}
		.report-loader {
			margin: 20px auto 30px auto;
			font-size: 10px;
			overflow: hidden;
			position: relative;
			text-indent: -9999em;
			border-top: 1.1em solid rgba(0, 0, 0, 0.05);
			border-right: 1.1em solid rgba(0, 0, 0, 0.05);
			border-bottom: 1.1em solid rgba(0, 0, 0, 0.05);
			border-left: 1.1em solid #26E66D;
			-webkit-transform: translateZ(0);
			-ms-transform: translateZ(0);
			transform: translateZ(0);
			-webkit-animation: load8 1.1s infinite linear;
			animation: load8 1.1s infinite linear;
		}


	}

	&.report-retrieve {
		text-align: center;
		box-shadow: none;
		background: none;
		display: grid;
		grid-template-columns: 1fr 325px;
		grid-gap: 15px;
		grid-auto-rows: auto;

		.report-block, .report-payment-panel {
			background: white;
			box-shadow: 0 3px 6px rgb(0, 0, 0, 0.05);
			padding: 20px;
			margin: 0 auto;
			border-radius: 8px;
			align-self: start;
		}

		.main-block {
			display: block;
			width: 100%;
			align-self: start;
		}

		.report-payment-panel {
			width: 100%;
			background: white;
			box-shadow: 0 3px 6px rgb(0, 0, 0, 0.05);
			padding: 20px;
			margin: 0 auto;
			border-radius: 8px;

			.title {
				display: block;
				font-size: 20px;
				font-weight: 500;
				margin: 0 0 20px 0;
				padding-bottom: 15px;
				border-bottom: solid 1px #E4E4E4;
			}

			.coupon-form {
				display: flex;
				flex-direction: column;
				margin-bottom: 15px;

				.row {
					display: flex;
				}

				.input {
					width: 100%;
					padding: 8px 10px;
					margin-right: 5px;
					border-radius: 5px;
					background: white;
					font-size: 14px;
					border: solid 1px #E4E4E4;
					color: #7A7A7A;
					box-shadow: 0 0 4px rgba(0, 0, 0, 0.05);

					&:focus {
						outline: none;
					}

					&:disabled {
						background: rgba(0, 0, 0, 0.05);
					}
				}

				.apply-button {
					display: block;
					padding: 6px 10px;
					font-size: 14px;
					border-radius: 5px;
					background: white;
					border: solid 1px #26E66D;
					color: #26E66D;
					cursor: pointer;
					transition: all 250ms;

					&:hover {
						background: #26E66D;
						color: white;
					}

					&:focus {
						outline: none;
						background: #26E66D;
						color: white;
					}

					&.hide {
						display: none;
					}
				}


				.loading-coupon, .delete-coupon {
					display: flex;
					align-items: center;
					justify-content: center;
					border: none;
					background: #26E66D;
					color: white;
					border-radius: 5px;
					padding: 5px 5px;
					transition: all 250ms;

					&:focus {
						outline: none;
					}

					&.hide {
						display: none;
					}

					svg {
						height: 20px;
						width: 20px;
					}
				}

				.loading-coupon {
					cursor: default;
					padding: 5px 8px;

					svg {
						height: 18px;
						width: 18px;
						animation-name: spin;
						animation-duration: 1000ms;
						animation-iteration-count: infinite;
					}

					@keyframes spin {
						from {
							transform:rotate(360deg);
						}
						to {
							transform:rotate(0deg);
						}
					}
				}

				.delete-coupon {
					cursor: pointer;
					background-color: #ef7272;

					&:hover {
						background: rgba(239, 114, 114, 0.75);
					}
				}

				.error {
					display: none;
					font-size: 14px;
					margin-top: 5px;
					color: #ef7272;

					&.show {
						display: block;
					}
				}
			}

			.payment-line, .payment-total {
				display: flex;
				justify-content: space-between;
			}

			.payment-total {
				font-weight: 500;
				font-size: 16px;
				margin-top: 5px;
			}

			.checkout-button {
				display: block;
				width: 100%;
				text-decoration: none;
				background: #26E667;
				color: white;
				text-align: center;
				border-radius: 5px;
				border: none;
				font-size: 18px;
				margin: 15px auto 0 auto;
				cursor: pointer;
				transition: all 250ms;
				padding: 10px 12px;

				&:hover {
					background: rgba(38, 230, 108, 0.75);
				}

				&:focus {
					outline: none;
					background: rgba(38, 230, 108, 0.75);
				}
			}

			.checkout-notice {
				font-size: 12px;
				display: block;
				margin: 8px 0 0 0;
			}
		}

		.title {
			text-align: center;
			margin: 8px 0 18px 0;
		}

		.subtitle {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			background: rgba(0, 0, 0, 0.05);
			font-weight: 400;
			font-size: 14px;
			padding: 8px 12px;
			margin-bottom: 12px;
			border-radius: 1000px;

			strong {
				margin: 0 4px;
			}

			svg {
				height: 16px;
				margin-right: 3px;
			}
		}

		.download-report {
			display: inline-block;
			text-decoration: none;
			background: #26E667;
			color: white;
			text-align: center;
			border-radius: 10px;
			border: none;
			font-size: 20px;
			margin: 10px auto 15px auto;
			cursor: pointer;
			transition: all 250ms;
			padding: 12px 12px;

			&:hover {
				background: rgba(38,230,108,0.75);
				outline: none;
			}

			&.disabled {
				background: rgba(38,230,108,0.4);
				cursor: default;
			}
		}

		.report-details {
			display: block;
			font-size: 12px;
			color: rgba(0, 0, 0, 0.25);
		}

		&.only-main-block {
			display: block;
		}

		&.main-block-and-stripe-alert {
			.stripe-checkout-alert {
				grid-column-start: 1;
				grid-column-end: 3;
				grid-row-start: 1;
				grid-row-end: 2;
			}

			.main-block {
				grid-column-start: 1;
				grid-row-start: 2;
				grid-row-end: 3;
			}

			.report-payment-panel {
				grid-column-start: 2;
				grid-row-start: 2;
				grid-row-end: 3;
			}

		}

		.stripe-checkout-alert {
			position: relative;
			display: flex;
			flex-direction: column;
			margin-bottom: 10px;
			background: white;
			box-shadow: 0 3px 6px rgb(0 0 0 / 5%);
			padding: 20px;
			border-radius: 8px;
			align-self: start;

			.confirmation-block {
				display: flex;
				align-items: center;
				justify-content: center;
				border-bottom: solid 1px rgba(0, 0, 0, 0.1);
				padding-bottom: 20px;

				h2 {
					font-size: 22px;
					font-weight: 500;
					white-space: nowrap;
				}
			}

			.info {
				padding-top: 20px;

				h2 {
					display: block;
					font-size: 20px;
					font-weight: 500;
					margin: 0 20px 0 0;
				}

				p {
					display: block;
					margin: 10px 0 0 0;
				}

				&.no-title {
					p {
						margin: 0 0 0 0;
					}
				}
			}

			.dismiss {
				display: flex;
				align-items: center;
				justify-content: center;
				top: 10px;
				right: 10px;
				position: absolute;
				height: 28px;
				width: 28px;
				background: rgba(0, 0, 0, 0.05);
				border: none;
				border-radius: 5px;
				cursor: pointer;
				transition: all 250ms;

				svg {
					width: 20px;
					height: 20px;
					color: rgba(0, 0, 0, 0.75);
				}

				&:hover {
					background: rgba(0, 0, 0, 0.1);
				}

				&:focus {
					outline: none;
				}
			}

			.spinner, .spinner:after {
				border-radius: 50%;
				width: 35px;
				height: 35px;
			}
			.spinner {
				font-size: 10px;
				overflow: hidden;
				position: relative;
				text-indent: -9999em;
				border-top: 0.4em solid rgba(0, 0, 0, 0.05);
				border-right: 0.4em solid rgba(0, 0, 0, 0.05);
				border-bottom: 0.4em solid rgba(0, 0, 0, 0.05);
				border-left: 0.4em solid #26E66D;
				-webkit-transform: translateZ(0);
				-ms-transform: translateZ(0);
				transform: translateZ(0);
				-webkit-animation: load8 1.1s infinite linear;
				animation: load8 1.1s infinite linear;
				margin: 0 15px 0 0;
			}

			&.only-info {
				.info {
					padding-top: 0;
				}
			}
		}

	}

	&.not-valid {
		text-align: center;
		padding: 50px 0;

		.heading, .subheading {
			display: block;
			margin: 0;
		}

		.heading {
			font-weight: 500;
			margin-bottom: 5px;
		}

		.subheading {
			font-size: 18px;
			font-weight: 400;
		}
	}
}

@-webkit-keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}